import { createClient } from 'contentful';
import * as allContentful from 'contentful';

export default defineNuxtPlugin(() => {
  const { isSandbox, isStaging, ctflProdSpaceId, ctflAccessToken } =
    useRuntimeConfig().public;

  const config = {
    space: ctflProdSpaceId,
    accessToken: ctflAccessToken,
    environment: 'master',
    ...(isSandbox || isStaging ? { host: 'preview.contentful.com' } : {}),
  };

  // issue with contentful working in different environments
  // so we will get the client this way
  // https://github.com/contentful/contentful.js/issues/1233
  const createClientFunction = createClient || allContentful.createClient;

  const contentfulClient = createClientFunction(config);
  return {
    provide: {
      contentfulClient,
    },
  };
});
