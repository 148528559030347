export const competitorBlockedEmails = [
  '@addsecure.com',
  '@ainfraserv.com',
  '@ais-now.com',
  '@apogeeis.net',
  '@astrata.eu',
  '@astratagroup.com',
  '@bigchange.com',
  '@cameramatics.com',
  '@cbblogistica.com',
  '@ceinetwork.com',
  '@cisco.com',
  '@ciscoite.com',
  '@encontrack.com',
  '@fleetcompleteuk.com',
  '@fleeti.co',
  '@fleetio.com',
  '@geotab.com',
  '@gomotive.com',
  '@gps-buddy.com',
  '@greenroad.com',
  '@greenroadins.com',
  '@idemtelematics.com',
  '@ims.tech',
  '@inseego.com',
  '@isaacinstruments.com',
  '@keeptrace.fr',
  '@keeptruckin.com',
  '@lightfoot.co.uk',
  '@logi-app.nl',
  '@lytx.com',
  '@masternaut.com',
  '@microlise.com',
  '@mixtelematics.com',
  '@movolytics.com',
  '@orbcomm.com',
  '@quartix.net',
  '@ramtracking.com',
  '@rio.cloud',
  '@route42.nl',
  '@rubicon.com',
  '@ssscamera.com',
  '@supatrak.com',
  '@teletracnavman.com',
  '@tomtom.com',
  '@trackunit.com',
  '@tracsys.com.mx',
  '@trakm8.com',
  '@trimble.com',
  '@truckonline.pro',
  '@ubidata.com',
  '@vehco.com',
  '@verizonconnect.com',
  '@Webfleet.com',
  '@zf.com',
];

export const personalBlockedEmails = [
  '@gmail.com',
  '@googlemail.com',
  '@yahoo.com',
  '@yahoo.co.uk',
  '@ymail.com',
  '@hotmail.com',
  '@outlook.com',
  '@live.com',
  '@msn.com',
  '@icloud.com',
  '@me.com',
  '@mac.com',
  '@aol.com',
  '@protonmail.com',
  '@zoho.com',
  '@mail.com',
  '@gmx.com',
  '@yandex.com',
  '@yandex.ru',
  '@fastmail.com',
];

export const blockedEmails = [
  ...competitorBlockedEmails,
  ...personalBlockedEmails,
];
