<template>
  <UtilitiesErrorBoundary tag="Column" :show-fallback="true">
    <div :class="wrapperClasses" :style="{ backgroundColor: backgroundColor }">
      <RichTextRenderer
        v-if="document"
        :document="document"
        :node-renderers="nodeRenderers"
        :mark-renderers="markRenderers"
      />
    </div>
    <LinksContentfulLink v-if="props.id" :id="props.id" label="column" />
  </UtilitiesErrorBoundary>
</template>

<script setup>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { markRenderers, nodeRenderers } from '~/utils/contentful-helpers';

  const props = defineProps({
    length: Number,
    column: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  });

  const document = computed(() => props.column.fields?.content || '');

  const classNames = computed(() => props.column.fields?.classes || []);

  const authorWrapperClasses = computed(() =>
    classNames.value && classNames.value.length > 0
      ? classNames.value.join(' ')
      : ''
  );

  const backgroundColor = computed(
    () => props.column.fields?.backgroundColor || null
  );

  const wrapperClasses = computed(() => {
    const columnFields = props.column.fields;

    const textAlign = columnFields.textAlign
      ? ` text-${columnFields.textAlign.toLowerCase()}`
      : '';

    const verticalAlignValues = {
      Center: 'self-center',
      Top: 'self-start',
      Bottom: 'self-end',
    };

    const verticalAlign = columnFields.verticalAlign
      ? ` ${verticalAlignValues[columnFields.verticalAlign]}`
      : '';

    const columnCount = () => {
      const gridDefault = 12;
      if (props.length) {
        return gridDefault / props.length;
      }
      console.warn('Missing column length');
      return gridDefault;
    };

    const grid = {
      desktop: columnFields.widthDesktop
        ? ` md:w-${columnFields.widthDesktop}/12`
        : ` md:w-${columnCount()}/12`,
      tablet: columnFields.widthTablet
        ? ` sm:w-${columnFields.widthTablet}/12`
        : '',
      mobile: columnFields.widthMobile
        ? ` w-${columnFields.widthMobile}/12`
        : ' w-12/12',
    };

    const gridOffset = {
      desktop: columnFields.offsetDesktop
        ? ` md:ml-${columnFields.offsetDesktop}/12`
        : ' md:ml-0',
      tablet: columnFields.offsetTablet
        ? ` sm:ml-${columnFields.offsetTablet}/12`
        : ' sm:ml-0',
      mobile: columnFields.offsetMobile
        ? ` ml-${columnFields.offsetMobile}/12`
        : '',
    };

    const mobileVisibility = {
      Hide: 'hidden md:block',
      Show: 'block md:hidden',
    };

    const tabletVisibility = {
      Hide: 'hidden md:block',
      Show: 'block md:hidden',
    };

    const desktopVisibility = {
      Hide: 'hidden lg:block',
      Show: 'block lg:hidden',
    };

    const visibility = {
      mobile: columnFields.hideshowMobile
        ? ` ${mobileVisibility[columnFields.hideshowMobile]}`
        : '',
      tablet: columnFields.hideshowTablet
        ? ` ${tabletVisibility[columnFields.hideshowTablet]}`
        : '',
      desktop: columnFields.hideshowDesktop
        ? ` ${desktopVisibility[columnFields.hideshowDesktop]}`
        : '',
    };

    return `column px-gap whitespace-pre-wrap ${authorWrapperClasses.value}${grid.desktop}${grid.tablet}${grid.mobile}${gridOffset.desktop}${gridOffset.tablet}${gridOffset.mobile}${textAlign}${verticalAlign}${visibility.mobile}${visibility.tablet}${visibility.desktop}`;
  });
</script>

<style lang="scss">
  :not(p) {
    @apply whitespace-normal;
  }
  .text-center {
    .video {
      margin: 0 auto;
    }
  }

  .base--button-wrapper {
    @apply m-auto;
  }
  .text-center,
  .text-left,
  .text-right {
    .base--button-wrapper {
      @apply w-fit;
    }
  }
  .text-right {
    .base--button-wrapper {
      @apply ml-auto;
    }
  }
  .text-left {
    .base--button-wrapper {
      @apply mr-auto;
    }
  }
  .column {
    &.psuedo-arrow-light-blue {
      @apply relative;

      margin-bottom: 0 !important;
      &::after {
        @apply absolute bottom-[-60px] h-0 w-0 translate-x-[-50%];

        border-left: 90px solid transparent;
        border-right: 90px solid transparent;
        border-top: 90px solid #f0f6fe;
        @screen md {
          @apply absolute right-[-90px] top-[50%] translate-y-[-50%];

          border-top: 60px solid transparent;
          border-bottom: 60px solid transparent;
          border-left: 60px solid #f0f6fe;
          border-right: none;
          @apply mb-1;
        }

        content: '';
        clear: both;
      }
    }
    p a u {
      text-decoration: none;
    }

    ul {
      list-style: unset;
      margin-left: 20px;

      p {
        margin-bottom: 0;
      }
    }

    &:not(:last-of-type) {
      @media (max-width: theme('screens.md')) {
        @apply mb-1;
      }
    }
    p:empty:last-of-type {
      margin: 0;
      @apply hidden;
    }
  }

  .venture-col {
    @screen sm-max {
      @apply pb-[30px];
    }
  }
</style>
