<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path d="M5.7998 1H12.9998V8.2" :stroke="color" stroke-width="1.5" />
    <rect
      x="1"
      y="4.6001"
      width="8.4"
      height="9.6"
      :stroke="color"
      stroke-width="1.5"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
