<template>
  <DotLottieVue :style="styleString" autoplay loop :src="link" />
</template>

<script setup>
  import { DotLottieVue } from '@lottiefiles/dotlottie-vue';

  const props = defineProps({
    link: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 500,
    },
    height: {
      type: Number,
      required: false,
      default: 500,
    },
    unit: {
      type: String,
      required: false,
      default: 'pixels',
    },
  });

  const styleString = computed(() => {
    const dict = {
      pixels: 'px',
      percent: '%',
      viewport: {
        h: 'vh',
        w: 'vw',
      },
    };

    const styleString = `height: ${props.height}${dict[props.unit] === 'viewport' ? dict[props.unit].h : dict[props.unit]}; width: ${props.width}${dict[props.unit] === 'viewport' ? dict[props.unit].w : dict[props.unit]}`;

    return styleString;
  });
</script>
