<template>
  <component :is="currentComponent" v-bind="currentProps" />
</template>

<script setup>
  import { resolveComponent } from 'vue';

  const props = defineProps({
    entry: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  });

  const componentMapping = new Map([
    ['modularColumn', resolveComponent('ModularColumn')],
    ['carousel', defineAsyncComponent(() => import('../dynamic/Carousel.vue'))],
    ['quote', defineAsyncComponent(() => import('../typography/Quote.vue'))],
    ['tabs', defineAsyncComponent(() => import('../wrappers/TabsWrapper.vue'))],
    [
      'devModule',
      defineAsyncComponent(() => import('../wrappers/DevModulesWrapper.vue')),
    ],
    ['list', defineAsyncComponent(() => import('../wrappers/ListWrapper.vue'))],
    ['form', defineAsyncComponent(() => import('../forms/FormPaid.vue'))],
    [
      'searchResults',
      defineAsyncComponent(() => import('../search/SearchResults.vue')),
    ],
  ]);

  const currentComponent = computed(() =>
    componentMapping.get(props.entry.sys.contentType.sys.id)
  );

  const currentProps = computed(() => {
    switch (props.entry.sys.contentType.sys.id) {
      case 'modularColumn':
        return {
          column: props.entry,
          length: props.row.fields.content.length,
          id: props.entry.sys.id,
        };
      case 'quote':
        return { quote: props.entry.fields };
      case 'devModule':
        return { modules: props.entry.fields };
      case 'carousel':
        return { carousel: props.entry.fields };
      case 'tabs':
        return { tabs: props.entry.fields };
      case 'form':
        return { formPaid: props.entry.fields };
      case 'list':
        return { entryObj: props.entry.fields };
      case 'searchResults':
        return { entryObj: props.entry.fields };
    }
  });
</script>
