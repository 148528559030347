<template>
  <div id="app-container">
    <UtilitiesDevModeWidget />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <img
      v-if="locale === 'uk'"
      width="1"
      height="1"
      class="hidden"
      alt="null"
      src="https://s.ml-attr.com/getuid?https%3a%2f%2fattr.ml-api.io%2f%3fdomain%3dwww.samsara.com%26pId%3d%24UID"
    />
    <div
      v-if="displayCookieBanner && !$route.fullPath.includes('cookie-consent')"
    >
      <LazyBannersCookieConsentBanner
        :country-code="countryCode"
        @accepted="cookiesAccepted"
        @rejected="cookiesRejected"
        @redirect="redirectToConsent"
      />
    </div>
    <div v-if="showGeolocation">
      <LazyBannersTheGeolocationBanner
        :country-code="countryCode"
        @closed="toggleGeolocation"
      />
    </div>
    <ModalsYoutubeEmbedModal
      v-if="ytId"
      :is-open="openModal"
      :close-location="'outer'"
      :video="{ id: ytId }"
      :youtube-player-state="ytPlayerState"
      @closeVideoModal="closeModal"
    />
  </div>
</template>

<script setup>
  import { flagMappings } from '~/constants/locales';
  import { AnalyticsEvents } from '~/types/enums/emitter-events';
  import {
    includeGTMScript,
    initAnalytics,
    thirdPartySetup,
  } from '~/utils/analytics';
  import { runUTMCookies } from '~/utils/analytics/utm-cookies';
  import { getGeo } from '~/utils/get-geo';

  useHead({
    link: [
      {
        rel: 'preload',
        type: 'font/woff2',
        href: '/fonts/roboto-v20-latin-300.woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        type: 'font/woff2',
        href: '/fonts/roboto-v20-latin-regular.woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        type: 'font/woff2',
        href: '/fonts/roboto-v20-latin-500.woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        type: 'font/woff2',
        href: '/fonts/roboto-v20-latin-700.woff2',
        as: 'font',
        crossorigin: '',
      },
    ],
    script: [
      {
        children:
          'window.dataLayer = window.dataLayer || [];\nfunction gtag() { dataLayer.push(arguments); }\ngtag("consent", "default", {\n"ad_user_data": "denied",\n"ad_personalization": "denied",\n"ad_storage": "denied",\n"analytics_storage": "denied",\n});\ngtag("set", "url_passthrough", true);',
      },
      {
        src: 'https://www.googletagmanager.com/gtm.js?id=GTM-53ZGD4T',
        async: true,
      },
      {
        children:
          "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'GTM-53ZGD4T');",
      },
    ],
  });

  const { $emitter } = useNuxtApp();
  const { analyticsTrack } = useAnalytics();
  const { updateExperimentValue } = useAnalyticsHelpers();
  const currentURL = useRequestURL();
  const localePath = useLocalePath();
  const { locale } = useI18n();

  const analyticsOptIn = ref(false);
  const advertisingOptIn = ref(false);
  const detectedCountry = useCookie('detectedCountry');
  const countryCode = ref(null);
  const isConsentRegion = ref(false);
  const isNorthAmerica = ref(false);
  const closeGeoLocation = ref(false);
  const displayCookieBanner = ref(false);
  const ytId = ref(null);
  const openModal = ref(false);
  const ytPlayerState = ref('unstarted');

  const getCookieDomain = () => {
    const hostname = currentURL.hostname;

    if (hostname === 'localhost') {
      return 'localhost';
    }

    // Split the hostname into parts
    const parts = hostname.split('.');

    // If the hostname has only two parts (e.g., 'example.com') or is a simple 'www' subdomain (e.g., 'www.example.com')
    if (parts.length === 2 || (parts.length === 3 && parts[0] === 'www')) {
      // Return the domain with a leading dot to include all subdomains
      return `.${parts.slice(-2).join('.')}`;
    } else {
      // For more complex domains, return the full hostname for specific targeting
      return hostname;
    }
  };

  const cookieConsentStatus = useCookie('cookieconsent_status', {
    maxAge: 86400 * 180, // 180 days
    domain: getCookieDomain(),
  });

  const samsaraCookies = useCookie('samsara_cookies', {
    maxAge: 86400 * 180, // 180 days
  });

  const showGeolocation = computed(() => {
    return (
      !closeGeoLocation.value &&
      countryCode.value &&
      countryCode.value !== flagMappings[locale.value]
    );
  });

  const redirectToConsent = async () => {
    await navigateTo({
      path: localePath('/legal/cookie-consent'),
    });
  };

  const cookiesAccepted = () => {
    displayCookieBanner.value = false;
    samsaraCookies.value = 'fn, an, ad';
    cookieConsentStatus.value = 'dismiss';
  };

  const cookiesRejected = () => {
    displayCookieBanner.value = false;
    cookieConsentStatus.value = 'dismiss';
  };

  const runTracking = () => {
    if (analyticsOptIn.value) {
      runUTMCookies();
      initAnalytics();
    }

    if (advertisingOptIn.value) {
      includeGTMScript();
    }
  };

  const setOptIn = () => {
    const cookiePreferences = samsaraCookies.value || '';
    if (cookiePreferences.includes('an')) {
      analyticsOptIn.value = true;
    }
    if (cookiePreferences.includes('ad')) {
      advertisingOptIn.value = true;
    }
  };

  const runAnalyticsFunctions = () => {
    if (!navigator.globalPrivacyControl) {
      setOptIn();
      thirdPartySetup(analyticsOptIn.value, isConsentRegion.value);
      runTracking();
    }
  };

  const toggleGeolocation = () => {
    closeGeoLocation.value = !closeGeoLocation.value;
  };

  const openVideoModal = (url, type) => {
    if (type === 'Modal Video') {
      const temp = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      ytId.value = temp[2] ? temp[2].split(/[^0-9a-z_\-]/i)[0] : temp[0];

      ytPlayerState.value = 'play';

      nextTick(() => {
        openModal.value = true;
      });
    }
  };

  const closeModal = () => {
    openModal.value = false;
    ytPlayerState.value = 'unstarted';
  };

  watchEffect(() => {
    if (countryCode.value) {
      detectedCountry.value = countryCode.value;

      // Check if the consent status is not 'dismissed'
      if (cookieConsentStatus.value !== 'dismiss') {
        if (isConsentRegion.value) {
          displayCookieBanner.value = true;
          if (isNorthAmerica.value) {
            runUTMCookies();
          }
        } else {
          cookiesAccepted();
        }
      } else {
        // Handle cases where consent status is 'dismissed' but Samsara cookies do not exist
        if (!samsaraCookies.value) {
          if (isConsentRegion.value) {
            cookieConsentStatus.value = 'dismiss';
            displayCookieBanner.value = false;
          } else {
            cookiesAccepted();
          }
        }
      }
    }
  });

  watch(samsaraCookies, (value) => {
    if (value) {
      runAnalyticsFunctions();
    }
  });

  watch(cookieConsentStatus, (value) => {
    if (value === 'dismiss') {
      displayCookieBanner.value = false;
    } else {
      displayCookieBanner.value = true;
    }
  });

  onMounted(() => {
    console.log('IN SANDBOX LAND');
    getGeo().then((geo) => {
      countryCode.value = geo.code;
      isConsentRegion.value = geo.isConsentRegion;
      isNorthAmerica.value = geo.isNorthAmerica;
    });

    // analytics:mixpanel manager
    $emitter.on(AnalyticsEvents.TRACK, (payload) => {
      const { event, flagKey, ...properties } = payload;

      analyticsTrack(event, properties);

      if (flagKey && typeof properties.variation_id !== 'undefined') {
        updateExperimentValue(flagKey, properties.variation_id);
      }
    });

    runAnalyticsFunctions();

    $emitter.on('playYouTube', (video) => {
      openVideoModal(video.url, 'Modal Video');
    });
  });

  onUnmounted(() => {
    $emitter.off('playYouTube');
  });
</script>
