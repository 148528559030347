<template>
  <span v-if="image" class="whitespace-normal">
    <ImagesNuxtImg
      :image="image"
      :url="src"
      :classes="classes"
      :is-inline="isInline"
      :alt="alt"
      :quality="quality"
      :modifier="modifier"
      :natural-width="naturalWidth"
      :natural-height="naturalHeight"
      :styles="styles"
      :content-type="contentType"
      :lazy="lazy"
      :query-params="queryParams"
    />
    <LinksContentfulLink v-if="id" :id="id" label="image" />
  </span>
</template>

<script>
  export default {
    props: {
      image: {
        type: Object,
        required: true,
      },
      modifier: {
        type: String,
        required: false,
        default: '',
      },
      queryParams: {
        type: String,
        required: false,
        default: '',
      },
      format: {
        type: String,
        required: false,
        default: undefined,
      },
      quality: {
        type: Number,
        required: false,
        default: 80,
      },
      classes: {
        type: String,
        required: false,
        default: '',
      },
      isInline: {
        type: Boolean,
        required: false,
        default: false,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      lazy: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    computed: {
      styles() {
        const width =
          this.image.maxWidth && this.image.width
            ? `max-width: ${this.image.maxWidth}px;`
            : this.image.maxWidth
              ? `max-width: ${this.image.maxWidth}px; width: 100%;`
              : '';
        return width;
      },
      src() {
        return this.image.image && this.image.image.fields.file
          ? this.image.image.fields.file.url
          : '';
      },
      alt() {
        return this.image.alt ? this.image.alt : '';
      },
      naturalWidth() {
        return this.image.image.fields.file
          ? this.image.image.fields.file.details.image.width
          : null;
      },
      naturalHeight() {
        return this.image.image.fields.file
          ? this.image.image.fields.file.details.image.height
          : null;
      },
      contentType() {
        return this.image.image.fields?.file?.contentType;
      },
    },
  };
</script>
