<template>
  <div>
    <div class="slider-wrapper">
      <Swiper
        v-bind="options"
        class="overflow-visible !pb-1 !pt-1"
        :modules="[SwiperNavigation]"
      >
        <SwiperSlide
          v-for="(slide, index) in carousel.slides"
          :key="`${index}`"
          ref="slides"
        >
          <div class="slide">
            <ImagesNuxtImg
              v-if="slide.fields.mobileImage"
              :data-slide-link="slide.fields?.link || ''"
              :data-slide-linkType="slide.fields?.linkType || ''"
              :class="{
                'absolute bottom-0 left-0':
                  slide.fields.mediaPosition &&
                  slide.fields.mediaPosition === 'Bottom',
                'h-full w-full scale-75 object-cover':
                  slide.fields.mediaPosition &&
                  slide.fields.mediaPosition === 'Center',
                relative:
                  slide.fields.mediaPosition === undefined ||
                  slide.fields.mediaPosition === null,
              }"
              class="absolute z-20 pl-1 pt-1"
              :url="slide.fields.mobileImage.fields.image.fields.file.url"
              :alt="slide.fields.mobileImage.fields.alt"
              :lazy="true"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="flex flex-col pt-1/2 md:flex-row md:justify-between">
      <div v-if="carousel.content" class="flex w-full flex-col md:border-0">
        <RichTextRenderer
          class="tcs-text-content"
          :document="carousel.content"
          :node-renderers="nodeRenderers"
        />
      </div>
      <span
        class="my-1 block h-[1px] w-full border-b-1 border-gray-lightest md:hidden"
      ></span>
      <p
        v-if="carousel.content.fields?.label"
        :id="carousel.content.fields?.link || ''"
        class="ms-modal-slider--label mb-0 flex w-auto flex-row flex-nowrap items-center self-start pb-0 font-bold text-blue hover:cursor-pointer hover:text-blue-dark"
        role="button"
        :data-slide-link="carousel.content.fields?.link || ''"
        :data-slide-linkType="carousel.content.fields?.linkType || ''"
      >
        {{ carousel.content.fields.label }}
        <IconsSvgModal class="w-[20px] pl-1/2" />
      </p>
    </div>
  </div>
</template>
<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        document: this.carousel,
        interval: null,
        slides: null,
        ytId: null,
        options: {
          loop: true,
          init: false,
          navigation: {
            nextEl: '#modal-next-button',
            prevEl: '#modal-prev-button',
          },
          lazy: {
            loadPrevNext: true,
          },
          allowTouchMove: true,
          watchOverflow: true,
          spaceBetween: 22,
          centeredSlides: false,
          slidesPerView: 'auto',
          breakpoints: {
            0: {
              allowTouchMove: true,
              centeredSlides: false,
              spaceBetween: 22,
              slidesPerView: 1.3,
              slidesPerGroup: 1,
            },
            436: {
              allowTouchMove: true,
              centeredSlides: false,
              spaceBetween: 22,
              slidesPerView: 2.3,
              slidesPerGroup: 1,
            },
            850: {
              loop: false,
              enabled: false,
              slidesPerView: 3,
            },
          },
        },
      };
    },
  };
</script>
