export function transformQueryToDict() {
  const params = {};
  if (location.search) {
    const parts = location.search.substring(1).split('&');
    for (let i = 0; i < parts.length; i++) {
      const query = parts[i].split('=');
      if (!query[0]) continue;
      params[query[0]] = query[1] || true;
    }
  }
  return params;
}

export function setCookie(cname, cvalue, exdays) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cname + '=' + decodeURI(cvalue) + ';' + expires + ';path=/';
}

export function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookie = decodedCookie.split(';');
  for (let i = 0; i < cookie.length; i++) {
    let char = cookie[i];
    while (char.charAt(0) == ' ') {
      char = char.substring(1);
    }
    if (char.indexOf(name) == 0) {
      return char.substring(name.length, char.length);
    }
  }
  return '';
}

export function getDateWithinLastDay(currdate, ts) {
  const oneDayInMilliseconds = 86400000;
  return currdate - ts < oneDayInMilliseconds;
}

export function getOrSetCookie(cname, cvalue, isInLastDay) {
  if (isInLastDay == undefined) {
    isInLastDay = false;
  }
  if (!getCookie(cname)) {
    setCookie(cname, cvalue, 7);
    return true;
  } else {
    const existingCookie = getCookie(cname);
    const tsCookie =
      cname == 'utm_ts'
        ? existingCookie.split(',')
        : getCookie('utm_ts').split(',');
    const lastTS = parseInt(tsCookie[tsCookie.length - 1]);
    const isWithinLastDay = getDateWithinLastDay(new Date().getTime(), lastTS);
    if (isInLastDay && isWithinLastDay) {
      return false;
    } else {
      const newCookie = existingCookie + ', ' + cvalue;
      setCookie(cname, newCookie, 7);
      return true;
    }
  }
}

export function isSearchEngine(referrer) {
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  } else if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  } else if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  } else if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  } else {
    return null;
  }
}

export function isSearchPage(path) {
  const searchPages = [
    '/blog',
    '/company',
    '/content',
    '/customers',
    '/events',
    '/fleet-terms',
    '/guides',
    '/industries',
    '/mission',
    '/products',
    '/research',
    '/resources',
    '/roi-calculator',
    '/solutions',
    '/videos',
  ];

  let searchPage = false;
  searchPages.forEach(function (page) {
    if (!searchPage) {
      searchPage = path.indexOf(page) > -1;
    }
  });
  return searchPage;
}

export function determineOrigin(referrer, path) {
  const searchEngine = isSearchEngine(referrer);
  let wasUpdated = false;

  if (searchEngine) {
    if (isSearchPage(path)) {
      getOrSetCookie('utm_source', 'SEO', true);
      wasUpdated = getOrSetCookie('utm_campaign', path, true);
    } else if (path.split('/')[1] === '' || path.split('/')[2] === '') {
      getOrSetCookie('utm_source', 'Branded');
      wasUpdated = getOrSetCookie('utm_campaign', path, true);
    }
  } else if (!referrer) {
    getOrSetCookie('utm_source', 'Direct', true);
    wasUpdated = getOrSetCookie('utm_campaign', path, true);
  } else if (referrer && !referrer.indexOf('https://www.samsara.com') > -1) {
    getOrSetCookie('utm_source', 'Branded', true);
    wasUpdated = getOrSetCookie('utm_campaign', path, true);
  }
  if (wasUpdated) {
    getOrSetCookie('utm_ts', new Date().getTime());
  }
}

export function setUTMCookies(wasUpdated, params) {
  const utmList = [
    'keyword',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_term',
    'utm_ext_ad_id',
    'utm_ext_adset_id',
    'utm_ext_campaign_id',
    'gclid',
    '_mkto_trk',
    'msclkid',
  ];
  for (let i = 0; i < utmList.length; i++) {
    const utm = utmList[i];
    if (utm in params) {
      wasUpdated = getOrSetCookie(utm, params[utm], true);
    }
  }
  return wasUpdated;
}

export function setTSCookie(wasUpdated) {
  if (wasUpdated) {
    getOrSetCookie('utm_ts', new Date().getTime(), true);
  }
}

export function setOneTimeCookie(cookie, value) {
  if (!getCookie(cookie)) {
    setCookie(cookie, value, 7);
  }
}

export function setCIDCookie(params) {
  if (!getCookie('cid') && 'cid' in params) {
    setCookie('cid', params['cid'], 7);
  }
}

export const cookieMessages = {
  AT: {
    message:
      'Wie verwenden Cookies, um unsere Webseite optimal für Sie gestalten und fortlaufend verbessern zu können.',
    button1: 'Details anzeigen',
    button2: 'Zustimmen',
    button3: 'Ablehnen',
  },
  CH: {
    message:
      'Ce site utilise des cookies pour vous offrir une meilleure expérience utilisateur.',
    button1: "Plus d'informations",
    button2: 'J’accepte',
    button3: 'Rejeter',
  },
  DE: {
    message:
      'Wie verwenden Cookies, um unsere Webseite optimal für Sie gestalten und fortlaufend verbessern zu können.',
    button1: 'Details anzeigen',
    button2: 'Zustimmen',
    button3: 'Ablehnen',
  },
  BE: {
    message: 'Wij gebruiken cookies om u de best mogelijke ervaring te geven.',
    button1: 'Meer informatie',
    button2: 'Accepteren',
    button3: 'Afwijzen',
  },
  ES: {
    message:
      'Utilizamos cookies propias y de terceros para mejorar nuestros servicios.',
    button1: 'Más info',
    button2: 'Aceptar',
    button3: 'Rechazar todos',
  },
  FR: {
    message:
      'Ce site utilise des cookies pour vous offrir une meilleure expérience utilisateur.',
    button1: "Plus d'informations",
    button2: 'J’accepte',
    button3: 'Rejeter',
  },
  UK: {
    message:
      'This website stores data such as cookies to enable essential site functionality, as well as marketing, personalization, and analytics. By clicking “Accept All” you agree to the storing of cookies on your device. You may change your settings at any time.',
    button1: 'Customize settings',
    button2: 'Accept',
    button3: 'Reject all',
    cookie_policy: {
      text: 'Cookie Policy',
      link: '/legal/cookie-policy',
    },
    privacy_policy: {
      text: 'Privacy Policy',
      link: '/support/privacy',
    },
  },
  US: {
    message:
      'This website stores data such as cookies to enable essential site functionality, as well as marketing, personalization, and analytics. By clicking “Accept All” you agree to the storing of cookies on your device.',
    button1: 'Customize settings',
    button2: 'Accept',
    button3: 'Reject all',
    cookie_policy: {
      text: 'Cookie Policy',
      link: '/legal/cookie-policy',
    },
  },
  CA: {
    message:
      'This website stores data such as cookies to enable essential site functionality, as well as marketing, personalization, and analytics. By clicking “Accept All” you agree to the storing of cookies on your device. You may change your settings at any time.',
    button1: 'Customize settings',
    button2: 'Accept',
    button3: 'Reject all',
    cookie_policy: {
      text: 'Cookie Policy',
      link: '/legal/cookie-policy',
    },
  },
  IT: {
    message:
      'Questo sito utilizza cookie tecnici e di profilazione propri e di terze parti per offrirle una migliore esperienza.',
    button1: 'Ulteriori informazioni',
    button2: 'Accetto',
    button3: 'Rifiutare',
  },
  NL: {
    message: 'Wij gebruiken cookies om u de best mogelijke ervaring te geven.',
    button1: 'Meer informatie',
    button2: 'Accepteren',
    button3: 'Afwijzen',
  },
};
