<template>
  <div :id="sysid">
    <div class="aspect--4x3 relative">
      <iframe
        :src="`https://capture.navattic.com/${props.iframe.source}`"
        class="absolute left-0 top-0 h-full w-full"
        allow="fullscreen"
        :data-navattic-demo-id="props.iframe.source"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    iframe: {
      type: Object,
      required: true,
    },
    sysid: {
      type: String,
      required: true,
    },
  });

  useHead({
    script: [
      {
        src: 'https://js.navattic.com/sdk.js',
        dataKey: 'pkey_cm34kvgk502ffrs3b8t8b0oi8',
        async: true,
      },
      {
        innerHTML:
          'void 0===window.navatticQueue&&(window.navatticQueue=[],window.navattic=new Proxy({},{get:function n(t,u,e){return function(){for(var n=arguments.length,t=Array(n),e=0;e<n;e++)t[e]=arguments[e];return navatticQueue.push({function:u,arguments:t})}}}))',
      },
    ],
  });
</script>
