export function isStorageAvailable(type) {
  //https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.key === 22 ||
        // Firefox
        e.key === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

function addScript(src, onLoad, useDefer = false) {
  const script = document.createElement('script');
  script.async = !useDefer;
  script.defer = useDefer;
  script.src = src;
  script.onload = onLoad;
  document.head.appendChild(script);
}

export function initAnalytics() {
  // Google Analytics
  addScript('https://www.googletagmanager.com/gtag/js?id=UA-60610307-1', () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'UA-60610307-1');
  });

  // Marketo
  addScript(
    '//munchkin.marketo.net/munchkin.js',
    () => {
      let didInit = false;
      function initMunchkin() {
        if (!didInit) {
          didInit = true;
          Munchkin.init('027-TSX-214');
        }
      }
      initMunchkin();
    },
    true
  );

  // Tapfiliate
  addScript('https://script.tapfiliate.com/tapfiliate.js', () => {
    window.TapfiliateObject = 'tap';
    window.tap =
      window.tap ||
      function () {
        (window.tap.q = window.tap.q || []).push(arguments);
      };
    tap('create', '14279-79e523', { integration: 'javascript' });
    tap('detect');
  });

  // 6sense
  addScript('//j.6sc.co/6si.min.js', () => {
    window._6si = window._6si || [];
    window._6si.push(['enableEventTracking', true]);

    let isVisitorCompanyIdentified = false;
    let dateIdentified = '';
    if (isStorageAvailable('localStorage')) {
      isVisitorCompanyIdentified =
        window.localStorage.getItem('_6senseCompanyDetails') !== null
          ? window.localStorage.getItem('_6senseCompanyDetails')
          : false;
    }

    const msPerSixMonths = 1000 * 60 * 60 * 24 * 180;
    if (isStorageAvailable('localStorage')) {
      dateIdentified = new Date(
        window.localStorage.getItem('_6senseDate') !== null
          ? window.localStorage.getItem('_6senseDate')
          : ''
      );
    }
    const currentDate = new Date();
    const isGreatherThanSixMonths =
      currentDate - dateIdentified > msPerSixMonths;

    if (!isVisitorCompanyIdentified || isGreatherThanSixMonths) {
      window._6si.push(['enableCompanyDetails', true]);

      if (isStorageAvailable('localStorage')) {
        window.localStorage.setItem(
          '_6senseDate',
          currentDate.toLocaleString()
        );
      }

      window._6si.push([
        'setEpsilonKey',
        '04e5174c00411de193bb0b4eb1914511217ad7f4',
      ]);
    }

    window._6si.push(['setToken', '53f94d2a02182b737669f976509fb951']);
    window._6si.push(['setEndpoint', 'b.6sc.co']);
  });
}

export function isEU(country: string | null): boolean {
  const EUCountries = [
    'AT',
    'CH',
    'DE',
    'BE',
    'ES',
    'FR',
    'UK',
    'IT',
    'NL',
    'BG',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'GR',
    'HR',
    'HU',
    'IE',
    'LT',
    'LU',
    'LV',
    'MT',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
  ];

  return EUCountries.includes(country);
}

export function thirdPartySetup(analyticsOptIn, isConsentRegion) {
  // Optimizely
  if (isConsentRegion) {
    window.optimizely = window.optimizely || [];

    window.optimizely.push({
      type: 'optOut',
      isOptOut: !analyticsOptIn,
    });
  }
}

export function includeGTMScript() {
  // Google Tag Manager
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });

  const gtmNoScript = document.createElement('noscript');
  gtmNoScript.innerHTML =
    '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-53ZGD4T" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
  document.head.appendChild(gtmNoScript);
}

export function createEventPayload({
  ldKey,
  variationId,
}: {
  ldKey: string;
  variationId: string;
}) {
  // regex check: throw an error if the string does not follow Launch Darkly key convention
  // accepted format: rollout-multi-step-form-redesign
  const regex = /^[a-z]+(-[a-z]+)+$/;

  if (!regex.test(ldKey)) {
    throw new Error('Invalid LaunchDarkly key format');
  }

  if (variationId === undefined) {
    throw new Error('Variation Id is required');
  }

  const words = ldKey
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  const titleCase = words.join(' ');

  return {
    event: `${titleCase} Evaluated`,
    experiment_id: titleCase,
    flagKey: ldKey,
    variation_id: variationId,
  };
}
