<template>
  <div :id="sysid">
    <div class="aspect--16x9 relative">
      <iframe
        class="absolute left-0 top-0 h-full w-full"
        :src="props.iframe.source"
        allow="fullscreen"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    iframe: {
      type: Object,
      required: true,
    },
    sysid: {
      type: String,
      required: true,
    },
  });
</script>
