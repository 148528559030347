import * as Sentry from '@sentry/vue';
import sentryErrors from '~/constants/sentry-error-ignores';

export default defineNuxtPlugin({
  name: 'sentry-plugin',
  setup(nuxtApp) {
    const router = useRouter();
    const { sentry } = useRuntimeConfig().public;

    if (sentry.dsn && !sentry.disabled) {
      Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,

        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
        ],
        allowUrls: [
          /https?:\/\/((cdn|www)\.)?samsara\.com/,
          /https?:\/\/((cdn|www)\.)?samsara-staging\.com/,
        ],
        ignoreErrors: sentryErrors,

        tracesSampleRate: 0.2,
        tracingOptions: {
          trackComponents: true,
          hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
        },

        tracePropagationTargets: [
          'https://samsara.com',
          'https://samsara-staging.com/',
        ],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },
});
