<template>
  <div
    v-if="!loading"
    ref="international-nav"
    class="nav--international fixed bottom-0 left-0 z-50 w-full bg-blue-dark"
  >
    <div
      id="header-cont-2"
      class="text--all-white mx-1/12 flex h-full flex-wrap items-center justify-between"
    >
      <div v-if="mainText" id="intl-text">
        <p class="p--subhead mb-0">{{ mainText }}</p>
      </div>
      <div class="flex w-full justify-between lg:w-auto">
        <div class="select-and-dismiss lg:ml-auto">
          <div id="select-banner-wrap">
            <select
              v-if="header?.countryDropdowns?.countries"
              v-model="selectedLocale"
              name="select-region"
              class="select--region p--subhead bg-transparent text-white lg:ml-1/4"
              @change.stop.prevent="changeLocale"
            >
              <option
                v-for="(country, index) in header.countryDropdowns.countries"
                :key="index"
                :value="country.value.toLowerCase()"
                :selected="country.selected === true"
              >
                {{ country.country_name }}
              </option>
            </select>
          </div>
        </div>
        <span
          class="ml-2 cursor-pointer"
          @click.prevent="setBannerDismissCookie('intlmodalclose', true)"
        >
          <svg
            width="29"
            height="30"
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="28.3536"
              y1="1.35355"
              x2="0.353553"
              y2="29.3536"
              stroke="white"
            />
            <line
              x1="0.353553"
              y1="0.646447"
              x2="28.3536"
              y2="28.6464"
              stroke="white"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { localeMappings } from '~/constants/locales';
  import { report } from '~/utils/telemetry';

  export default {
    props: {
      countryCode: {
        type: String,
        required: true,
      },
    },
    emits: ['closed'],
    async setup() {
      const { locale, setLocale } = useI18n();
      const { analyticsTrack } = useAnalytics();
      const { fullPath } = useRoute();

      const { data, error, pending } = await useAsyncGql({
        operation: 'GeolocationBanner',
        variables: {
          locale: localeMappings[locale.value],
        },
      });

      if (error.value) {
        report(error.value);
      }

      const header = computed(() => data.value?.header ?? {});
      const headerUS = computed(() => data.value?.headerUS ?? {});

      return {
        header,
        headerUS,
        locale,
        fullPath,
        loading: pending,
        selectedLocale: locale,
        analyticsTrack,
        setLocale,
      };
    },
    computed: {
      mainText() {
        if (!this.loading && this.headerUS?.internationalBannerText) {
          return this.headerUS.internationalBannerText[this.countryCode] || '';
        }
        return '';
      },
    },
    methods: {
      setBannerDismissCookie(cookieName, cookieValue) {
        const cookie = useCookie(cookieName, {
          path: '/',
          expires: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000), // 90 days from now
        });
        cookie.value = cookieValue;
        this.$emit('closed');
        this.analyticsTrack('International Banner Dismissed');
      },
      async changeLocale() {
        this.trackClick('Locale Selector', this.selectedLocale);

        if (this.selectedLocale === 'us') this.selectedLocale = 'en-US';

        try {
          this.setLocale(this.selectedLocale);
        } catch (error) {
          await navigateTo({ path: '/' });
        }
      },
      trackClick(buttonName, locale) {
        this.analyticsTrack('Button Clicked', {
          locale,
          buttonName,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .p--subhead {
    @apply text-white;

    @media screen and (max-width: theme('screens.md')) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .nav--international {
    height: 120px;
    @screen md {
      height: 80px;
    }
    @screen lg {
      height: 60px;
    }
  }
  .select--region {
    line-height: 30px;
  }

  .select--region:focus {
    @apply border-0 shadow-none outline-none;
  }
</style>
