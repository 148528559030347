<template>
  <div class="w-full py-0 md:py-1">
    <div class="ms-modal-slider pl-gap2">
      <Swiper
        v-bind="options"
        class="swiper-container overflow-visible"
        :modules="[SwiperNavigation]"
      >
        <SwiperSlide
          v-for="(slide, index) in amendedSlides"
          :key="`${index}`"
          class="rounded-[8px] lg:w-[387px]"
          :class="{
            'z-50': hovered === index,
          }"
          @click="handleSlideClick($event)"
          @mouseenter="hovered = index"
          @mouseleave="hovered = null"
        >
          <div
            class="slide relative block w-auto transition-all ease-out lg:w-[387px]"
          >
            <div
              class="ms-modal-slider--media relative h-full w-full origin-center transition-all hover:z-30 md:hover:border-[15px] md:hover:border-solid md:hover:border-white md:hover:shadow-3"
            >
              <ImagesNuxtImg
                v-if="slide.fields.image"
                :data-slide-link="slide.fields?.link || ''"
                :data-slide-linkType="slide.fields?.linkType || ''"
                :class="{
                  'absolute bottom-0 left-0':
                    slide.fields.mediaPosition &&
                    slide.fields.mediaPosition === 'Bottom',
                  'h-full w-full scale-75 object-cover':
                    slide.fields.mediaPosition &&
                    slide.fields.mediaPosition === 'Center',
                  relative:
                    slide.fields.mediaPosition === undefined ||
                    slide.fields.mediaPosition === null,
                }"
                class="absolute z-20"
                :url="slide.fields.image.fields.image.fields.file.url"
                :alt="
                  slide.fields.image.fields.alt
                    ? slide.fields.image.fields.alt
                    : ''
                "
                :lazy="true"
                width="387"
                height="387"
              />
              <ImagesNuxtImg
                v-if="slide.fields.backgroundMedia"
                :data-slide-link="slide.fields?.link || ''"
                :data-slide-linkType="slide.fields?.linkType || ''"
                class="relative z-10"
                :url="slide.fields.backgroundMedia.fields.file.url"
                :alt="
                  slide.fields.backgroundMedia.fields.description
                    ? slide.fields.backgroundMedia.fields.description
                    : ''
                "
                :lazy="true"
                width="387"
                height="387"
              />
            </div>
            <div
              class="ms-modal-slider--text relative flex flex-col pt-1/2 transition-all ease-out md:flex-row md:justify-between"
            >
              <div
                v-if="slide.fields.content"
                class="flex w-7/12 flex-col md:border-0"
              >
                <RichTextRenderer
                  class="tcs-text-content"
                  :document="slide.fields.content"
                  :node-renderers="nodeRenderers"
                />
              </div>
              <span
                class="my-1 block h-[1px] w-full border-b-1 border-gray-lightest md:hidden"
              ></span>
              <p
                v-if="slide.fields?.label"
                class="ms-modal-slider--label mb-0 flex w-auto flex-row flex-nowrap items-center self-start pb-0 font-bold text-blue hover:cursor-pointer hover:text-blue-dark"
                role="button"
                :data-slide-link="slide.fields?.link || ''"
                :data-slide-linkType="slide.fields?.linkType || ''"
              >
                {{ slide.fields.label }}
                <IconsSvgModal class="w-[20px] pl-1/2" />
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        class="mt-3"
        :class="
          carousel.navigationPlacement === 'Bottom Center' &&
          carousel.navigation === true
            ? 'flex justify-center'
            : ''
        "
      >
        <ButtonsSemanticButton
          id="modal-prev-button"
          :text="''"
          :aria-label="'Previous'"
          :classes="''"
          class="mr-1/2 h-3 w-3 rounded-full shadow-1 active:scale-90 active:shadow-none"
          :disabled="false"
          ><IconsPreviousArrowIcon
            :dropshadow="true"
            class="pointer-events-none h-full w-full"
            :fill-color="'#0384FB'"
          />
        </ButtonsSemanticButton>
        <ButtonsSemanticButton
          id="modal-next-button"
          :text="''"
          :aria-label="'Next'"
          :classes="''"
          class="h-3 w-3 rounded-full shadow-1 active:scale-90 active:shadow-none"
          :disabled="false"
        >
          <IconsNextArrowIcon
            :dropshadow="true"
            class="pointer-events-none h-full w-full"
            :fill-color="'#0384FB'"
          />
        </ButtonsSemanticButton>
      </div>
      <ModalsTheModal
        v-if="openVideoModal"
        :is-open="openVideoModal"
        :close-location="'outer'"
        @close="closeVideoModal"
      >
        <VideosYoutubeEmbed
          ref="video"
          :youtube-player-state="'play'"
          :video="{ id: ytId }"
        />
      </ModalsTheModal>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      carousel: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const hovered = ref(null);

      return {
        hovered,
      };
    },
    data() {
      return {
        document: this.carousel.content,
        openVideoModal: false,
        ytPlayerState: 'unstarted',
        interval: null,
        ytId: null,
        updateOnWindowResize: true,
        options: {
          loop: false,
          navigation: {
            nextEl: '#modal-next-button',
            prevEl: '#modal-prev-button',
          },
          lazy: {
            loadPrevNext: true,
          },
          allowTouchMove: true,
          watchOverflow: true,
          spaceBetween: 22,
          centeredSlides: false,
          slidesPerView: 'auto',
          breakpoints: {
            0: {
              allowTouchMove: true,
              centeredSlides: false,
              spaceBetween: 22,
              slidesPerGroup: 1, // sets 1 active slide
            },
            436: {
              allowTouchMove: true,
              centeredSlides: false,
              spaceBetween: 22,
              slidesPerGroup: 1, // sets 1 active slide
            },
            1080: {
              centeredSlidesBounds: true,
              allowTouchMove: false,
            },
          },
        },
      };
    },
    computed: {
      amendedSlides() {
        const slidesLength = this.carousel.slides.length;
        if (slidesLength < 6) {
          const copy = [...this.carousel.slides];

          do {
            const [first, second] = this.carousel.slides.slice(0, 2);
            copy.push(second, first);
          } while (copy.length < 6);

          return copy;
        } else {
          return this.carousel.slides;
        }
      },
    },
    methods: {
      handleSlideClick(event) {
        /* This click is for handling looping slide click events for event propogation. Duplicated slides will not work with binding the click event at the slide level. */
        this.openModal(
          event.target.getAttribute('data-slide-link'),
          event.target.getAttribute('data-slide-linkType')
        );
      },
      closeVideoModal() {
        this.openVideoModal = false;
      },
      openModal(url, type) {
        if (type === 'Modal Video') {
          url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          this.ytId =
            url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
          this.ytPlayerState = 'play';
          this.openVideoModal = true;
        }
        if (type === 'Modal') {
          const modal = document.querySelector(`#${url}`);
          fadeIn(modal, 'block');
          document.querySelector('html').classList.add('overflow-y-hidden');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .swiper-container {
    @apply relative overflow-visible;
  }

  .swiper-slide {
    @apply h-auto w-[387px];
  }
  .swiper-wrapper {
    @apply pb-3 pt-3;
  }

  .slide {
    .ms-modal-slider--media {
      @screen md {
        &:hover {
          @apply scale-125 cursor-pointer rounded-md;
        }

        &:hover + .ms-modal-slider--text {
          @apply translate-y-[40px];
          .ms-modal-slider--label {
            @apply text-blue-dark;
          }
        }
      }
    }
  }

  .modal {
    :deep(.yt-embed-inner),
    :deep(.yt-embed-container) {
      @apply h-full pb-0;
    }
    :deep(.youtubeIframe) {
      @apply relative;
    }
  }

  .modal .yt-embed-container.video {
    margin: 0 auto 50px;
  }
</style>
