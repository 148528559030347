<template>
  <Transition>
    <div v-show="loading" class="placeholder-content flex h-full flex-wrap">
      <div
        v-for="i in lineNum"
        :key="'line' + i"
        class="placeholder-content_item grow"
        :style="`width:${getRandomInt(50)}%;`"
      ></div>
    </div>
  </Transition>
</template>

<script setup>
  const props = defineProps({
    height: {
      type: Number,
      required: false,
      default: 2000,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  });

  const HEIGHT = 20;

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const lineNum = computed(() => {
    const num = props.height / HEIGHT;
    const rounded = Math.floor(num);

    return rounded;
  });
</script>

<style lang="scss" scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .placeholder-content {
    overflow: hidden;
    background: #fff;
    position: absolute;
    width: 100%;
    // Animation
    &_item {
      height: 20px;
      animation-duration: 1.7s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: placeholderAnimate;
      background: #f6f7f8; // Fallback
      background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
      background-size: 1300px; // Animation Area
      z-index: 2;
      margin-bottom: 10px;
      margin-right: 10px;
      border-radius: 5px;

      &:after,
      &:before {
        width: inherit;
        height: inherit;
        content: '';
        position: absolute;
      }
    }
  }

  @keyframes placeholderAnimate {
    0% {
      background-position: -650px 0;
    }
    100% {
      background-position: 650px 0;
    }
  }
</style>
