import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
  state: () => ({
    query: '',
    results: null,
  }),
  getters: {
    getQuery: (state) => state.query,
    getResults: (state) => state.results,
  },
  actions: {
    cleanResults(results: any, locale: string) {
      if (results?.hits?.length === 0) {
        this.results = null;
        return;
      }

      let tempResults = results;
      // Filter out results that don't have content in the current locale
      // Currently account for both availableInLocale true or null (for entries that don't have this set)
      tempResults.hits = tempResults.hits.filter((hit: any) => {
        return hit.availableInLocale?.[locale];
      });

      // Filter out paid pages
      tempResults.hits = tempResults.hits.filter((hit: any) => {
        if (hit?.contentType?.sys?.id === 'modularPage') {
          const urlSection = hit.urlSection?.[locale];
          const hasUrlPrefix = hit.urlPrefix?.[locale];
          if (hit.urlPrefix?.[locale] === 'demos') {
            return false;
          }
          return !(urlSection === 'pages' && !hasUrlPrefix);
        }
        return true;
      });

      this.results = {
        ...tempResults,
        hits: tempResults.hits.filter(
          (hit: any) => !hit?.indexing || !hit.indexing.includes('noindex')
        ),
      };
    },
  },
});
