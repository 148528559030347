<template>
  <div
    class="search__input flex w-[100%] max-w-[695px] justify-center justify-self-center rounded-md bg-blue-dark p-space2"
  >
    <FormKit
      v-model="query"
      type="search"
      label-class="text-white text-xs font-medium"
      input-class="w-[calc(100%-55px)] h-[48px] rounded-l-md rounded-r-none p-[20px] focus:outline-blue outline outline-[2px] outline-blue"
      wrapper-class="!h-full text-left"
      outer-class="w-full"
      inner-class="flex items-center"
      message-class="text-red mt-[4px] text-xs block"
      messages-class="!ml-0"
      placeholder="Search Samsara"
      autofocus
      @change="executeSearch(false)"
      maxlength="512"
    >
      <template #suffix="{ value, state }">
        <ButtonsSearchButton
          query="border-blue bg-blue"
          @click="executeSearch(false)"
          class="p-0"
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup>
  const query = ref('');
  const { result, search } = useAlgoliaSearch('samsara');
  const store = useSearchStore();
  const { locale } = useI18n();
  const { analyticsTrack } = useAnalytics();
  const route = useRoute();
  const router = useRouter();

  const sanitizeQuery = (query) => {
    const maxLength = 512;
    return query
      .replace(/[^\w\s-]/g, '') // Allow alphanumeric, space, dash
      .substring(0, maxLength);
  };

  const executeSearch = async (empty = false) => {
    if (query.value === '') {
      executeEmptySearch();
      return;
    }
    await search({
      query: sanitizeQuery(query.value),
      clickAnalytics: true,
    });
    store.$patch((state) => {
      state.query = query.value;
    });
    store.cleanResults(result.value, locale.value);
    analyticsTrack('Search Executed', {
      searchQuery: query.value,
      results: result.value?.hits?.length
        ? `${result.value.hits.length} Results Found`
        : 'No Results',
      resultQuantity: result.value?.hits?.length,
    });

    router.push({
      path: route.path,
      query: { ...route.query, query: query.value },
    });
  };

  const emptySearch = useAlgoliaSearch('samsara_empty_query');

  const executeEmptySearch = async () => {
    await emptySearch.search({ query: '', clickAnalytics: true });
    store.$patch((state) => {
      state.query = '';
    });
    store.cleanResults(emptySearch.result.value, locale.value);
  };

  onMounted(() => {
    if (route.query.query) {
      query.value = route.query.query;
      executeSearch();
    } else {
      executeEmptySearch();
    }
  });
</script>
<style lang="scss" scoped>
  .search__input {
    margin: 0 auto; //Force center for safari
  }
  :deep(input[type='search']::-webkit-search-cancel-button) {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1L1 12" stroke="%2300263E" stroke-width="2" stroke-linecap="round"/><path d="M12 12L1 1" stroke="%2300263E" stroke-width="2" stroke-linecap="round"/></svg>')
      no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  :deep(input[type='search']:focus::-webkit-search-cancel-button) {
    opacity: 1;
    pointer-events: all;
  }

  :deep(input[type='search'].dark::-webkit-search-cancel-button) {
    filter: invert(1);
  }
</style>
