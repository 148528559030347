import { defineStore } from 'pinia';

export const usePricingFormStore = defineStore('pricingForm', {
  state: () => ({
    _mkto_trk: '',
    advertisingOptIn: true,
    analyticsOptIn: true,
    backButtonText: '',
    channel_name: '',
    cid: '',
    country: '',
    disclaimers: {
      marketing: {},
      privacy: {},
      payload: {
        has_agreed_to_privacy_policy: false,
        has_opted_out_of_email: false,
        email_blog_opt_in: false,
        email_event_opt_in: false,
        email_using_samsara_opt_in: false,
        email_newsletter_opt_in: false,
        email_product_ann_updates_opt_in: false,
        email_resource_opt_in: false,
        email_special_offer_opt_in: false,
        email_surveys_opt_in: false,
        email_webinar_opt_in: false,
      },
      marketingOptIn: false,
    },
    experimentName: '',
    formFields: {},
    formTitle: '',
    fpv: '',
    functionalOptIn: true,
    geoFetched: false,
    gclid: '',
    isEU: false,
    keyword: '',
    lastUpdated: '',
    locale: '',
    mixpanelID: '',
    msclkid: '',
    phoneType: '',
    quantcastID: '',
    recaptchaToken: '',
    referrer: '',
    referralID: '',
    segmentID: '',
    submitted: false,
    stepValidationStatus: [null, null, null],
    thankYou: '',
    urlPrefix: '',
    utm_campaign: '',
    utm_content: '',
    utm_ext_ad_id: '',
    utm_ext_adset_id: '',
    utm_ext_campaign_id: '',
    utm_medium: '',
    utm_source: '',
    utm_term: '',
    utm_ts: '',
    writtenLanguage: '',
    gdprOptInStatus: null,
    formUngated: false,
    shortFormSkip: {
      skipEmail: false,
    },
  }),
  actions: {
    setDisclaimersPayload(locale) {
      this.disclaimers.payload.has_agreed_to_privacy_policy = true;

      Object.keys(this.disclaimers.payload).forEach((key) => {
        if (key === 'has_agreed_to_privacy_policy') {
          return;
        }

        if (locale === 'en-US' || locale === 'mx') {
          if (key === 'has_opted_out_of_email') {
            return;
          }
          this.disclaimers.payload[key] = true;
        } else {
          if (key === 'has_opted_out_of_email') {
            this.disclaimers.payload[key] = !this.disclaimers.marketingOptIn;
            return;
          }

          this.disclaimers.payload[key] = this.disclaimers.marketingOptIn;
        }
      });
    },
    verifyPayload() {
      if (
        this.formFields.channel_name === 'Organic Fleet' &&
        this.formFields.assetcount !== ''
      ) {
        if (
          this.formFields.assetcount === '1 - 2' ||
          this.formFields.assetcount === '3 - 10'
        ) {
          this.formFields.industry = '';
        } else {
          this.formFields.features = '';
        }
      }
    },
  },
  getters: {
    bussinessUnitAssetTypes: (state) => {
      return state.formFields?.businessunit?.length > 0
        ? state.formFields.businessunit.toString()
        : '';
    },
    assetTypes: (state) => {
      return state.formFields?.assets?.length > 0
        ? state.formFields.assets.toString()
        : '';
    },
    fleetFeatures: (state) => {
      return state.formFields?.features?.length > 0
        ? state.formFields.features.toString()
        : '';
    },
    fleetIndustries: (state) => {
      return state.formFields?.industry?.length > 0
        ? state.formFields.industry.toString()
        : '';
    },
    fleetSize: (state) => {
      let size = '';

      if (state.formFields?.assetcount) {
        size = state.formFields.assetcount;
      }

      if (state.formFields?.equipmentsegment) {
        size = state.formFields.equipmentsegment;
      }

      return size;
    },
  },
  persist: [
    {
      key: 'PricingForm',
      paths: [
        'channel_name',
        'country',
        'experimentName',
        'formFields',
        'submitted',
      ],
      storage: persistedState.localStorage,
    },
    {
      key: 'shortForm',
      paths: ['formUngated'],
      storage: persistedState.sessionStorage,
    },
    {
      key: 'shortFormSkips',
      paths: ['shortFormSkip'],
      storage: persistedState.sessionStorage,
    },
  ],
});
