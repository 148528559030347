<template>
  <div
    v-if="card.type && card.type === 'Full'"
    class="card card--full relative w-full overflow-hidden"
    :class="{
      'card--full-alt': card.title || card.bodyText,
      'card--full-has-link': card.link !== undefined,
    }"
  >
    <LinksLocaleLink
      v-if="card.link ?? false"
      :link="card.link"
      class="absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full"
    />
    <div
      v-if="card.title || card.bodyText"
      class="pointer-events-none absolute left-0 top-0 z-20 block"
    >
      <h1 v-if="card.title" class="mb-0 block px-2 pt-2 text-white">
        {{ card.title }}
      </h1>
      <p
        v-if="card.bodyText"
        class="block px-2 font-bold text-white !opacity-100"
      >
        {{ card.bodyText }}
      </p>
    </div>
    <div>
      <div
        v-if="card.title || card.bodyText"
        class="gradient-top pointer-events-none absolute top-0 z-10 block w-full"
      ></div>
    </div>
    <div
      class="card__zoom-bg absolute h-full w-full bg-cover bg-no-repeat transition-transform duration-100 ease-in-out"
    >
      <ImagesNuxtImg
        v-if="card.image.fields.image.fields.file"
        :url="card.image.fields.image.fields.file.url"
        :lazy="false"
        class="h-full w-full object-cover"
        :alt="card.image.fields.alt ? card.image.fields.alt : ''"
      />
    </div>
    <div
      :class="{ 'pointer-events-none': card.link }"
      class="text--all-white card__copy-wrap absolute bottom-0 z-10 flex h-auto flex-col whitespace-normal"
    >
      <div
        v-if="card.title || card.bodyText"
        class="relative my-1 hidden h-[1px] w-full bg-white opacity-50 md:block"
      ></div>
      <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
      <div
        v-if="card.cta"
        ref="copyWrap"
        :class="{
          'mt-1/4 md:mt-1': card.title || card.bodyText,
          'mt-1': !card.title && !card.bodyText,
        }"
      >
        <div v-for="(cta, index) in card.cta" :key="index">
          <ButtonsTheButton
            v-if="cta.sys.contentType.sys.id === 'button'"
            :button="cta.fields"
          />
          <LinksArrowLink
            v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
            :arrow-link="cta.fields"
          />
        </div>
      </div>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
  <div
    v-else-if="card.type && card.type === 'Reveal'"
    class="card card--reveal flex h-auto flex-1 flex-col transition-all ease-out hover:cursor-pointer"
    :class="[
      {
        'md:rounded-md md:shadow-2': totalItems > 3,
        'keep-reveal-card my-2 rounded-md shadow-2': totalItems <= 3,
      },
      revealClasses,
    ]"
    @click="show"
  >
    <span
      class="card--reveal-title p-0"
      :class="{
        'md:pl-1 md:pr-1 md:pt-2': totalItems > 3 && !active,
        'pl-1 pr-1 pt-2': totalItems <= 3 && !active,
        'p-0': active,
      }"
    >
      <ImagesPicture
        v-if="card.image !== undefined ? true : false"
        :image="card.image.fields"
        :classes="{
          'w-full md:block hidden max-w-[70px] mb-1 m-auto': totalItems > 3,
          'w-full block max-w-[70px] mb-1 m-auto': totalItems <= 3,
        }"
        :lazy="false"
      />
      <h3
        v-if="card.title"
        class="normal-case text-blue-dark"
        :class="{
          'text-left md:text-center': card.textAlign === 'Center',
          'text-center': card.textAlign === 'Center' && totalItems <= 3,
          'text-left': card.textAlign === 'Left',
          'text-left md:text-right': card.textAlign === 'Right',
          'text-right': card.textAlign === 'Right' && totalItems <= 3,
          'mb-0 pb-0 pt-1': active,
          'mb-0 py-1': !active,
          'flex flex-row items-center justify-between md:block md:pb-0 md:pt-0':
            totalItems > 3,
          'block pb-0 pt-0': totalItems <= 3,
        }"
      >
        <span
          :class="{
            'w-8/12': totalItems > 3,
            'w-auto': totalItems <= 3,
          }"
        >
          {{ card.title }}
        </span>
        <span class="block h-[7px] w-[13px] md:hidden" v-if="totalItems > 3">
          <IconsSvgChevronDownIcon class="card--icon-chevron h-full w-full" />
        </span>
      </h3>
    </span>
    <span class="card--reveal-content flex flex-col"
      ><MarkdownTheMarkdown
        v-if="card.content"
        class="h-full overflow-auto"
        :class="{
          'text-left md:text-center': card.textAlign === 'Center',
          'text-center': card.textAlign === 'Center' && totalItems <= 3,
          'text-left': card.textAlign === 'Left',
          'text-left md:text-right': card.textAlign === 'Right',
          'text-right': card.textAlign === 'Right' && totalItems <= 3,
          'mb-0': !active,
          'mb-1': active,
          'block md:flex md:flex-col md:items-center md:justify-center md:px-2 md:py-1':
            totalItems > 3,
          'mt-3 px-2 pb-1': totalItems <= 3,
        }"
        :source="card.content"
    /></span>
    <span
      class="mb-2 self-center md:block"
      :class="{
        'mt-16': !active,
        'hidden md:block': totalItems > 3,
        'block ': totalItems <= 3,
      }"
    >
      <IconsSvgPlusCircleIcon
        class="card--reveal-icon-desktop"
        :class="revealClasses"
        :active="active"
      />
    </span>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
  <CardsCardMediaSwap
    v-else-if="card.type && card.type === 'Media Swap'"
    :id="id"
    tabindex="0"
    :aspect-ratio="aspectRatio"
    :card="card"
  />
  <div
    v-else-if="card.type && card.type === 'Simple Left Border'"
    class="card card--simple-border"
  >
    <LinksLocaleLink
      v-if="card.link"
      :link="card.link"
      class="block pl-0 underline md:pl-1/2"
    >
      <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
    </LinksLocaleLink>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
  <div
    v-else-if="card.type && card.type === 'Products Link'"
    class="card panel__subsection"
  >
    <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
    <template v-if="card.cta">
      <template v-for="(cta, index) in card.cta" :key="index">
        <ButtonsTheButton
          v-if="cta.sys.contentType.sys.id === 'button'"
          :button="cta.fields"
        />
        <LinksArrowLink
          v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
          :arrow-link="cta.fields"
        />
      </template>
    </template>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
  <LinksLocaleLink
    v-else-if="card.type && card.type === 'Icon Link'"
    :link="card.link"
    class="card card--icon mb:mb-1/4 mx-0 mb-1/4 mt-1/4 flex cursor-pointer flex-row items-center whitespace-normal p-0 no-underline duration-200 ease-in-out md:py-1/2 md:pl-1/2 md:pr-1"
  >
    <IconsTheIcon v-if="card.image" :icon="card.image.fields" />
    <div class="text-wrap">
      <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="inline"
      >
        <path
          d="M6 1L11.1648 5.45055L6 9.9011"
          stroke="#00263E"
          stroke-width="2.5"
        />
        <rect y="4.25" width="11" height="2.5" fill="#00263E" />
      </svg>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </LinksLocaleLink>
  <CardsLogoCard
    v-else-if="card.type && card.type === 'Logo Link'"
    :id="id"
    :card="card"
    :class="card.classes !== undefined ? card.classes : ''"
    :dropshadow="card.dropshadow ? card.dropshadow : false"
    :filter="card.filter ? card.filter : 'Greyscale'"
    class="card lc-card--logo-link"
  >
    <template #copy>
      <h5
        v-if="card.title"
        class="mt-2 px-1 text-blue"
        :class="{
          'text-center': card.textAlign === 'Center',
          'text-left': card.textAlign === 'Left',
          'text-right': card.textAlign === 'Right',
        }"
      >
        {{ card.title }}
      </h5>
      <p
        v-if="card.bodyText"
        class="mx-2 pb-0"
        :class="{
          'text-center': card.textAlign === 'Center',
          'text-left': card.textAlign === 'Left',
          'text-right': card.textAlign === 'Right',
        }"
      >
        {{ card.bodyText }}
      </p>
    </template>
  </CardsLogoCard>
  <ModalsImageModal
    v-else-if="card.type && card.type === 'Image Modal'"
    :id="id"
    :card="card"
    class="card card--image-modal"
  />
  <CardsDefaultModalCard
    v-else-if="card.type && card.type === 'Default Modal'"
    :content="card"
  >
    <div class="card__img-wrap">
      <ImagesPicture v-if="card.image" :image="card.image.fields" />
    </div>
    <div
      ref="copyWrap"
      class="card__copy-wrap flex flex-col pb-2 pl-1 pr-1 pt-1"
    >
      <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
      <div v-for="(cta, index) in card.cta" :key="index">
        <ButtonsTheButton
          v-if="cta.sys.contentType.sys.id === 'button'"
          :button="cta.fields"
        />
        <LinksArrowLink
          v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
          :arrow-link="cta.fields"
        />
      </div>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </CardsDefaultModalCard>
  <div
    v-else-if="card.type && card.type === 'White Rounded'"
    class="card--white-rounded h-full rounded-md bg-white p-0 pb-2 pt-2"
  >
    <div class="card__img-wrap w-auto text-center">
      <ImagesPicture v-if="card.image" :image="card.image.fields" />
    </div>
    <div v-if="card.content" class="card__copy-wrap text-blue-dark">
      <MarkdownTheMarkdown :source="card.content" />
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
  <CardsAnimatedImageCard
    v-else-if="card.type === 'Animated Image'"
    :id="id"
    :card="card"
    class="card card--image-animated"
  />
  <CardsProductCalloutCard
    v-else-if="
      card.type &&
      (card.type === 'Product Callout Double' ||
        card.type === 'Product Callout Single')
    "
    :content="card"
    class="card card--product-callout"
  />
  <CardsBlogQuoteCard
    v-else-if="card.type && card.type === 'Blog Quote'"
    :content="card"
    class="card card--blog-quote"
  />
  <LazyCardsSimpleImageLeft
    v-else-if="card.type && card.type === 'Simple Image Left'"
    :content="card"
    :card-id="id"
    class="card card--simple-image-left"
    :class="{ 'shadow-2': card.dropshadow ? card.dropshadow : false }"
  />
  <div
    v-else-if="card.type && card.type === 'Blank'"
    :class="{
      'rounded-[16px] bg-white shadow-2':
        card.dropshadow !== false || card.dropshadow === null,
    }"
    class="card card--blank relative flex flex-col"
  >
    <div
      :class="{
        'pb-2 pl-2 pr-2 pt-2':
          card.dropshadow !== false || card.dropshadow === null,
      }"
    >
      <div :class="aspectRatioClasses">
        <ImagesNuxtImg
          v-if="card.image && card.image.fields.image.fields.file"
          :url="card.image.fields.image.fields.file.url"
          :alt="card.image.fields.alt ? card.image.fields.alt : ''"
          :class="{
            'absolute left-0 right-0 top-0 inline-block h-full w-full object-cover align-bottom':
              aspectRatio !== '',
            'relative inline-block h-auto': aspectRatio === '',
          }"
        />
      </div>
      <div class="flex flex-col px-0 pt-1">
        <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
        <div v-for="(cta, index) in card.cta" :key="index">
          <ButtonsTheButton
            v-if="cta.sys.contentType.sys.id === 'button'"
            :button="cta.fields"
          />
          <LinksArrowLink
            v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
            :arrow-link="cta.fields"
          />
        </div>
      </div>
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </div>
  </div>
  <template v-else-if="card.type && card.type === 'Default'">
    <LinksLocaleLink
      v-if="card.link"
      :link="card.link"
      @click="trackLinkClick(card)"
      target="_blank"
      class="card relative flex flex-col overflow-hidden rounded-[16px] bg-white"
      :class="{ 'shadow-2': card.dropshadow ? card.dropshadow : false }"
    >
      <div class="card__img-wrap">
        <ImagesPicture
          v-if="card.image"
          :image="card.image.fields"
          :lazy="false"
        />
      </div>
      <div
        ref="copyWrap"
        class="card__copy-wrap flex flex-col pb-2 pl-2 pr-2 pt-1"
      >
        <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
        <div v-for="(cta, index) in card.cta" :key="index">
          <ButtonsTheButton
            v-if="cta.sys.contentType.sys.id === 'button'"
            :button="cta.fields"
          />
          <LinksArrowLink
            v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
            :arrow-link="cta.fields"
          />
        </div>
      </div>
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </LinksLocaleLink>
    <div
      v-else
      class="card relative flex flex-col overflow-hidden rounded-[16px] bg-white"
      :class="{ 'shadow-2': card.dropshadow ? card.dropshadow : false }"
    >
      <div class="card__img-wrap">
        <ImagesPicture
          v-if="card.image"
          :image="card.image.fields"
          :lazy="false"
        />
      </div>
      <div
        ref="copyWrap"
        class="card__copy-wrap flex flex-col pb-2 pl-2 pr-2 pt-1"
      >
        <MarkdownTheMarkdown v-if="card.content" :source="card.content" />
        <div v-for="(cta, index) in card.cta" :key="index">
          <ButtonsTheButton
            v-if="cta.sys.contentType.sys.id === 'button'"
            :button="cta.fields"
          />
          <LinksArrowLink
            v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
            :arrow-link="cta.fields"
          />
        </div>
      </div>
      <LinksContentfulLink v-if="id" :id="id" label="card" />
    </div>
  </template>

  <div v-else></div>
</template>

<script>
  export default {
    props: {
      card: {
        type: Object,
        required: true,
      },
      aspectRatio: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      totalItems: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data() {
      return {
        active: false,
      };
    },
    setup() {
      const { analyticsTrack } = useAnalytics();
      return {
        analyticsTrack,
      };
    },
    computed: {
      aspectRatioClasses() {
        return this.aspectRatio !== ''
          ? 'relative flex h-0 flex-1 origin-center flex-col ' +
              this.aspectRatio
          : '';
      },
      otherClasses() {
        if (this.card.filter) {
          if (this.card.filter === 'Reveal Dark Blue') {
            return 'flip-bg-dark-blue';
          } else if (this.card.filter === 'White') {
            return 'flip-bg-white';
          }
        }
        return '';
      },
      revealClasses() {
        let activeClass = this.active ? 'active' : 'inactive';

        if (this.otherClasses) {
          return `${activeClass} ${this.otherClasses}`;
        } else {
          return activeClass;
        }
      },
    },
    methods: {
      show() {
        this.active = !this.active;
      },
      trackLinkClick(card) {
        if (card.trackingId) {
          this.analyticsTrack('Card Click', {
            tracking_id: card.trackingId,
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .card {
    &.active {
      @screen md {
        @apply bg-white;
      }
      &.keep-reveal-card {
        @apply bg-blue-lighter;
        .card--reveal-title {
          @apply h-0 opacity-0;
        }
        &.flip-bg-white {
          @apply bg-white;
        }
      }
      .card--reveal-content {
        @apply h-full opacity-100;
        p {
          @apply mb-0 pt-0;
        }
      }
      .card--icon-chevron {
        transform: rotateX(180deg);
      }
      &:not(.keep-reveal-card) {
        .card--reveal-title {
          @apply h-auto opacity-100 md:hidden md:h-0 md:opacity-0;
        }
      }
      &:not(.flip-bg-white) {
        @apply md:bg-blue-lighter;
      }
    }

    &.card--reveal {
      .card--reveal-content {
        p {
          @apply mb-0;
        }
      }
      &.keep-reveal-card {
        &.flip-bg-white {
          @apply bg-white;
        }
      }
      &:hover {
        @screen md {
          @apply hover:scale-105;
        }
        svg.card--reveal-icon-desktop {
          fill: theme('colors.blue.DEFAULT');
          circle,
          line {
            @apply stroke-white;
          }
        }
      }
      @media (min-width: theme('screens.md')) {
        svg.card--reveal-icon-desktop.flip-bg-dark-blue.active {
          fill: theme('colors.white');
          circle,
          line {
            stroke: theme('colors.blue.DEFAULT');
          }
        }
        &.flip-bg-dark-blue.active {
          @apply bg-blue-dark;
          .card--reveal-content {
            h4,
            p {
              color: theme('colors.white');
            }
          }
        }
        &.flip-bg-white {
          @apply bg-white;
        }
      }
      @media (max-width: theme('screens.md')) {
        &.keep-reveal-card.flip-bg-dark-blue.active {
          @apply bg-blue-dark;
          .card--reveal-content {
            h4,
            p {
              color: theme('colors.white');
            }
          }
          svg.card--reveal-icon-desktop.flip-bg-dark-blue.active {
            fill: theme('colors.white');
            circle,
            line {
              stroke: theme('colors.blue.DEFAULT');
            }
          }
        }
      }
    }

    &.inactive {
      .card--reveal-title {
        @apply h-full opacity-100;
      }
      .card--reveal-content {
        @apply h-0 opacity-0;
      }
    }
  }

  .card--white-rounded {
    .card__copy-wrap {
      h5,
      p,
      li {
        color: theme('colors.blue.dark') !important;
      }

      h5 {
        @apply pt-1 text-center;
      }

      p {
        margin-bottom: 0;
      }

      ul {
        @apply m-0 list-none pl-0;

        li {
          @apply mb-1/4 pl-1 pr-1/2;

          font-size: 15px;

          &::before {
            content: '• ';
            color: theme('colors.blue.DEFAULT');
            margin-left: -8px;
          }
        }
      }

      @media (max-width: theme('screens.md')) {
        ul {
          text-align: center;

          li {
            padding-left: 0;
          }
        }
      }
    }
  }

  .card--icon {
    .text-wrap > div {
      display: inline;
    }

    .icon {
      @apply mb-0 mr-1/2 mt-0 h-auto;

      max-width: 40px;
      max-height: 40px;

      @media (max-width: theme('screens.md')) {
        @apply hidden;
      }
    }

    h5 {
      @apply mb-0 inline text-blue-dark;
    }

    p {
      @apply mb-0;
    }

    em {
      @apply not-italic text-blue;
      @media (min-width: theme('screens.md')) {
        @apply hidden;
      }
    }

    svg {
      width: 12px;
      margin-left: 4px;

      rect {
        opacity: 0;
        transition: 0.1s ease-in-out;
      }

      path {
        transform: translateX(-5px);
        transition: 0.1s ease-in-out;
      }
    }

    &:hover {
      svg {
        rect {
          opacity: 1;
          fill: theme('colors.blue.DEFAULT');
        }

        path {
          transform: translateX(0);
          stroke: theme('colors.blue.DEFAULT');
        }
      }

      h5 {
        color: theme('colors.blue.DEFAULT');
      }

      @media (max-width: theme('screens.md')) {
        box-shadow: none;
      }

      .icon {
        filter: invert(53%) sepia(69%) saturate(6933%) hue-rotate(194deg)
          brightness(102%) contrast(98%);
      }
    }
  }

  .card--simple-border {
    border-bottom: 1px solid rgba(#00263e, 0.12); // can't grab from theme due to scss

    h4 {
      @apply mb-2 text-lg leading-7;

      text-decoration-color: theme('colors.blue.dark');
    }

    @media (min-width: theme('screens.md')) {
      min-height: 150px;
      border-left: 1px solid rgba(#00263e, 0.12);
      border-bottom: 0;

      h4 {
        @apply mb-0;
      }
    }
  }
  .card--full {
    padding-bottom: 98%;
    width: calc(100% + 22px);

    .gradient-top {
      @apply h-[50%] opacity-100 md:h-[33%];
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &::after {
      content: '';
      @apply absolute left-0 h-full w-full;

      background: linear-gradient(
        358.15deg,
        rgba(0, 0, 0, 0.85) 13.66%,
        rgba(0, 38, 62, 0) 77.89%
      );
    }

    &:hover {
      .card__zoom-bg {
        transform: scale(1.1);
      }
    }

    @media (min-width: 1680px) {
      height: 711px;
      @apply pb-0;
    }

    .card__copy-wrap {
      max-width: 550px;
      padding: 0 0 85px 85px;

      p {
        @apply pr-2;
      }

      @media (max-width: theme('screens.lg')) {
        padding: 0 22px 22px 22px;
      }
    }
    @media (max-width: theme('screens.md')) {
      margin-bottom: -20px;
    }
  }
  .card__img-wrap {
    img {
      @apply h-auto w-full;
    }
  }
  .card__img-wrap-blank {
    img {
      @apply w-auto;
    }
  }

  .card__copy-wrap {
    h2 {
      @apply mb-0;
    }
  }
</style>
