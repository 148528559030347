<template>
  <div class="base--button-wrapper">
    <button
      :data-id="props.button?.id"
      class="uppercase"
      :class="[
        buttonClasses,
        { 'flex flex-row justify-between gap-1': iconComponent },
      ]"
      @click="buttonAction"
    >
      <component
        :is="iconComponent"
        v-if="
          iconComponent &&
          props.button?.iconPosition === IconPosition.BEFORE_TEXT
        "
        :color="
          props.button?.variant === ButtonVariant.PRIMARY_BLUE
            ? 'white'
            : 'black'
        "
      />
      {{ props.button?.text }}
      <component
        :is="iconComponent"
        v-if="
          iconComponent &&
          props.button?.iconPosition === IconPosition.AFTER_TEXT
        "
        :color="
          props.button?.variant === ButtonVariant.PRIMARY_BLUE
            ? 'white'
            : 'black'
        "
      />
    </button>
  </div>
</template>

<script setup lang="ts">
  import {
    ButtonAction,
    Icon,
    IconPosition,
    ButtonVariant,
  } from '~/types/enums/button';
  import { scrollIntoView } from '~/utils';
  import { fadeIn } from '~/utils/html-helpers';

  const props = defineProps({
    button: {
      type: Object,
      required: true,
    },
  });

  const playAudio = () => {
    const audio = new Audio(props.button?.sourceUrl ?? '');
    audio.play();
  };

  const openModal = () => {
    if (props.button?.id && document.getElementById(props.button?.id)) {
      const element = document.getElementById(props.button.id);
      fadeIn(element, 'block');
      document.querySelector('html').classList.add('overflow-y-hidden');
    }
  };

  const scrollToHash = () => {
    if (props.button?.id && document.getElementById(props.button?.id)) {
      const element = document.getElementById(props.button.id);
      if (element) {
        scrollIntoView(element, 'smooth', 'start');
      }
    }
  };

  const actionMap = new Map([
    [ButtonAction.PLAY_AUDIO, playAudio],
    [ButtonAction.JUMP_LINK, scrollToHash],
    [ButtonAction.OPEN_MODAL, openModal],
  ]);

  const iconMap = new Map([
    [Icon.AUDIO, defineAsyncComponent(() => import('../icons/SvgAudio.vue'))],
    [Icon.MODAL, defineAsyncComponent(() => import('../icons/SvgModal.vue'))],
  ]);

  const iconComponent = computed(() => iconMap.get(props.button?.icon as Icon));

  const buttonClasses = computed(() => {
    let variantClasses;
    let defaultClasses = 'transition-all duration-150 ease-in ';

    switch (props.button?.variant) {
      case ButtonVariant.PRIMARY_BLUE:
        variantClasses = 'btn btn--blue btn--blue:hover';
        break;
      default:
        variantClasses = '';
    }

    return props.button?.classes
      ? defaultClasses + variantClasses + ' ' + props.button?.classes.join(' ')
      : defaultClasses + variantClasses;
  });

  const buttonAction = computed(() => {
    return actionMap.get(props.button?.action as ButtonAction);
  });
</script>
