<template>
  <div
    :class="`animate-set-${intervalCount}`"
    class="lw-wrapper-animated-flip lw-wrapper-inner relative block h-full w-full"
  >
    <div
      class="lw-wrapper-animated-flip--items md:px-0"
      :class="[props.innerWrapperClasses]"
    >
      <ListsListItem
        v-for="(item, firstFlipIndex) of animationFlipSet1"
        :key="`flipItem-set-1-${firstFlipIndex}`"
        :style="{
          transitionDelay: steppedAnimationDelay
            ? `${firstFlipIndex * steppedAnimationDelay}s`
            : '0s',
          transitionDuration: `${transitionTime}s`,
        }"
        :list-item-data-id="firstFlipIndex"
        :list-item-ctfl-id="item.sys?.id"
        :item-type="props.entryObj.listType"
        :item-fields="item.fields"
        :has-animation="props.entryObj.animationType ? true : false"
        :aspect-ratio="props.entryObj.featuredMediaAspectRatio"
        :content-type="item.sys?.contentType?.sys?.id || 'blogPost'"
        :item-link-text="props.entryObj.linkText"
        :media-position="
          props.entryObj.featuredMediaPosition || 'object-center'
        "
        :show-author="props.entryObj.showAuthor || false"
        :total-items="items.length"
        :class="[itemClasses]"
        class="h-[78px] text-left transition-all will-change-transform"
      />
    </div>
    <template v-if="animationFlipSets.length > 0">
      <div
        v-for="(flipItems, setIndex) of animationFlipSets"
        :key="`flipSet-${setIndex}`"
        class="lw-wrapper-animated-flip--items absolute top-0 w-full md:px-0"
        :class="[props.innerWrapperClasses]"
      >
        <ListsListItem
          v-for="(item, nthItemIndex) of flipItems"
          :key="`flipItem-set-${setIndex}-${nthItemIndex}`"
          :list-item-data-id="nthItemIndex"
          :style="{
            transitionDelay: steppedAnimationDelay
              ? `${nthItemIndex * steppedAnimationDelay}s`
              : '0s',
            transitionDuration: `${transitionTime}s`,
          }"
          :list-item-ctfl-id="item.sys?.id"
          :item-type="props.entryObj.listType"
          :item-fields="item.fields"
          :has-animation="props.entryObj.animationType ? true : false"
          :aspect-ratio="props.entryObj.featuredMediaAspectRatio"
          :content-type="item.sys?.contentType?.sys?.id || 'blogPost'"
          :item-link-text="props.entryObj.linkText"
          :media-position="
            props.entryObj.featuredMediaPosition || 'object-center'
          "
          :show-author="props.entryObj.showAuthor || false"
          :total-items="props.items.length"
          :class="[itemClasses]"
          class="h-[78px] text-left transition-all will-change-transform"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
  import { AnimationType } from '~/types/enums/animation';

  const props = defineProps({
    items: {
      type: Array,
      required: true,
    },
    animationType: {
      type: String,
      required: true,
    },
    entryObj: {
      type: Object,
      required: true,
    },
    innerWrapperClasses: {
      type: String,
      required: true,
    },
    horizontalItemAlignment: {
      type: String,
      default: 'justify-center',
    },
    verticalItemAlignment: {
      type: String,
      default: 'items-center',
    },
    itemsPerRowDesktop: {
      type: String,
      default: 'lg:w-4/12',
    },
    itemsPerRowTablet: {
      type: String,
      default: 'md:w-3/12',
    },
    itemsPerRowMobile: {
      type: String,
      default: 'w-12/12',
    },
    defaultItemClasses: {
      type: String,
      default: 'block',
    },
  });
  const intervalCount = ref(1);

  const steppedAnimationDelay = computed(
    () => props.entryObj.steppedAnimationDelay ?? false
  );
  const transitionTime = computed(
    () => props.entryObj.itemAnimationDuration ?? 0.5
  );
  const animationDuration = computed(
    () => props.entryObj.animationDurationCycle ?? 3
  );

  const itemClasses = computed(() => [
    props.horizontalItemAlignment,
    props.verticalItemAlignment,
    props.itemsPerRowDesktop,
    props.itemsPerRowTablet,
    props.itemsPerRowMobile,
    props.defaultItemClasses,
  ]);

  const animationFlipSet1 = computed(() => {
    let items = [];
    if (
      props.itemsPerRowDesktop === 'lg:w-[20%]' &&
      props.itemsPerRowTablet === 'md:w-[20%]' &&
      props.itemsPerRowMobile === 'w-6/12'
    ) {
      items = props.items.slice(0, 5);
    } else if (
      props.itemsPerRowDesktop === 'lg:w-3/12' &&
      props.itemsPerRowTablet === 'md:w-3/12' &&
      props.itemsPerRowMobile === 'w-6/12'
    ) {
      items = props.items.slice(0, 4);
    }
    return items;
  });

  const animationFlipSets = computed(() => {
    const sets = [];
    const setSize = animationFlipSet1.value.length;
    const totalItems = props.items.length;

    for (let i = setSize; i < totalItems; i += setSize) {
      sets.push(props.items.slice(i, i + setSize));
    }

    return sets;
  });

  const runFlipAnimation = () => {
    const totalSets =
      animationFlipSets.value.length +
      (animationFlipSet1.value.length !== 0 ? 1 : 0);
    setInterval(() => {
      if (intervalCount.value >= totalSets) {
        intervalCount.value = 1;
      } else {
        intervalCount.value++;
      }
    }, animationDuration.value * 1000);
  };
  onMounted(() => {
    if (AnimationType.FLIP === props.animationType) {
      setTimeout(() => {
        intervalCount.value = 2;
      }, 1000);
      runFlipAnimation();
    }
  });
</script>

<style lang="scss" scoped>
  .lw-wrapper-animated-flip {
    &.animate-set-1 {
      .lw-wrapper-animated-flip--items {
        &:nth-child(1) {
          .li-list-item {
            opacity: 1;
            filter: blur(0px);
            transform: scale(1);
          }
        }
      }
    }
    &.animate-set-2,
    &.animate-set-3 {
      .lw-wrapper-animated-flip--items {
        &:nth-child(1) {
          .li-list-item {
            opacity: 0;
            filter: blur(5px);
            transform: scale(0.8);
          }
        }
      }
    }
    &.animate-set-2 {
      .lw-wrapper-animated-flip--items {
        &:nth-child(2) {
          .li-list-item {
            opacity: 1;
            filter: blur(0px);
            transform: scale(1);
          }
        }
      }
    }
    &.animate-set-3 {
      .lw-wrapper-animated-flip--items {
        &:nth-child(3) {
          .li-list-item {
            opacity: 1;
            filter: blur(0px);
            transform: scale(1);
          }
        }
      }
    }

    .lw-wrapper-animated-flip--items {
      &:nth-child(2),
      &:nth-child(3) {
        .li-list-item {
          opacity: 0;
          filter: blur(5px);
          transform: scale(0.8);
        }
      }

      .li-list-item {
        @apply mt-0;
        transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);

        :deep(span) {
          @apply flex h-full w-full justify-center;
          img {
            @apply h-full w-auto object-contain;
          }
        }
      }
    }
  }
</style>
