<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0001 7.71371C18.0001 11.197 15.118 14.0524 11.5235 14.0524C7.92891 14.0524 5.04686 11.197 5.04686 7.71371C5.04686 4.23041 7.92891 1.375 11.5235 1.375C15.118 1.375 18.0001 4.23041 18.0001 7.71371Z"
      :stroke="fillColor"
      stroke-width="2"
    />
    <path
      d="M1.00012 16.625L6.48398 11.9073"
      :stroke="fillColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      fillColor: {
        type: String,
        required: false,
        default: '#ffffff',
      },
    },
  };
</script>
