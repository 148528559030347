import validate from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirects_45global from "/codebuild/output/src3556124522/src/horizon/middleware/01.redirects.global.js";
import manifest_45route_45rule from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "6s-redirect": () => import("/codebuild/output/src3556124522/src/horizon/middleware/6s-redirect.js"),
  "lang-redirect": () => import("/codebuild/output/src3556124522/src/horizon/middleware/lang-redirect.js")
}