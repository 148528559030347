<template>
  <UtilitiesErrorBoundary tag="row">
    <LinksContentfulLink
      v-if="props.row.sys.id"
      :id="props.row.sys.id"
      label="row"
    />
    <template v-if="props.row?.fields?.rowStyle === 'Variation Container'">
      <template v-if="!ldLoading && experiment !== undefined">
        <ModularExperimentGroup :experimental-row="experimentalRow" />
      </template>
    </template>

    <template v-else>
      <component
        :is="component"
        :id="props.row.fields.row_id"
        class="row"
        :class="allClasses"
        v-bind="currentProperties"
      >
        <template v-if="isValidHeroType">
          <ModularColumn
            v-for="(column, index) in props.row.fields.content"
            :id="column.sys.id"
            :key="index"
            :column="column"
          />
        </template>
      </component>
    </template>
  </UtilitiesErrorBoundary>
</template>

<script setup>
  import {
    createRouter,
    createMemoryHistory,
    createWebHistory,
  } from 'vue-router';
  import { AnalyticsEvents } from '~/types/enums/emitter-events';
  import { EventName } from '~/types/enums/mixpanel';
  import { getNestedProperty } from '~/utils';

  const props = defineProps({
    row: {
      type: Object,
      required: true,
    },
    rowPosition: {
      type: [String, Number],
      required: false,
      default: null,
    },
  });

  const id = useId();
  const { $emitter } = useNuxtApp();
  const classes = useAttrs().class;
  const experiment = useState(`experiment-${id}`, () => false);
  const experimentReason = useState(`experimentReason-${id}`, () => 'OFF');
  const route = useRoute();
  const { isMobile } = useDevice();
  const { locale } = useI18n();

  const ldLoading = ref(false);

  const isValidHeroType = computed(() => {
    return [
      'Full Responsive',
      'Full Responsive Short',
      'Full Responsive Split',
      'Full Responsive Split Pattern 1',
      'Full Responsive Split Pattern 2',
      'Full Responsive Split Oval',
      'Split',
      'Split Reverse',
      'Shape Overlay Hero',
    ].includes(props.row.fields.heroType);
  });

  const rowStyle = computed(() =>
    props.row.fields.rowStyle ? props.row.fields.rowStyle : undefined
  );

  if (
    props.row.fields.experimentId &&
    rowStyle.value === 'Variation Container'
  ) {
    const { data: ldDetail, error: ldApiError } = await useFetch(
      '/api/evaluate-launch-darkly',
      {
        headers: { 'Cache-Control': 'no-cache' },
        query: {
          isMobile,
          ldKey: props.row.fields.experimentId,
          locale: locale.value,
          route: route.path,
        },
        onResponse({ response }) {
          experiment.value = response._data?.value ?? false;
          experimentReason.value = response._data?.reason.kind ?? 'OFF';
          ldLoading.value = false;
        },
        onResponseError() {
          experiment.value = false;
          experimentReason.value = 'OFF';
          ldLoading.value = false;
        },
      }
    );
  }

  provide('modularRow:rowStyle', rowStyle.value);
  provide('modularRow:rowTracking', {
    name: props.row.fields.internalName.replaceAll(' ', '_'),
    tracking_id: props.row.fields.row_id
      ? props.row.fields.row_id
      : props.row.sys.id,
    row_type: props.row.fields.rowStyle,
  });

  const allClasses = computed(() => {
    return `${classes ? classes : ''} ${bgColorClasses.value}`;
  });

  const heroType = computed(() =>
    props.row.fields.heroType ? props.row.fields.heroType : undefined
  );

  const currentProperties = computed(() => {
    if (
      [
        'Full Responsive',
        'Full Responsive Short',
        'Full Responsive Split',
        'Full Responsive Split Pattern 1',
        'Full Responsive Split Pattern 2',
        'Full Responsive Split Oval',
        'Shape Overlay Hero',
      ].includes(props.row.fields.heroType)
    ) {
      return {
        hero: props.row.fields,
        id: props.row.sys.id,
      };
    } else if (['Split', 'Split Reverse'].includes(props.row.fields.heroType)) {
      return {
        hero: props.row.fields,
        type: props.row.fields.heroType || 'Split',
      };
    } else if (props.row.fields.heroType === 'Cards') {
      return { content: props.row.fields };
    } else {
      return {
        row: props.row,
        isVideo: isVideo.value,
        innerWrapperClasses: container.value,
        outerWrapperClasses: wrapClasses.value,
        rowEntryWrapperClasses: rowClasses.value,
      };
    }
  });

  const component = computed(() => {
    const componentsMap = new Map([
      [
        'Full Responsive',
        defineAsyncComponent(() => import('../heroes/FullHeroResponsive.vue')),
      ],
      [
        'Full Responsive Short',
        defineAsyncComponent(
          () => import('../heroes/FullHeroResponsiveShort.vue')
        ),
      ],
      [
        'Full Responsive Split',
        defineAsyncComponent(
          () => import('../heroes/FullHeroResponsiveSplit.vue')
        ),
      ],
      [
        'Full Responsive Split Pattern 1',
        defineAsyncComponent(
          () => import('../heroes/FullHeroResponsiveSplitPattern1.vue')
        ),
      ],
      [
        'Full Responsive Split Pattern 2',
        defineAsyncComponent(
          () => import('../heroes/FullHeroResponsiveSplitPattern2.vue')
        ),
      ],
      [
        'Full Responsive Split Oval',
        defineAsyncComponent(
          () => import('../heroes/FullHeroResponsiveSplitOval.vue')
        ),
      ],
      [
        'Shape Overlay Hero',
        defineAsyncComponent(() => import('../heroes/ShapeOverlayHero.vue')),
      ],
      ['Split', defineAsyncComponent(() => import('../heroes/SplitHero.vue'))],
      [
        'Split Reverse',
        defineAsyncComponent(() => import('../heroes/SplitHero.vue')),
      ],
      [
        'Row With Background Media Container',
        defineAsyncComponent(
          () => import('./RowWithBackgroundMediaContainer.vue')
        ),
      ],
      [
        'Row With Background Media',
        defineAsyncComponent(() => import('./RowWithBackgroundMedia.vue')),
      ],
      [
        'Hero Bottom',
        defineAsyncComponent(() => import('../heroes/HeroBottom.vue')),
      ],
      [
        'Cards',
        defineAsyncComponent(() => import('../heroes/HeroCardModal.vue')),
      ],
      ['Modal Popup', defineAsyncComponent(() => import('./RowModal.vue'))],
      ['Row Default', defineAsyncComponent(() => import('./RowDefault.vue'))],
    ]);

    if (
      [
        'Full Responsive',
        'Full Responsive Short',
        'Full Responsive Split',
        'Full Responsive Split Pattern 1',
        'Full Responsive Split Pattern 2',
        'Full Responsive Split Oval',
        'Split',
        'Split Reverse',
        'Cards',
        'Shape Overlay Hero',
      ].includes(props.row.fields.heroType)
    ) {
      return componentsMap.get(props.row.fields.heroType);
    }

    if (props.row.fields.rowStyle === 'Modal Popup') {
      return componentsMap.get(props.row.fields.rowStyle);
    }

    if (props.row.fields.backgroundMediaContainer) {
      return componentsMap.get('Row With Background Media Container');
    }

    if (
      props.row.fields.backgroundImage &&
      props.row.fields.heroType !== 'Hero Top' &&
      props.row.fields.heroType !== 'Hero Bottom'
    ) {
      return componentsMap.get('Row With Background Media'); // broken
    }

    if (
      props.row.fields.backgroundImage &&
      props.row.fields.rowStyle === 'Hero Bottom'
    ) {
      return componentsMap.get(props.row.fields.rowStyle);
    }

    return componentsMap.get('Row Default');
  });

  const bgColorClasses = computed(() => {
    const bgColorsMap = {
      'Light Gray': 'bg-gray-lighter',
      'Light Blue': 'bg-blue-lighter',
      'Dark Blue': 'bg-blue-dark text--all-white',
      'Vertical 70% Dark Blue': 'bg--70-dark-blue text--all-white',
      'Vertical Half Light Blue': 'bg--half-light-blue',
      'Horizontal 80% Light Blue': 'bg--eighty-percent-light-blue',
      'Horizontal 80% Light Blue Reverse':
        'bg--eighty-percent-light-blue bg--eighty-percent-light-blue-reverse',
      'Dark Gray': 'bg-gray-darkest text--all-white',
      White: 'bg-white',
      Black: 'bg-[#111111] text--all-white',
      Green: 'bg-green',
      '75% Light Blue Pattern': 'bg-light-blue bg--seventyfive-percent-pattern',
      '50% Light Blue Pattern': 'bg-light-blue bg--fifty-percent-pattern',
      'Mobile Vertical 70% Dark Blue':
        'bg--70-dark-blue-mobile text--all-white',
      'Vertical 50% Dark Blue': 'bg--50-dark-blue text--all-white',
    };

    if (props.row.fields.rowStyle === 'Modal Popup') {
      return '';
    }

    return props.row.fields.backgroundColor
      ? ` ${bgColorsMap[props.row.fields.backgroundColor]}`
      : '';
  });

  const wrapClasses = computed(() => {
    const bgColorsMap = {
      'Light Gray': 'bg-gray-lighter',
      'Light Blue': 'bg-blue-lighter',
      'Dark Blue': 'bg-blue-dark text--all-white',
      'Vertical 70% Dark Blue': 'bg--70-dark-blue text--all-white',
      'Vertical Half Light Blue': 'bg--half-light-blue',
      'Horizontal 80% Light Blue': 'bg--eighty-percent-light-blue',
      'Horizontal 80% Light Blue Reverse':
        'bg--eighty-percent-light-blue bg--eighty-percent-light-blue-reverse',
      'Dark Gray': 'bg-gray-darkest text--all-white',
      White: 'bg-white',
      Green: 'bg-green',
      '75% Light Blue Pattern': 'bg-light-blue bg--seventyfive-percent-pattern',
      '50% Light Blue Pattern': 'bg-light-blue bg--fifty-percent-pattern',
      'Mobile Vertical 70% Dark Blue':
        'bg--70-dark-blue-mobile text--all-white',
      'Vertical 50% Dark Blue': 'bg--50-dark-blue text--all-white',
    };

    const bgImageClasses =
      getNestedProperty(props.row, 'fields.backgroundImage', false) &&
      getNestedProperty(
        props.row,
        'fields.backgroundImage.sys.contentType.sys.id',
        false
      ) === 'image' &&
      getNestedProperty(
        props.row,
        'fields.backgroundImage.fields.image.fields.file.url',
        false
      )
        ? ' bg-center bg-cover bg-no-repeat'
        : '';

    const bgColor =
      props.row.fields.backgroundColor &&
      bgColorsMap[props.row.fields.backgroundColor]
        ? ` ${bgColorsMap[props.row.fields.backgroundColor]}`
        : '';

    const heroTypesMap = {
      Tall: 'hero--tall',
      'Shape Overlay Hero': 'hero--tall',
      'Tall Left': 'hero--tall hero--tall-left',
      Narrow: 'hero--narrow',
      Contact: 'hero--contact bg--two-thirds-light-blue',
      'Contact Reverse':
        'hero--contact-reverse bg--two-thirds-light-blue bg--two-thirds-light-blue-reverse',
      'Fade Out': `hero--fade-out ${
        isVideo.value ? 'hero--fade-out-video' : ''
      } h-[460px] flex relative items-center`,
    };

    const heroType = props.row.fields.heroType
      ? ` ${heroTypesMap[props.row.fields.heroType]}`
      : '';

    const bannerClasses = ` h-[460px] items-center flex text--all-white dim dim--light ${bgImageClasses}`;

    if (['Body', 'Modal Popup'].includes(props.row.fields.rowStyle)) {
      return `${bgColor}${bgImageClasses}`;
    }

    if (props.row.fields.rowStyle === 'Hero Top') {
      if (['Contact', 'Contact Reverse'].includes(props.row.fields.heroType)) {
        return heroType;
      }

      if (props.row.fields.heroType === 'Fade Out') {
        return `${bgImageClasses} ${heroType} hero--top`;
      }

      return `${bannerClasses}${heroType} hero--top`;
    }

    if (props.row.fields.rowStyle === 'Hero Bottom') {
      return ` text-center ${bannerClasses}`;
    }

    return '';
  });

  const customInnerWrapperClasses = computed(() =>
    props.row.fields.classes ? props.row.fields.classes.join(' ') : ''
  );

  const container = computed(() => {
    if (
      (props.row.fields.rowStyle === 'Body' &&
        props.row.fields.fullWidth === true) ||
      (props.row.fields.content &&
        props.row.fields.content[0]?.sys?.contentType?.sys?.id !==
          'modularColumn')
    ) {
      return '';
    }

    return `row-container w-full px-gap2 lg:container z-10 ${customInnerWrapperClasses.value}`;
  });

  const rowClasses = computed(() => {
    const paddingClassDesktop = {
      Default: '4',
      '0px': '0',
      '20px': '1',
      '35px': '2',
      '50px': '3',
      '80px': '4',
      '120px': '5',
      '180px': '6',
    };

    const marginClassDesktop = {
      Default: '0',
      '0px': '0',
      '20px': '1',
      '35px': '2',
      '50px': '3',
      '80px': '4',
      '120px': '5',
      '180px': '6',
    };

    const marginClassMobile = {
      Default: '0',
      '0px': '0',
      '20px': '1',
      '35px': '2',
      '50px': '3',
      '80px': '4',
      '120px': '5',
      '180px': '6',
    };

    const paddingClassMobile = {
      Default: '3',
      '0px': '0',
      '20px': '1',
      '35px': '2',
      '50px': '3',
      '80px': '4',
      '120px': '5',
      '180px': '6',
    };

    const marginClasses = {
      marginDesktopTop: props.row.fields.desktopMarginTop
        ? ` md:mt-${marginClassDesktop[props.row.fields.desktopMarginTop]}`
        : ` ${marginClassDesktop.Default}`,
      marginDesktopBottom: props.row.fields.desktopMarginBottom
        ? ` md:mb-${marginClassDesktop[props.row.fields.desktopMarginBottom]}`
        : ` md:mb-${marginClassDesktop.Default}`,
      marginMobileTop: props.row.fields.mobileMarginTop
        ? ` mt-${marginClassMobile[props.row.fields.mobileMarginTop]}`
        : ` ${marginClassMobile.Default}`,
      marginMobileBottom: props.row.fields.mobileMarginBottom
        ? ` mb-${marginClassMobile[props.row.fields.mobileMarginBottom]}`
        : ` ${marginClassMobile.Default}`,
    };

    const paddingClasses = {
      paddingDesktopTop: props.row.fields.desktopPaddingTop
        ? ` md:pt-${paddingClassDesktop[props.row.fields.desktopPaddingTop]}`
        : ` md:pt-${paddingClassDesktop.Default}`,
      paddingDesktopBottom: props.row.fields.desktopPaddingBottom
        ? ` md:pb-${paddingClassDesktop[props.row.fields.desktopPaddingBottom]}`
        : ` md:pb-${paddingClassDesktop.Default}`,
      paddingMobileTop: props.row.fields.mobilePaddingTop
        ? ` pt-${paddingClassMobile[props.row.fields.mobilePaddingTop]}`
        : ` pt-${paddingClassMobile.Default}`,
      paddingMobileBottom: props.row.fields.mobilePaddingBottom
        ? ` pb-${paddingClassMobile[props.row.fields.mobilePaddingBottom]}`
        : ` pb-${paddingClassMobile.Default}`,
    };

    const mobileSwap =
      props.row.fields.swapOrderMobile === true
        ? ' flex-col-reverse md:flex-row items-start'
        : '';

    const columnLayoutClasses =
      props.row.fields.content &&
      props.row.fields.content[0]?.sys?.contentType?.sys?.id === 'modularColumn'
        ? 'columns-wrapper flex -mx-gap flex-wrap'
        : '';

    return `${columnLayoutClasses}${mobileSwap}${paddingClasses.paddingDesktopTop}${paddingClasses.paddingDesktopBottom}${marginClasses.marginDesktopTop}${marginClasses.marginDesktopBottom}${paddingClasses.paddingMobileBottom}${paddingClasses.paddingMobileTop}${marginClasses.marginMobileTop}${marginClasses.marginMobileBottom}`;
  });

  const isVideo = computed(() =>
    Boolean(
      props.row.fields.backgroundImage &&
        props.row.fields.backgroundImage.sys.contentType.sys.id === 'video'
    )
  );

  const scrollTo = (id) => {
    const target = document.querySelector(`.row#${id}`);
    if (target) {
      window.scrollTo({
        top: target.getBoundingClientRect().top + window.scrollY - 100,
        behavior: 'smooth',
      });
      setTimeout(() => {
        $emitter.emit('subnav-sticky-active');
      }, 200);
    }
  };

  const experimentalRow = computed(() => {
    if (
      props.row.fields.rowStyle === 'Variation Container' &&
      props.row.fields.content
    ) {
      return (
        props.row.fields.content.find(
          (row) => row.fields.variationId === String(experiment.value)
        ) || Object.values(props.row.fields.content)[0]
      );
    }
    return null;
  });

  onMounted(() => {
    const isServer = typeof window === 'undefined';
    const history = isServer ? createMemoryHistory() : createWebHistory();
    const router = createRouter({ routes: [], history });
    const route = router.currentRoute;
    const hash = route?.value?.hash;

    if (hash) {
      const newHash = hash.replace('#', '');
      if (document.querySelector(`#${newHash}`)) {
        router.replace({ path: route.path.split('#')[0], hash: '' });
        scrollTo(newHash);
      }
    }
    $emitter.on('scroll-to-id', (value) => {
      if (document.querySelector(`.row#${value.id}`)) {
        scrollTo(value.id);
      }
    });

    if (
      props.row.fields.rowStyle === 'Variation Container' &&
      props.row.fields.experimentName &&
      experimentReason.value === 'RULE_MATCH'
    ) {
      $emitter.emit(AnalyticsEvents.TRACK, {
        event: `${props.row.fields.experimentName} Evaluated`,
        experiment_id: props.row.fields.experimentName,
        flagKey: props.row.fields.experimentName,
        variation_id: experiment.value,
      });
      $emitter.emit(AnalyticsEvents.TRACK, {
        event: EventName.EXPERIMENT_STARTED,
        'Experiment name': props.row.fields.experimentName,
        'Variant name': experiment.value,
      });
      if (sendCSAnalyticsEvent) {
        sendCSAnalyticsEvent(props.row.fields.experimentId, experiment.value);
      }
    }
  });

  onUnmounted(() => {
    experiment.value = false;
    experimentReason.value = 'OFF';
  });
</script>

<style lang="scss">
  // Non scoped so changes filter down

  .bg--two-thirds-light-blue {
    background: linear-gradient(
      to right,
      theme('colors.blue.lighter') 66%,
      theme('colors.white') 33%
    );
  }
  .bg--two-thirds-light-blue-reverse {
    background: linear-gradient(
      to left,
      theme('colors.blue.lighter') 66%,
      theme('colors.white') 33%
    );
  }
  .bg--half-light-blue {
    background: linear-gradient(
      to bottom,
      theme('colors.blue.lighter') 60%,
      theme('colors.white') 30%
    );
  }
  .bg--70-dark-blue {
    background: linear-gradient(
      theme('colors.blue.dark') 70%,
      theme('colors.white') 30%
    );
    background-size: 100% 101% !important;
  }
  .bg--50-dark-blue {
    background: linear-gradient(
      theme('colors.blue.dark') 50%,
      theme('colors.white') 50%
    );
    background-size: 100% 101% !important;
  }
  .bg--70-dark-blue-mobile {
    background: theme('colors.blue.dark');
    background-size: 100% 101% !important;
    @media (max-width: theme('screens.md')) {
      background: linear-gradient(
        theme('colors.blue.dark') 80%,
        theme('colors.white') 20%
      );
      background-size: 100% 101% !important;
    }
  }

  .flex-col-reverse .column:not(:first-of-type) {
    @media (max-width: theme('screens.md')) {
      @apply mb-1;
    }
  }
  .flex-col-reverse .column:not(:last-of-type) {
    @media (max-width: theme('screens.md')) {
      @apply mb-0;
    }
  }
  .tabs-col-reverse {
    @media (min-width: theme('screens.md')) {
      @apply flex-row-reverse;
    }
    @media (max-width: theme('screens.md')) {
      @apply flex-col-reverse;
    }
  }
</style>
