import { datadogLogs } from '@datadog/browser-logs';

export default defineNuxtPlugin({
  name: 'datadog-logs',
  setup(nuxtApp) {
    const { ddClientToken } = useRuntimeConfig().public;

    nuxtApp.hook('app:mounted', () => {
      datadogLogs.init({
        clientToken: ddClientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: false,
        sessionSampleRate: 100,
      });
    });

    return {
      provide: {
        ddLoggerClient: datadogLogs,
      },
    };
  },
  env: {
    islands: false,
  },
});
