export const localeMappings = {
  fr: 'fr',
  uk: 'en-GB',
  de: 'de',
  mx: 'es-MX',
  ca: 'en-CA',
  'fr-ca': 'fr-CA',
  us: 'en-US',
  'en-US': 'en-US',
  nl: 'nl',
};

export const GQL_LOCALE_MAPPINGS = {
  fr: 'fr',
  engb: 'uk',
  de: 'de',
  esmx: 'mx',
  enca: 'ca',
  frca: 'fr-ca',
  enus: 'en-US',
  nl: 'nl',
};

export const LOCALE_CODE_TO_ISO = {
  ca: 'en-CA',
  de: 'de',
  'en-US': 'en-US',
  fr: 'fr',
  'fr-ca': 'fr-CA',
  mx: 'es-MX',
  nl: 'nl',
  uk: 'en-GB',
  us: 'en-US',
};

export const supportedLocales = [
  'fr',
  'en-GB',
  'de',
  'es-MX',
  'en-CA',
  'fr-CA',
  'en-US',
  'nl',
];

export const supportedLocalesWithRedirect = {
  fr: 'fr',
  'en-GB': 'uk',
  de: 'de',
  'es-MX': 'mx',
  'en-CA': 'ca',
  'fr-CA': 'fr-ca',
  'en-US': '',
  nl: 'nl',
  'en-IE': 'uk',
  'fr-BE': 'fr',
  'nl-BE': 'nl',
};

export const supportedEULocales = ['fr', 'de', 'uk', 'nl'];

export const supportedBlogLocales = ['en-US', 'ca', 'mx', 'uk', 'fr', 'de'];

export const hrefLocales = ['fr', 'uk', 'de', 'mx', 'ca', 'fr-ca', 'nl'];

export const fallbackLocales = ['en-CA', 'fr-CA'];

export const fallbackMappings = {
  'fr-ca': 'fr',
  ca: 'en-US',
  'en-CA': 'en-US',
  'fr-CA': 'fr',
};

export const ctflMappings = {
  fr: 'fr',
  'en-GB': 'uk',
  de: 'de',
  'es-MX': 'mx',
  'en-CA': 'ca',
  'fr-CA': 'fr-ca',
  'en-US': 'en-US',
  nl: 'nl',
};

export const flagMappings = {
  fr: 'FR',
  uk: 'UK',
  de: 'DE',
  mx: 'MX',
  ca: 'CA',
  'fr-ca': 'FR-CA',
  'en-US': 'US',
  us: 'US',
  nl: 'NL',
};

export const IPQS_ALLOW_LIST = [
  'US',
  'CA',
  'FR',
  'GB',
  'IE',
  'DE',
  'AT',
  'CH',
  'MX',
  'NL',
  'BE',
  'LU',
];

// reference: https://docs.google.com/spreadsheets/d/1TIIjShYhY0_PyKLItsjPD_NIoFXL_z_6L8ZQwt5i7Pw/edit?usp=sharing
export const IPQS_BLOCK_LIST = [
  'AO',
  'BF',
  'BI',
  'BJ',
  'BW',
  'CD',
  'CF',
  'CG',
  'CI',
  'CM',
  'CV',
  'DJ',
  'DZ',
  'EG',
  'EH',
  'ER',
  'ET',
  'GA',
  'GH',
  'GM',
  'GN',
  'GQ',
  'GW',
  'KE',
  'KM',
  'LR',
  'LS',
  'LY',
  'MA',
  'MG',
  'ML',
  'MR',
  'MU',
  'MW',
  'MZ',
  'NA',
  'NE',
  'NG',
  'RE',
  'RW',
  'SC',
  'SD',
  'SH',
  'SL',
  'SN',
  'SO',
  'ST',
  'SZ',
  'TD',
  'TG',
  'TN',
  'TZ',
  'UG',
  'YT',
  'ZA',
  'ZM',
  'ZW',
  'AF',
  'AM',
  'AZ',
  'BD',
  'BH',
  'BN',
  'BT',
  'GE',
  'HK',
  'IQ',
  'IR',
  'JO',
  'KG',
  'KH',
  'KP',
  'KW',
  'KZ',
  'LA',
  'LB',
  'LK',
  'MM',
  'MN',
  'MO',
  'MV',
  'MY',
  'NP',
  'OM',
  'PH',
  'PK',
  'PS',
  'QA',
  'SG',
  'SY',
  'TH',
  'TJ',
  'TL',
  'TM',
  'TR',
  'TW',
  'UZ',
  'VN',
  'YE',
  'RU',
  'AS',
  'AU',
  'CC',
  'CK',
  'CX',
  'FJ',
  'FM',
  'GU',
  'KI',
  'MH',
  'MP',
  'NC',
  'NF',
  'NR',
  'NU',
  'NZ',
  'PF',
  'PG',
  'PN',
  'PW',
  'SB',
  'TK',
  'TO',
  'TV',
  'VU',
  'WF',
  'WS',
  'AD',
  'AN',
  'AQ',
  'BV',
  'GS',
  'HM',
  'IO',
  'SJ',
  'TF',
  'UM',
  'VA',
  'BO',
  'CL',
  'CO',
  'EC',
  'FK',
  'GF',
  'GY',
  'MQ',
  'PE',
  'PY',
  'SR',
  'UY',
  'VE',
];

export const LOCALE_CODE_TO_COUNTRY = {
  ca: 'Canada',
  de: 'Germany',
  'en-us': 'United States',
  fr: 'France',
  'fr-ca': 'Canada',
  mx: 'Mexico',
  nl: 'Netherlands',
  uk: 'United Kingdom',
};

export const LOCALE_CODE_TO_WRITTEN_LANGUAGE = {
  ca: 'English',
  de: 'German',
  'en-us': 'English',
  fr: 'French',
  'fr-ca': 'French',
  mx: 'Spanish',
  nl: 'Dutch',
  uk: 'English',
};
