
// @ts-nocheck


export const localeCodes =  [
  "en-US",
  "ca",
  "nl",
  "uk",
  "de",
  "mx",
  "fr",
  "fr-ca"
]

export const localeLoaders = {
  "en-US": [],
  "ca": [],
  "nl": [],
  "uk": [],
  "de": [],
  "mx": [],
  "fr": [],
  "fr-ca": []
}

export const vueI18nConfigs = [
  () => import("../i18n.options.ts?hash=ef6a39e1&config=1" /* webpackChunkName: "__i18n_options_ts_ef6a39e1" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.options.ts",
  "locales": [
    {
      "code": "en-US",
      "name": "English",
      "language": "en-US"
    },
    {
      "code": "ca",
      "name": "English",
      "language": "en-CA"
    },
    {
      "code": "nl",
      "name": "Dutch",
      "language": "nl"
    },
    {
      "code": "uk",
      "name": "English",
      "language": "en-GB"
    },
    {
      "code": "de",
      "name": "German",
      "language": "de"
    },
    {
      "code": "mx",
      "name": "Spanish",
      "language": "es-MX"
    },
    {
      "code": "fr",
      "name": "French",
      "language": "fr-FR"
    },
    {
      "code": "fr-ca",
      "name": "French",
      "language": "fr-CA"
    }
  ],
  "defaultLocale": "en-US",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.samsara.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en-US",
    "name": "English",
    "language": "en-US",
    "files": []
  },
  {
    "code": "ca",
    "name": "English",
    "language": "en-CA",
    "files": []
  },
  {
    "code": "nl",
    "name": "Dutch",
    "language": "nl",
    "files": []
  },
  {
    "code": "uk",
    "name": "English",
    "language": "en-GB",
    "files": []
  },
  {
    "code": "de",
    "name": "German",
    "language": "de",
    "files": []
  },
  {
    "code": "mx",
    "name": "Spanish",
    "language": "es-MX",
    "files": []
  },
  {
    "code": "fr",
    "name": "French",
    "language": "fr-FR",
    "files": []
  },
  {
    "code": "fr-ca",
    "name": "French",
    "language": "fr-CA",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
