<template>
  <div
    class="demo-module__container flex w-full justify-center sm:items-center"
  >
    <FormsDemoForm v-if="!demoFormSubmitted" :form="entry" />
    <div :class="!demoFormSubmitted ? 'iframe-blurred' : ''">
      <iframe
        :src="modules.generalId ? modules.generalId : 'https://capture.navattic.com/cm214isge000003l51xj7gakv'"
        style="
          border: none;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100vw;
          height: 100vh;
          margin: 0;
          padding: 0;
          z-index: 1000;
        "
        allow="fullscreen"
        id="navattic-embed"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
  import { isStorageAvailable } from '~/utils/analytics';

  // Get helpers
  const { $emitter, $getContentfulPage } = useNuxtApp();

  const props = defineProps({
    modules: {
      type: Object,
      required: true
    }
  })

  // Init variables
  const demoFormSubmitted = ref(null);
  const options = {
    'sys.id': '6T9zDFHvelaN55hy9LdPT1',
    content_type: 'form',
  };

  // Get contentful data
  const { data: entry, error: entryError } = await useAsyncData(() => {
    return $getContentfulPage({ options });
  });

  if (entryError.value) {
    throw createError({ statusCode: 404 });
  }

  // Handle form submitted successfully
  const handleFormSubmittedSuccessfully = (value) => {
    demoFormSubmitted.value = value;
    if (isStorageAvailable('localStorage')) {
      localStorage.setItem('demoFormSubmitted', value);
    }
  };

  // Handle emitter
  $emitter.on('formSubmitted', handleFormSubmittedSuccessfully);

  // Lifecycle
  onMounted(() => {
    if (isStorageAvailable('localStorage')) {
      demoFormSubmitted.value = localStorage.getItem('demoFormSubmitted');
    }
  });
</script>

<style lang="scss" scoped>
  .iframe-blurred {
    iframe {
      filter: brightness(60%) blur(5px);
      pointer-events: none;
    }
  }
</style>
