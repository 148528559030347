<template>
  <div v-show="props.isActive">
    <slot></slot>
  </div>
</template>

<script setup>
  const props = defineProps({
    label: {
      type: String,
      default: 'Tab',
    },
    isActive: {
      type: Boolean,
    },
    labelMedia: {
      type: Object,
      required: false,
      default: null,
    },
  });
</script>
