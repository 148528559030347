<template>
  <transition>
    <div class="banner">
      <div class="banner__text">
        <p id="cookie-consent-message" class="mb-0 text-center text-xs">
          {{ cookieBanner.message }}
          <LinksLocaleLink
            v-if="cookieBanner.cookie_policy"
            :link="cookieBanner.cookie_policy.link"
            class="mr-1/4"
            >{{ cookieBanner.cookie_policy.text }}</LinksLocaleLink
          >
          <LinksLocaleLink
            v-if="cookieBanner.privacy_policy"
            :link="cookieBanner.privacy_policy.link"
            >{{ cookieBanner.privacy_policy.text }}</LinksLocaleLink
          >
        </p>
      </div>
      <div class="banner__buttons text-center">
        <a
          id="cookie-settings-button"
          href=""
          class="btn btn--blue-secondary btn--blue:hover"
          title="Learn more"
          @click.prevent="redirect"
          @keydown.prevent="redirect"
          >{{ cookieBanner.button1 }}</a
        >
        <a
          id="cookie-reject-button"
          href=""
          class="btn btn--blue-secondary btn--blue:hover"
          title="Reject all"
          @click.prevent="rejected"
          @keydown.prevent="rejected"
          >{{ cookieBanner.button3 }}</a
        >
        <a
          id="cookie-accept-button"
          href=""
          class="btn btn--blue btn--blue:hover"
          title="Accept"
          @click.prevent="accepted"
          @keydown.prevent="accepted"
          >{{ cookieBanner.button2 }}</a
        >
      </div>
    </div>
  </transition>
</template>

<script>
  import { cookieMessages } from '~/utils/analytics/cookie-helpers';

  export default {
    props: {
      countryCode: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        cookieBanner: cookieMessages[this.countryCode] || cookieMessages.UK,
      };
    },
    methods: {
      accepted() {
        this.$emit('accepted');
      },
      rejected() {
        this.$emit('rejected');
      },
      redirect() {
        this.$emit('redirect');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .banner {
    @apply fixed bottom-0 z-50 flex h-min w-full flex-col items-center justify-center bg-blue-lighter p-1;
  }

  .banner__text {
    margin-bottom: 20px;

    @media (max-width: theme('screens.md')) {
      margin-bottom: 5px;
    }
  }

  .banner__buttons {
    a {
      margin: 5px;
    }
  }
</style>
