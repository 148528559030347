<template>
  <HeadersGlobalDesktopNavigation
    v-if="
      !dataPending &&
      links.length > 0 &&
      navButtons.length > 0 &&
      countries.length > 0 &&
      width > 1100
    "
    :nav-buttons="navButtons"
    :countries="countries"
    :links="links"
    :is-transparent="isTransparent"
    :sticky-header="stickyHeader"
    :promotion-banner="promotionBanner"
  />
  <HeadersGlobalMobileNavigation
    v-if="
      !dataPending &&
      links.length > 0 &&
      navButtons.length > 0 &&
      countries.length > 0 &&
      width < 1100
    "
    :nav-buttons="navButtons"
    :countries="countries"
    :links="links"
    :is-transparent="isTransparent"
    :sticky-header="stickyHeader"
    :secondary-navigation-title="secondaryNavigationTitle"
    :promotion-banner="promotionBanner"
  />
</template>

<script setup>
  import { localeMappings } from '~/constants/locales';
  import { useWindowSize } from '@vueuse/core';

  const props = defineProps({
    stickyHeader: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
    secondaryNavigationTitle: {
      type: String,
      required: false,
      default: () => {
        return 'Menu';
      },
    },
    isTransparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    promotionBanner: {
      type: Object,
      required: false,
      default: () => {
        return { show: false };
      },
    },
  });

  const { isSandbox, isStaging } = useRuntimeConfig().public;
  const { locale } = useI18n();
  const { width } = useWindowSize();

  const {
    data,
    error: dataError,
    pending: dataPending,
  } = await useAsyncGql({
    operation: 'GlobalNavigation',
    variables: {
      id: '3JVZ0fPj8bAWsUzKOfCoM',
      locale: localeMappings[locale.value],
      preview: isSandbox || isStaging,
    },
  });

  if (dataError.value) {
    throw createError({ statusCode: 404 });
  }

  const links = data.value?.navHeader?.linksCollection?.items ?? [];
  const navButtons = data.value?.navHeader?.buttonsCollection?.items ?? [];
  const countries = data.value?.navHeader?.countryDropdowns?.countries ?? [];
</script>

<style lang="scss" scoped>
  .secondary-navigation {
    .mobile-secondary-navigation-active {
      svg {
        transform: rotateX(180deg);
      }
    }
    nav {
      li:not(:first-child) {
        &.link-active {
          span {
            &:after {
              content: '';
              background-color: theme('colors.blue.DEFAULT');
              @apply absolute h-[2px] w-full;
            }
          }
          p {
            color: theme('colors.blue.DEFAULT');
          }
        }
      }
      li:first-child {
        p,
        a {
          @apply font-bold text-blue-dark md:text-white;
        }
      }
    }
  }
</style>
