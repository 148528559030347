<template>
  <div class="chart mb-3 flex items-center justify-center">
    <div
      class="chart__content flex flex-row flex-wrap justify-center md:flex-nowrap"
    >
      <div
        v-for="(item, index) in chartData"
        :key="index"
        class="relative h-full w-6/12 md:w-[20%]"
      >
        <div
          class="progress-circle aspect-square w-full"
          :style="{
            '--total-items': chartData.length,
            '--percentage': item.percentage,
            '--color': item.color,
          }"
        >
          <div
            class="absolute left-[50%] top-[50%] z-10 flex w-full translate-x-[-50%] translate-y-[-50%] justify-center"
          >
            <p class="mb-0 px-0 text-xl font-medium text-white md:text-3xl">
              {{
                locale === 'en-us'
                  ? item.percentage + '% '
                  : item.percentageLabel
              }}
            </p>
          </div>
        </div>
        <p class="px-1/2 text-center text-xs text-white md:block">
          {{ item.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
  });

  const locale = useI18n();

  const fallBackcolors = ['#3b82f6'];

  const colors = computed(() =>
    props.data.map(
      (stat, index) =>
        stat.fields?.accentColor ||
        fallBackcolors[index % fallBackcolors.length]
    )
  );

  const chartData = computed(() => {
    return props.data.map((stat, index) => ({
      label: stat.fields?.statisticName || '',
      percentage: stat.fields?.statisticPercentage || 0,
      percentageLabel: stat.fields?.statisticLabel || '',
      color: colors.value[index % colors.value.length],
    }));
  });
</script>
<style lang="scss" scoped>
  .progress-circle {
    @apply relative inline-block;
    &::before,
    &::after {
      content: '';
      @apply absolute bottom-0 left-0 right-0 top-0 h-full w-full;
      border-radius: 50%;
    }
    &::before {
      background: conic-gradient(
        var(--color) calc(var(--percentage) * 1%),
        #3e5c76 calc(var(--percentage) * 1%)
      );
      clip-path: circle(40%);
    }
    &::after {
      background: theme('colors.blue.dark');
      clip-path: circle(33%);
      z-index: 2;
    }
  }
</style>
