<template>
  <div class="pt-1">
    <FormKit
      v-if="!formSubmitted"
      id="short-form"
      type="form"
      :actions="false"
      #default="{ value, state, disabled }"
      form-class="short-form"
      @submit="submitHandler"
    >
      <template v-for="(input, index) in questions" :key="index">
        <section class="input-section" :class="input.fields.type">
          <component
            :is="formComponents.get(input.fields.type)"
            :label="input.fields.label"
            :name="input.fields.name"
            :required="input.fields.required ?? true"
            :error-messages="input.fields.errorMessage"
            :options="input.fields.valueList"
            :content="input.fields.content"
          />
        </section>
      </template>

      <FormsFkButtonSubmitShortForm
        :state="state"
        :value="value"
        :label="buttonText"
      />
    </FormKit>
    <div v-if="formSubmitted">
      <MarkdownTheMarkdown :source="successResponse" />
    </div>
  </div>
</template>

<script setup>
  import {
    LOCALE_CODE_TO_COUNTRY,
    LOCALE_CODE_TO_WRITTEN_LANGUAGE,
  } from '~/constants/locales';
  import { usePricingFormStore } from '~/stores/pricing-form';
  import { fadeIn } from '~/utils/html-helpers';

  // Get Helpers
  const { analyticsTrack, analyticsAlias, analyticsIdentify } = useAnalytics();
  const {
    getQuantcastID,
    getUTMCookies,
    getSegmentId,
    getGDPROptInPreferences,
  } = useAnalyticsHelpers();
  const { $emitter } = useNuxtApp();
  const { fullPath, query } = useRoute();
  const { locale } = useI18n();
  const store = usePricingFormStore();
  const { isMobile } = useDevice();
  const formModalId = inject('row:modalId');

  const props = defineProps({
    formId: {
      type: String,
      required: true,
    },
    cid: {
      type: String,
      required: false,
      default: null,
    },
    successResponse: {
      type: String,
      required: false,
      default: null,
    },
    questions: {
      type: Object,
      required: false,
      default: null,
    },
    channelName: {
      type: String,
      required: false,
      default: null,
    },
    thankYou: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Submit',
    },
    gated: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  if (props.gated === true) {
    $emitter.emit('hasGatedForm', formModalId);
  }

  // Init Vars
  const actionURL = ref(undefined);
  const combinedFormValues = ref({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    phone: '',
    num_vehicles_range: '',
    channel_name: props.channelName,
    cid: props.cid ?? '',
    description: props.description ?? 'Short Form Default',
    inbound_form_message: '',
    thankyou: props.thankYou.replace(/^\/+/, ''),
    session_id: '',
  });
  const formSubmitted = ref(false);
  const localeSite = LOCALE_CODE_TO_COUNTRY[locale.value.toLowerCase()];

  // Run Advertising and Analytics
  const setReferralId = () => {
    const referralID = query.referralID;
    if (referralID) {
      combinedFormValues.value.referralID = referralID;
    }
  };
  const runAdvertising = () => {
    const cookies = getUTMCookies();

    cookies.forEach((cookie) => {
      combinedFormValues.value[cookie.key] = cookie.value;
    });

    if (combinedFormValues.value.referralID) {
      // Set static campaign id for referrals if
      // referral id present in query parameters
      // Overwrite any old cid cookie value
      const REFERRAL_CAMPAIGN_ID = '7014p000001LGQD';
      combinedFormValues.value.cid = REFERRAL_CAMPAIGN_ID;
    }

    combinedFormValues.value.quant_id = getQuantcastID().value;
  };

  const runAnalytics = () => {
    const mixpanelID = getSegmentId();
    const segmentID = getSegmentId();

    combinedFormValues.value.mixpanel_id = mixpanelID || '';
    combinedFormValues.value.segment_id = segmentID || '';
  };

  const getDisclaimers = () => {
    store.setDisclaimersPayload(locale.value);
    for (const [key, value] of Object.entries(store.disclaimers.payload)) {
      combinedFormValues.value[key] = value;
    }
  };

  // Action URL
  const generateFormActionURL = () => {
    const prefix = locale.value === 'en-US' ? '' : '/' + locale.value;
    const hostname = window.location.hostname;
    let base;

    if (hostname.includes('localhost')) {
      base = `http://localhost:3000/proxy`;
    } else if (hostname.includes('amplifyapp')) {
      base = `https://${hostname}/proxy`;
    } else if (hostname.includes('samsara-staging')) {
      base = 'https://www.samsara-staging.com';
    } else if (hostname.includes('samsara-sandbox')) {
      base = 'https://www.samsara-sandbox.com';
    } else {
      base = 'https://www.samsara.com';
    }

    return `${base}${prefix}/pricing-form-submit`;
  };

  const setGDPRoptInPreferences = () => {
    if (store.isEU === true) {
      const preferences = getGDPROptInPreferences();
      // Will only set the gdprOptInStatus to true if at least the analytics and advertising opt-in are true
      const gdprOptInStatus =
        preferences.analyticsOptIn && preferences.advertisingOptIn;
      combinedFormValues.value.gdpr_opt_in_status = gdprOptInStatus;
    }
  };

  // Geo
  const geoLocate = async () => {
    const geo = await getGeo();

    if (Object.keys(geo).length) {
      if (geo.isEU) {
        store.isEU = true;
        setGDPRoptInPreferences();
      }

      store.geoFetched = true;
    }
  };
  const submitHandler = async (formValues) => {
    // Prevent submission if required fields are missing
    const { firstname, lastname } = splitName(formValues.name);
    combinedFormValues.value.first_name = firstname;
    combinedFormValues.value.last_name = lastname;
    combinedFormValues.value.num_vehicles_range = formValues.assetCount;
    combinedFormValues.value.company = formValues.company;
    combinedFormValues.value.phone = store.formFields.phone;
    combinedFormValues.value.email = formValues.email;
    combinedFormValues.value.inbound_form_message = formValues.reason;
    combinedFormValues.value.cid = props.cid;
    combinedFormValues.value.session_id =
      sessionStorage.getItem('_session_id') || '';
    getDisclaimers();

    const formDataToURLEncoded = (data) => {
      return Object.keys(data)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(data[key] === undefined ? '' : data[key])}`
        )
        .join('&');
    };

    const formData = new FormData();
    Object.keys(combinedFormValues.value).forEach((key) => {
      formData.append(
        key,
        combinedFormValues.value[key] === undefined
          ? ''
          : combinedFormValues.value[key]
      );
    });

    try {
      // Convert the FormData to URL-encoded format
      const urlEncodedData = formDataToURLEncoded(combinedFormValues.value);
      const response = await fetch(actionURL.value, {
        method: 'POST',
        body: urlEncodedData,
        redirect: 'manual',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (
        response.ok ||
        (response.type === 'opaqueredirect' && response.status === 0)
      ) {
        formSubmitted.value = true;
        store.formUngated = true;

        $emitter.emit('gatedFormSubmitted', true);

        analyticsAlias(formValues.email);

        analyticsIdentify(formValues.email, {
          email: formValues.email,
          asset_count: formValues.assetCount,
          company: formValues.company,
        });

        analyticsTrack('Form Submitted', {
          form_type: 'Short Form',
          form_url: fullPath,
          is_mobile: isMobile,
          locale_site: localeSite,
          tracking_id: 'shortform_demo_module',
        });
      } else {
        formSubmitted.value = false;
      }
    } catch (error) {
      formSubmitted.value = false;
    }
  };

  // Methods
  const splitName = (name) => {
    const nameParts = name.trim().split(/\s+/);
    if (nameParts.length > 1) {
      return { firstname: nameParts.shift(), lastname: nameParts.join(' ') };
    }
    return { firstname: name, lastname: '' };
  };

  const formComponents = new Map([
    ['firstnameLastname', resolveComponent('FormsFkTextNameShortForm')],
    ['text', resolveComponent('FormsFkTextCompanyShortForm')],
    ['email', resolveComponent('FormsFkTextEmailShortForm')],
    ['phone', resolveComponent('FormsFkTextPhoneShortForm')],
    ['radio', resolveComponent('FormsFkRadioAssetCountShortForm')],
    ['textarea', resolveComponent('FormsFkTextMessageShortForm')],
    ['optin', resolveComponent('FormsFkCheckboxOptInDisclaimers')],
  ]);

  // Lifecycle

  onMounted(() => {
    if (props.gated === true && store.formUngated !== true) {
      const modal = document.querySelector(`#${formModalId}`);
      fadeIn(modal, 'block');
      document.querySelector('html').classList.add('overflow-y-hidden');
      // prevent user from being stuck
      document.querySelector('#header').style.zIndex = '60';
    }
    actionURL.value = generateFormActionURL();
    geoLocate();
    setReferralId();

    if (store.advertisingOptIn) {
      runAdvertising();
    }

    if (store.analyticsOptIn) {
      runAnalytics();
    }

    // Add experiment name to form values
    combinedFormValues.value.experimentName = store.experimentName;
    combinedFormValues.value.written_language =
      LOCALE_CODE_TO_WRITTEN_LANGUAGE[locale.value.toLowerCase()];

    // Track form started
    analyticsTrack('Form Started', {
      form_type: 'Short Form',
      form_url: fullPath,
      is_mobile: isMobile,
      locale_site: localeSite,
      tracking_id: 'shortform_demo_module',
    });
  });

  onUnmounted(() => {
    // prevent user from being stuck after navigating away without filling in form
    document.querySelector('html').classList.remove('overflow-y-hidden');
    document.querySelector('#header').style.zIndex = '50';
  });
</script>

<style lang="scss" scoped>
  .formkit-outer {
    padding: 10px 10px 0px 0px;
    &[data-family='button'] {
      padding-top: 20px;
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .button-group {
    margin-top: 16px;
  }

  .hidden-radio {
    display: none;
  }

  .fleet-size-button {
    &.selected {
      background-color: #007bff;
      color: #fff;
      border-color: #007bff;
    }
  }
  .formkit-form {
    @apply flex flex-wrap justify-center;
  }
  .input-section {
    @apply w-full px-[7px] md:w-1/2;

    &.radio,
    &.optin,
    &.textarea {
      @apply w-full;
    }

    input,
    textarea {
      @apply rounded-[4px] p-1/2 text-blue-dark;
    }
  }
  .short-form {
    :deep(label) {
      @apply mb-1/4 block text-left text-xs font-medium text-blue-dark before:hidden;
    }
    :deep(.formkit-message) {
      @apply text-left;
    }
    :deep(legend) {
      @apply text-left;
    }
    :deep(.formkit-inner) {
      @apply relative;

      input,
      textarea {
        @apply mt-0 outline outline-1 outline-gray-light hover:outline-2;

        &:hover {
          outline-color: #0384fb !important;
        }

        &:focus {
          @apply outline-2;
          filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
          outline-color: #0384fb !important;
        }
      }
    }
    :deep(.formkit-outer) {
      @apply mb-space;
    }
  }
</style>
