<template>
  <div>
    <FormsMarketoForm
      v-if="
        formId &&
        !questions &&
        type !== 'Inline Button Single Field' &&
        type !== 'Search'
      "
      :form-num="formId"
      :form-date="formDate"
      :form-type="formType"
      :custom-confirm="successResponse || null"
      :prevent-redirect="true"
      :gated="gated"
      :form-info="formInfo"
      :type="type"
      :action="action"
      :secondary-id="secondaryId"
      @webinar:submitted="$emit('webinar:submitted')"
    />
    <FormsShortForm
      v-else-if="formId && questions && type !== 'Inline Button Single Field'"
      :form-id="formId"
      :success-response="successResponse"
      :channel-name="channelName"
      :questions="questions"
      :thank-you="thankYou"
      :description="description"
      :cid="cid"
      :button-text="buttonText"
      :gated="gated"
    />
    <SearchInput v-else-if="formId && type === 'Search'" />
    <FormsInlineCtaForm
      v-else-if="formId && questions && type === 'Inline Button Single Field'"
      :form-id="formId"
      :success-response="successResponse"
      :channel-name="channelName"
      :questions="questions"
      :thank-you="thankYou"
      :description="description"
      :cid="cid"
      :button-text="buttonText"
      :action="action"
      :type="type"
    />
  </div>
</template>
<script>
  export default {
    props: {
      formId: {
        type: String,
        required: true,
      },
      cid: {
        type: String,
        required: false,
        default: '',
      },
      formType: {
        type: String,
        required: false,
        default: '',
      },
      formDate: {
        type: String,
        required: false,
        default: '',
      },
      successResponse: {
        type: String,
        required: false,
        default: null,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      gated: {
        type: Boolean,
        required: false,
        default: false,
      },
      questions: {
        type: Object,
        required: false,
        default: null,
      },
      channelName: {
        type: String,
        required: false,
        default: null,
      },
      thankYou: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: false,
        default: '',
      },
      buttonText: {
        type: String,
        required: false,
        default: 'Default',
      },
      action: {
        type: String,
        required: false,
        default: 'Default',
      },
      type: {
        type: String,
        required: false,
        default: 'Default',
      },
      formInfo: {
        type: Object,
        required: false,
        default: null,
      },
      secondaryId: {
        type: [String, Number],
        required: false,
        default: null,
      },
    },
    emits: ['webinar:submitted'],
    setup() {
      useCreateMarketo();
    },
  };
</script>
