import { supportedLocalesWithRedirect } from '~/constants/locales';
import { isStorageAvailable } from '~/utils/analytics';

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return; // don't run on server

  // Only run on homepage (/ or empty path)
  if (to.fullPath !== '/' && to.fullPath !== '') return;

  const userAgent = navigator.userAgent.toLowerCase();
  const isSearchEngineBot =
    /bot|crawl|spider|google|bing|yandex|baidu|duckduckgo|adsbot|slurp/.test(
      userAgent
    );

  // Skip redirection for bots
  if (isSearchEngineBot) return; // Allow bots to crawl the current page without redirection

  if (!isStorageAvailable('sessionStorage')) return; // don't run if localstorage can't be set

  const langChecked = sessionStorage.getItem('browser-lang-checked');

  if (!langChecked) {
    const browserLang = navigator.language;
    sessionStorage.setItem('browser-lang-checked', browserLang);

    const targetLocale = supportedLocalesWithRedirect[browserLang];

    if (targetLocale) {
      return navigateTo(`/${targetLocale}`, { external: true });
    }
  }

  // Proceed to the intended page if no redirection occurs
});
