<template>
  <div class="m-auto">
    <div id="marketo-form" class="form--marketo">
      <form
        :id="`mktoForm_${formNum}`"
        class="relative m-auto block"
        :data-locale="locale"
      ></form>
    </div>
    <ClientOnly>
      <div
        :id="`confirmform-${formNum}`"
        style="visibility: hidden; display: none"
      >
        <RichTextRenderer
          v-if="formConfirmationRichText"
          :document="formConfirmationRichText"
          :node-renderers="nodeRenderers"
        />
        <p
          v-else-if="customConfirm"
          :data-form-id="formNum"
          v-html="customConfirm"
        ></p>
        <p v-else class="font-bold" :data-form-id="formNum">
          Thank you for registering! Look for a confirmation email and your
          unique join link.
        </p>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      formNum: {
        type: [String, Number],
        required: true,
      },
      formType: {
        type: String,
        required: false,
        default: '',
      },
      formDate: {
        type: String,
        required: false,
        default: '',
      },
      gated: {
        type: Boolean,
        required: false,
        default: false,
      },
      customConfirm: {
        type: String,
        required: false,
        default: null,
      },
      preventRedirect: {
        type: Boolean,
        required: false,
        default: false,
      },
      helperText: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['submitted', 'focused'],

    setup() {
      const { analyticsTrack } = useAnalytics();
      const { locale } = useI18n();
      const { fullPath } = useRoute();
      const formConfirmationRichText = inject(
        'webinarForm:confirmationRichText'
      );

      return {
        analyticsTrack,
        formConfirmationRichText,
        fullPath,
        nodeRenderers,
        locale,
      };
    },
    mounted() {
      const PREVENT_REDIRECT = this.preventRedirect;
      const GATED = this.gated;
      const formNumber = this.formNum;
      const mountedFormDate = this.formDate;
      const mountedFormType = this.formType;
      const runAnalyticsTrack = this.analyticsTrack;
      const ShowContent = this.showContent;
      const GateCheck = this.gateCheck;
      const localeValue = this.locale;
      const routeFullPath = this.fullPath;
      try {
        (async (id = formNumber) => {
          const maxAttempts = 100;
          let attempts = 0;
          while (typeof MktoForms2 === 'undefined' && attempts < maxAttempts) {
            attempts += 1;
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
          if (attempts <= maxAttempts) {
            if (process.client) {
              const vm = this;
              MktoForms2.loadForm(
                '//app-sj30.marketo.com',
                '027-TSX-214',
                id,
                function (form) {
                  form.doNotRedirect = PREVENT_REDIRECT;
                  const checkboxLabel =
                    document.querySelectorAll('.mktoCheckboxList');
                  const checkboxLabelArray = [...checkboxLabel];
                  checkboxLabelArray.forEach((div) => {
                    div.closest('.mktoFieldWrap').classList.add('checkbox');
                  });
                  const checkboxParent =
                    document.querySelectorAll('.mktoCheckboxList');
                  const checkboxParentArray = [...checkboxParent];
                  checkboxParentArray.forEach((div) => {
                    div
                      .closest('.mktoFormCol')
                      .classList.add('mkto--full-width');
                  });
                  form.onSubmit(function () {
                    const utm_campaign =
                      document.getElementsByName('utm_campaign__c')[0];
                    const utm_content =
                      document.getElementsByName('utm_content__c')[0];
                    const utm_medium =
                      document.getElementsByName('utm_medium__c')[0];
                    const utm_source =
                      document.getElementsByName('utm_source__c')[0];
                    const utm_term =
                      document.getElementsByName('utm_term__c')[0];
                    const fpv = document.getElementsByName(
                      'First_Page_Visited__c'
                    )[0];
                    if (utm_campaign)
                      utm_campaign.setAttribute(
                        'value',
                        vm.cookieGrab('utm_campaign')
                      );
                    if (utm_content)
                      utm_content.setAttribute(
                        'value',
                        vm.cookieGrab('utm_content')
                      );
                    if (utm_medium)
                      utm_medium.setAttribute(
                        'value',
                        vm.cookieGrab('utm_medium')
                      );
                    if (utm_source)
                      utm_source.setAttribute(
                        'value',
                        vm.cookieGrab('utm_source')
                      );
                    if (utm_term)
                      utm_term.setAttribute('value', vm.cookieGrab('utm_term'));
                    if (fpv) fpv.setAttribute('value', vm.cookieGrab('fpv'));
                    vm.delete_cookie('utm_campaign');
                    vm.delete_cookie('utm_content');
                    vm.delete_cookie('utm_medium');
                    vm.delete_cookie('utm_source');
                    vm.delete_cookie('utm_term');
                  });
                  form.onSuccess(function (values, followUpUrl) {
                    if (GATED && GATED == 'true') {
                      ShowContent();
                    } else {
                      form.getFormElem().hide();

                      if (
                        document.querySelector(`#confirmform-${formNumber}`)
                      ) {
                        document.querySelector(
                          `#confirmform-${formNumber}`
                        ).style.visibility = 'visible';
                        document.querySelector(
                          `#confirmform-${formNumber}`
                        ).style.display = 'block';
                      }
                      vm.$emit('submitted');
                      if (mountedFormType.includes('Webinar')) {
                        vm.analyticsTrack('Form Submitted', {
                          id: formNumber,
                          cookie: vm.cookieGrab('_mkto_trk'),
                          routeName: localeValue,
                          date: mountedFormDate,
                          type: mountedFormType,
                        });
                      } // track contact us form submit
                      else if (formNumber === '3425') {
                        runAnalyticsTrack('Form Submitted', {
                          form_type: 'contact us',
                          form_url: routeFullPath,
                          locale_site: localeValue,
                          field_data: {},
                        });
                        if (
                          sessionStorage.getItem('contact_started') !== null &&
                          sessionStorage.getItem('contact_started') === 'true'
                        ) {
                          sessionStorage.removeItem('contact_started');
                        }
                        if (
                          document.querySelector('[data-id="h3"]') &&
                          document.querySelector('[data-id="p"]')
                        ) {
                          document.querySelector(
                            '[data-id="h3"]'
                          ).style.display = 'none';
                          document.querySelector(
                            '[data-id="p"]'
                          ).style.display = 'none';
                        }
                      }
                      return false;
                    }
                  });
                }
              );
              MktoForms2.whenRendered(function () {
                if (vm.helperText) {
                  const emailField = document.getElementById('Email');
                  if (emailField) {
                    emailField.addEventListener('focus', function () {
                      vm.$emit('focused');
                    });
                  }
                }
              });
              if (GATED && GATED == 'true') {
                GateCheck();
              }
            }
          }
        })();
      } catch (e) {
        console.error(e);
      }
    },
    methods: {
      cookieGrab(name) {
        const match = useCookie(name);
        if (match.value !== undefined) {
          return match.value;
        }
      },
      delete_cookie(name) {
        const match = useCookie(name);
        match.value = null;
      },
      showContent() {
        const embeded = document.getElementById('webinar_container');
        const content = document.getElementById('webinar_content');
        const form = document.getElementById('samsara-form');
        content.style.display = 'none';
        form.style.display = 'none';
        embeded.insertAdjacentHTML('beforeend', '{{embed | safe}}');
        this.gatePrevent();
      },
      gateCheck() {
        const hideGate = localStorage.getItem('gate-hide') || false;
        if (hideGate) {
          this.showContent();
        }
      },
      gatePrevent() {
        localStorage.setItem('gate-hide', true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #marketo-form form.mktoForm {
    width: 100% !important;
    @apply mb-[25px] flex flex-col;
    :deep(.mktoButtonRow) {
      .mktoButtonWrap.mktoRound {
        @apply block h-[48px];
        margin-left: 0 !important;
      }
      .mktoButton {
        @apply py-[25px] text-center text-[13px] font-bold uppercase text-white;
        font-family: Roboto, sans-serif;
        line-height: 1;
        background-color: theme('colors.blue.DEFAULT') !important;
        border-radius: 5px !important;
        display: inline-block !important;
        min-width: 200px;
        border: 1px solid transparent !important;
        padding: 17px 20px 15px !important;
        letter-spacing: 1px;
        text-decoration: none;
        text-shadow: none;
        background-image: none;
        margin-left: 0 !important;
      }
    }

    :deep(.mktoFormRow) {
      @apply flex w-full flex-col;

      .mktoLabel.mktoHasWidth,
      input,
      select,
      .mktoFieldWrap,
      .mktoHtmlText.mktoHasWidth {
        width: 100% !important;
      }

      .mktoLabel {
        @apply pt-0 font-bold;
      }

      label {
        @apply pb-1/2 text-xs;
      }

      label,
      .mktoHtmlText a {
        @apply text-blue-dark;
      }

      .mktoHtmlText a {
        @apply underline;
      }

      .mktoFieldWrap.mktoRequiredField {
        @apply w-full;
      }

      .mktoOffset,
      .mktoLogicalField.mktoCheckboxList.mktoHasWidth {
        width: unset !important;
      }

      .mktoGutter.mktoHasWidth {
        @apply h-0 w-0;
      }

      .mktoField {
        @apply mb-1/2 inline-block h-[42px] max-w-full bg-white p-1/4;

        box-shadow: none;
        width: 100% !important; //overrides marketo standard
        border-radius: 3px;
        line-height: 2rem;
        border: 2px solid #eee;
      }
    }
  }
  :deep(.mktoForm *) {
    @apply p-0;
  }
  #webinar-form #marketo-form form.mktoForm,
  #contact-us-modal #marketo-form form.mktoForm {
    :deep(div.mktoFormRow:nth-child(2)) {
      .mktoFieldDescriptor.mktoFormCol,
      .mktoFieldDescriptor.mktoFormCol {
        @apply w-full md:w-1/2;
      }
    }
    :deep(div.mktoFormRow:nth-child(2)),
    :deep(div.mktoFormRow:nth-child(3)),
    .mktoFieldWrap.mktoRequiredField {
      @apply flex w-full;

      .mktoFieldDescriptor.mktoFormCol {
        @apply flex-col;
      }
    }

    :deep(div.mktoFormRow:nth-child(2)) {
      @apply flex-col md:flex-row;
      div.mktoFieldDescriptor.mktoFormCol:first-child {
        @apply pr-0 md:pr-1;
      }
    }

    :deep(div.mktoFormRow:nth-child(6)),
    :deep(div.mktoFormRow:nth-child(7)) {
      @apply flex-row-reverse items-start justify-end;
    }

    :deep(div.mktoFormRow:nth-child(3)) {
      @apply flex-col;
      .mktoFieldDescriptor.mktoFormCol {
        @apply w-full;
      }
    }

    :deep(.mktoFormRow) {
      .mktoFieldWrap.checkbox {
        input {
          @apply h-0;
        }
      }
    }
  }

  #marketo-form form.mktoForm[data-locale='uk'],
  #marketo-form form.mktoForm[data-locale='fr'],
  #marketo-form form.mktoForm[data-locale='de'],
  #marketo-form form.mktoForm[data-locale='nl'],
  #marketo-form form.mktoForm[data-locale='ca'],
  #marketo-form form.mktoForm[data-locale='fr-ca'] {
    :deep(.checkbox) {
      @apply flex flex-row-reverse items-start;

      .mktoAsterix {
        @apply hidden;
      }

      .asterisk {
        color: #bf0000;
      }
    }
  }

  #contact-us-modal #marketo-form form.mktoForm {
    :deep(div.mktoFormRow:nth-child(5)),
    :deep(div.mktoFormRow:nth-child(3)),
    :deep(div.mktoFormRow:nth-child(4)) {
      .mktoFieldDescriptor.mktoFormCol,
      .mktoFieldDescriptor.mktoFormCol {
        @apply w-full md:w-1/2;
      }
    }
    :deep(div.mktoFormRow:nth-child(4)),
    :deep(div.mktoFormRow:nth-child(5)) {
      @apply flex-col md:flex-row;
      div.mktoFieldDescriptor.mktoFormCol:first-child {
        @apply pr-0 md:pr-1;
      }
    }

    :deep(div.mktoFormRow:nth-child(6)),
    :deep(div.mktoFormRow:nth-child(7)) {
      @apply flex-row-reverse items-start justify-end;
      div.mktoFieldDescriptor.mktoFormCol {
        @apply w-full;
      }
    }

    :deep(div.mktoFormRow:nth-child(3)) {
      @apply flex-col;
      .mktoFieldDescriptor.mktoFormCol {
        @apply w-full;
      }
    }

    :deep(.mktoButtonRow) {
      @apply flex justify-center;
    }

    :deep(.mktoFormRow) {
      .mktoFieldWrap.checkbox {
        .mktoLogicalField {
          @apply mb-0;
          label {
            @apply mb-0;
          }
          input {
            @apply mb-0;
          }
        }
      }
    }
  }
</style>
