<template>
  <div ref="vimeo" :style="maxWidth" class="video">
    <div class="aspect--16x9 relative">
      <iframe
        id="vimeo-video"
        v-lazy-load
        class="absolute block h-full w-full"
        :class="{ shadow: video.dropShadow }"
        :src="`https://player.vimeo.com/video/${video.id}?autoplay=${video.autoplay}&loop=${video.loop}&muted=1&portrait=0&controls=${videoControls}&title=0`"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        :title="video.altText ? video.altText : ''"
      ></iframe>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="video" />
  </div>
</template>
<script setup>
  const props = defineProps({
    video: {
      type: Object,
      required: true,
    },
  });

  const videoControls = ref('0');

  const maxWidth = computed(() => {
    return props.video.maxWidth ? `max-width:${props.video.maxWidth}px;` : '';
  });

  onMounted(() => {
    const matchMedia = window.matchMedia(' (max-width: 736px) ');

    function swapVideo(matchMedia) {
      videoControls.value = matchMedia.matches ? '1' : '0';
    }
    swapVideo(matchMedia);
    matchMedia.addEventListener('change', swapVideo);
  });
</script>
<style lang="scss" scoped>
  .video--frameless {
    padding-bottom: 44.6%;
  }

  .shadow {
    box-shadow: 1px 0 41px 2px rgba(0, 38, 62, 0.06);
  }
</style>
