<template>
  <div v-if="chart.stat">
    <component :is="component" :data="chart.stat" />
  </div>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';
  import { ChartType } from '~/types/enums/chart';

  const props = defineProps({
    chart: {
      type: Object,
      required: true,
    },
  });

  const component = computed(() => {
    if (props.chart.type === ChartType.CIRCLE) {
      return defineAsyncComponent(() => import('../charts/CircleChart.vue'));
    } else if (props.chart.type === ChartType.BAR) {
      return defineAsyncComponent(() => import('../charts/BarChart.vue'));
    } else if (props.chart.type === ChartType.PROGRESS) {
      return defineAsyncComponent(() => import('../charts/ProgressChart.vue'));
    } else if (props.chart.type === ChartType.PROGRESS_CIRCLE) {
      return defineAsyncComponent(
        () => import('../charts/ProgressCircleChart.vue')
      );
    }
  });
</script>
