<template>
  <button
    class="btn flex h-[48px] !min-w-[55px] !max-w-[55px] cursor-pointer items-center justify-center rounded-l-none rounded-r-md"
    :class="
      props.query ? 'border-blue bg-blue' : 'border-gray-light bg-gray-light'
    "
  >
    <IconsSvgMagnifyingIcon :fill-color="'#ffffff'" />
  </button>
</template>

<script setup>
  const props = defineProps({
    query: {
      type: String,
    },
  });
</script>
