import { datadogRum } from '@datadog/browser-rum';

const initDatadog = ({
  ddApplicationId,
  ddClientToken,
  ddVersion,
  ddEnv,
  sessionSampleRate,
}) => {
  datadogRum.init({
    applicationId: ddApplicationId,
    clientToken: ddClientToken,
    defaultPrivacyLevel: 'mask-user-input',
    env: ddEnv,
    enableExperimentalFeatures: ['feature_flags'],
    service: 'samsara.com',
    sessionReplaySampleRate: 0,
    sessionSampleRate: sessionSampleRate || 1,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: ddVersion,
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        event.error.message.includes(
          'Hydration completed but contains mismatches.'
        )
      ) {
        return false;
      }

      // allow other events to be sent
      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
};

export default defineNuxtPlugin((nuxtApp) => {
  const { ddApplicationId, ddClientToken, ddVersion, ddEnv, runningTests } =
    useRuntimeConfig().public;
  const route = useRoute();

  let sessionSampleRate = 1; // sets the initial session sample rate to 1%

  if (route.path.includes('thankyou') || route.path.includes('thank-you')) {
    sessionSampleRate = 10; // sets the session sample rate to 10% for the thank you page (arbitrary) to get more data
  }

  nuxtApp.hook('app:mounted', () => {
    if (!runningTests) {
      initDatadog({
        ddApplicationId,
        ddClientToken,
        ddVersion,
        ddEnv,
        sessionSampleRate,
      });
    }
  });
});
