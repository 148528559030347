<template>
  <div
    v-if="!loading && !close && content && content.hide != 'true'"
    class="ams--banner fixed bottom-[60px] left-0 z-50 flex w-full items-center overflow-hidden bg-blue py-1/4 pl-1 pr-3 md:bottom-0 md:justify-center md:px-3 md:py-0 md:text-center"
  >
    <div>
      <p
        v-if="content.text"
        class="mb-0 inline text-white"
        v-html="content.text"
      ></p>
      <p class="mb-0 inline text-white">
        <LinksLocaleLink
          v-if="content.link !== undefined && content.linkText"
          class="ml-1/2 border-b-1 border-white font-bold"
          :link="content.link"
          >{{ content.linkText }}</LinksLocaleLink
        >
      </p>
    </div>
    <svg
      class="absolute right-0 mr-1 cursor-pointer hover:opacity-50"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="closeBanner"
    >
      <rect
        x="17.501"
        y="5.47986"
        width="1.5"
        height="17"
        transform="rotate(45 17.501 5.47986)"
        fill="white"
      />
      <rect
        x="18.5615"
        y="17.5007"
        width="1.5"
        height="17"
        transform="rotate(135 18.5615 17.5007)"
        fill="white"
      />
    </svg>
  </div>
</template>
<script>
  import { localeMappings } from '~/constants/locales';

  export default {
    async setup() {
      const { isSandbox, isStaging } = useRuntimeConfig().public;
      const { locale } = useI18n();
      const footerCookie = useCookie('announceFooterClosed', {
        maxAge: 30 * 24 * 60 * 60 * 1000,
      });

      const { data, pending } = await useAsyncGql({
        operation: 'AnnouncementBannerFooter',
        variables: {
          locale: localeMappings[locale.value],
          preview: isSandbox || isStaging,
        },
      });

      return {
        announceFooterClosed: footerCookie,
        content: data.value?.corpwebDb?.jsonContent ?? '',
        loading: pending,
        close: false,
      };
    },
    mounted() {
      if (this.announceFooterClosed && this.announceFooterClosed === true) {
        this.close = true;
      }
    },
    methods: {
      closeBanner() {
        this.close = !this.close;
        this.announceFooterClosed = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ams--banner {
    height: auto;
    p {
      font-size: 14px;

      :deep(b) {
        @apply tracking-wide;
      }
    }
    svg {
      @media (max-width: theme('screens.md')) {
        margin-top: 4px;
      }
    }

    @screen md {
      height: 66px;
      animation: slideIn 0.8s ease-in-out forwards;
    }

    :deep() {
      a {
        @apply border-b-1 border-white;

        &:hover {
          color: rgba(#fff, 0.5);
          border-bottom: 1px solid rgba(#fff, 0.5);
        }
      }
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(66px);
    }
    100% {
      transform: translateY(0px);
    }
  }
</style>
