<template>
  <div class="yt-embed-container">
    <div class="yt-embed-inner aspect--16x9 relative">
      <Transition>
        <AnimationsLoadingCircles
          v-show="loading"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        />
      </Transition>
      <iframe
        v-show="youtubePlayerState === 'play' && !loading"
        id="youtubeiframe"
        ref="ytVideo"
        v-lazy-load
        :title="title"
        :src="'https://www.youtube.com/embed/' + video.id"
        class="youtubeIframe absolute h-full w-full"
        allow="autoplay; fullscreen"
      ></iframe>
      <slot></slot>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="video" />
    <p v-if="video.style === 'Blog' && video.footnote" class="mt-1 text-xs">
      {{ video.footnote }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      video: {
        type: Object,
        required: true,
      },
      youtubePlayerState: {
        type: String,
        default: 'unstarted',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: 'Youtube Video',
      },
    },
    setup() {
      const loading = ref(true);
      return { loading };
    },
    data() {
      return {
        interval: null,
        youtubeVideoEl: null,
      };
    },
    watch: {
      youtubePlayerState(newValue, oldValue) {
        if (newValue === 'play') {
          this.checkYtVideo();
        }
      },
    },
    mounted() {
      if (this.youtubePlayerState === 'play') {
        this.checkYtVideo();
      }
    },
    methods: {
      checkYtVideo() {
        if (this.interval === null) {
          this.interval = setInterval(this.playYtVideo, 300);
        }
      },
      clearIntervalPoll() {
        clearInterval(this.interval);
        this.interval = null;
        this.youtubeVideoEl = null;
      },
      playYtVideo() {
        if (!this.youtubeVideoEl) {
          this.youtubeVideoEl = document.querySelector('#youtubeiframe');
        }
        if (this.youtubeVideoEl) {
          this.loading = false;
          const src = this.youtubeVideoEl.src + '?autoplay=1';
          this.youtubeVideoEl.setAttribute('src', src);
          this.clearIntervalPoll();
        } else {
          this.clearIntervalPoll();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .image--cover-image {
    &:hover {
      .svg--play-icon {
        transform-origin: center;
        transition: transform 300ms ease;
        transform: scale(1.1);
      }
    }
    &::after {
      content: '';
      @apply absolute bottom-0 left-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
  }
</style>
