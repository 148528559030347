<template>
  <NuxtImg
    v-if="props.queryParams === '' && props.url"
    :src="props.url"
    :style="props.styles"
    :class="computedClasses"
    :alt="props.alt"
    :preload="!!props.preload"
    :sizes="height ? null : 'xl:100vw lg:100vw md:100vw sm:100vw xs:100vw'"
    :width="width !== '' ? width : props.naturalWidth ? props.naturalWidth : ''"
    :height="
      height !== '' ? height : props.naturalHeight ? props.naturalHeight : ''
    "
    :modifiers="{ fit: `${props.modifier}` }"
    :provider="props.provider"
    :loading="nuxtImgLoadingType"
    :format="format"
    @load="$emit('update:imageLoaded')"
  />
  <img
    v-else-if="props.url"
    :src="props.url + props.queryParams"
    :style="props.styles"
    :class="imageClasses"
    :alt="props.alt"
    :loading="props.lazy ? 'lazy' : 'eager'"
  />
</template>

<script setup>
  const { isSandbox, isStaging } = useRuntimeConfig().public;

  const props = defineProps({
    image: {
      type: Object,
      required: false,
      default: null,
    },
    preload: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    isInline: {
      type: Boolean,
      required: false,
      default: false,
    },
    queryParams: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    styles: {
      type: String,
      required: false,
      default: null,
    },
    url: {
      type: String,
      required: true,
    },
    modifier: {
      type: String,
      required: false,
      default: '',
    },
    provider: {
      type: String,
      required: false,
      default: 'contentful',
    },
    naturalWidth: {
      type: String,
      required: false,
      default: null,
    },
    naturalHeight: {
      type: String,
      required: false,
      default: null,
    },
    contentType: {
      type: String,
      required: false,
      default: undefined,
    },
  });

  const imgContentTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.png',
    '.jpg',
    '.jpeg',
  ];

  if (!props.url || props.url === '') {
    if (isSandbox || isStaging) {
      console.warn(
        `NuxtImg needs an image url to render, double check that an image has been added to your content`
      );
    }
  }

  const format = computed(() =>
    imgContentTypes.includes(props.contentType) ||
    imgContentTypes.some((type) => props.url.includes(type))
      ? 'webp'
      : undefined
  );

  const rowStyle = inject('modularRow:rowStyle', undefined);

  // Computed properties for dynamic data
  const nuxtImgLoadingType = computed(() => {
    if (rowStyle === 'Hero Top') {
      return 'eager';
    }
    return props.lazy ? 'lazy' : 'eager';
  });

  const computedClasses = computed(() => {
    let computedClasses = '';
    if (props.classes) {
      computedClasses = props.classes;
    } else if (props.isInline) {
      computedClasses = 'inline-block align-middle';
    } else {
      computedClasses = 'inline-block align-bottom';
    }
    return computedClasses;
  });

  const width = computed(() =>
    props.image?.width !== undefined && props.image?.width !== null
      ? props.image.width
      : ''
  );

  const height = computed(() =>
    props.image?.height !== undefined && props.image?.height !== null
      ? props.image.height
      : ''
  );

  const processAlt = (altValue) =>
    altValue === null || altValue === undefined ? '' : alt;

  const processUrl = (urlString) =>
    urlString.replace('//images.ctfassets.net/', '');

  watch(
    () => props.url,
    (newVal) => processUrl(newVal)
  );

  watch(
    () => props.alt,
    (newVal) => processAlt(newVal)
  );
</script>
