<template>
  <div
    class="banner--promotion fixed top-0 w-full bg-blue-dark px-gap transition-all delay-100"
    :class="classes"
  >
    <div class="flex h-full items-center justify-center text-center">
      <p class="mb-0 block text-xs text-white md:hidden">
        <template v-if="props.content.text.length >= 40">
          <span
            @click="
              showMore = !showMore;
              $emitter.emit('showMore', showMore);
            "
            >{{ truncatedText.displayText }}</span
          ><span v-if="!showMore">{{ truncatedText.suffix }}</span>
          <span v-if="showMore">{{ truncatedText.hiddenText }}</span>
        </template>
        <template v-else>
          <span>{{ props.content.text }}</span>
        </template>
        <LinksArrowLink
          v-if="content.link"
          :arrow-link="{
            type: 'White Small Animated',
            linkUrl: content.link,
            text: content.linkText,
          }"
        />
      </p>
      <p class="mb-0 hidden text-xs text-white md:block">
        {{ content.text }}
        <LinksArrowLink
          v-if="content.link"
          :arrow-link="{
            type: 'White Small Animated',
            linkUrl: content.link,
            text: content.linkText,
          }"
        />
      </p>
    </div>
  </div>
</template>
<script setup>
  import { NavigationDirection, ResourceTypes } from '~/types/enums';

  const { $emitter } = useNuxtApp();

  const props = defineProps({
    content: {
      type: Object,
      required: true,
    },
    scrollDirection: {
      type: String,
      required: false,
      default: null,
    },
  });

  const truncatedText = truncateFilterWithShowMore(
    props.content.text,
    40,
    '...'
  ); // truncate to 40 characters
  const showMore = ref(false);

  const classes = computed(() => {
    return {
      'translate-y-[-66px] md:translate-y-[-34px]':
        props.scrollDirection === NavigationDirection.DOWN,
      'translate-y-[0px] md:translate-y-[0px]':
        props.scrollDirection === NavigationDirection.UP ||
        props.scrollDirection === NavigationDirection.UNSET ||
        props.scrollDirection === NavigationDirection.TOP,
      'h-[66px]': showMore.value,
      'h-[34px]': !showMore.value,
    };
  });
</script>
<style lang="scss" scoped>
  .banner--promotion {
    :deep(.a--link-arrow-animated--white) {
      @apply pl-1/4 text-xs hover:text-white;
    }
  }
</style>
