import { report } from '~/utils/telemetry';
import robots from '~/constants/bots-list';

interface TrackFunnelData {
  formType: string;
  pageName: string;
  formUrl: string;
  isMobile: boolean;
  localeSite: string;
  stepNumber: number | 0;
  stepName: string | null;
  stepText: string;
  flow: string;
  maxSteps: number;
  assetType: string | null;
  size: string | null;
  industry: string | null;
  feature: string | null;
}

export function useAnalytics() {
  const route = useRoute();
  const { isProduction } = useRuntimeConfig().public;
  const { isMobile } = useDevice();

  function analyticsAlias(identifier, properties = {}, silent = false) {
    const analytics = (window.analytics = window.analytics || []);
    try {
      analytics.alias(identifier, properties);
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsIdentify(identifier, properties = {}, silent = false) {
    const analytics = (window.analytics = window.analytics || []);

    try {
      const userAgent = window.navigator.userAgent || '';
      const isRobot = robots.some((robot) =>
        userAgent.toLowerCase().includes(robot)
      );

      if (!isRobot) {
        analytics.identify(identifier, properties);
      }
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsTrack(eventName, customProperties = {}, silent = false) {
    if (!window.analytics) {
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    const userAgent = window.navigator.userAgent || '';
    try {
      const baseProperties = {
        is_mobile: isMobile,
        user_agent_mp: userAgent,
        app: 'horizon',
        session_id: sessionStorage.getItem('_session_id') || '',
      };
      const properties = { ...baseProperties, ...customProperties };

      analytics.track(eventName, properties);
    } catch (error) {
      if (isProduction && !silent) {
        if (process.client) {
          report(error);
        }
      }
    }
  }

  function analyticsTrackLink(
    element,
    eventName,
    customProperties = {},
    silent = false
  ) {
    if (!window.analytics) {
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    try {
      const baseProperties = {
        is_mobile: isMobile,
      };
      const properties = { ...baseProperties, ...customProperties };

      analytics.trackLink(element, eventName, properties);
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsTrackRetry(
    eventName,
    customProperties = {},
    maxAttempts = 10,
    silent = false
  ) {
    try {
      // Wait for analytics to be defined before tracking first step to avoid race
      // condition. This code does not block the main thread. The attempt logic is
      // to prevent this code from running perpetually if analytics will never be
      // defined like when someone has an adblocker.
      (async () => {
        let attempts = 0;
        while (
          typeof window.analytics === 'undefined' &&
          attempts < maxAttempts
        ) {
          attempts += 1;
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        if (attempts <= maxAttempts) {
          analyticsTrack(eventName, customProperties);
        }
      })();
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function trackFleetAssessmentPricingClick() {
    const properties = {
      page_name: document.title,
      url: route.path,
    };
    analyticsTrack('FA Pricing Button Clicked', properties);
  }

  function trackFleetAssessmentStep(step, max) {
    const properties = {
      page_name: document.title,
      url: route.path,
      current_step: step,
      max_step: max,
    };
    analyticsTrack('FA Step Viewed', properties);
  }

  function trackFunnelStep({
    formType,
    formUrl,
    localeSite,
    isMobile,
    stepNumber,
    stepName,
    stepText,
    maxSteps,
    flow,
    pageName,
    size,
    assetType,
    industry,
    feature,
  }: TrackFunnelData) {
    const properties = {
      form_type: formType,
      'page name': pageName,
      form_url: formUrl,
      is_mobile: isMobile,
      locale_site: localeSite,
      step_number: stepNumber,
      step_name: stepName,
      step_text: stepText,
      flow,
      session_id: sessionStorage.getItem('_session_id') || '',
      'max steps': maxSteps,
      field_data: {
        asset_type: assetType,
        size,
        industry,
        feature,
      },
    };

    analyticsTrack('Form Step Completed', properties);
  }

  function trackFleetAssessmentSubmit() {
    const properties = {
      page_name: document.title,
      url: route.path,
    };
    analyticsTrack('FA Submitted', properties);
  }

  return {
    analyticsAlias,
    analyticsIdentify,
    analyticsTrack,
    analyticsTrackLink,
    analyticsTrackRetry,
    trackFleetAssessmentPricingClick,
    trackFleetAssessmentStep,
    trackFleetAssessmentSubmit,
    trackFunnelStep,
  };
}
