<template>
  <div class="chart__container -mx-3 -mt-12 flex justify-center md:p-1">
    <div v-if="chartData.length" class="chart flex max-w-lg flex-col md:w-full">
      <div
        v-for="(item, index) in chartData"
        :key="index"
        class="chart__item flex w-full flex-col items-start pb-8"
      >
        <div class="pb-space text-left text-base">
          <span class="chart__item-label text-2xl font-medium"
            >{{
              locale === 'en-us' ? item.percentage : item.statisticLabel
            }} </span
          ><span class="pl-1/2">{{ item.label }}</span>
        </div>
        <div
          class="chart__item-bar-container relative h-8 w-full min-w-[300px] overflow-hidden rounded-full bg-blue-dark"
        >
          <div
            class="chart__item-inner-bar absolute h-full rounded-br-lg rounded-tr-lg bg-blue"
            :style="{ '--bar-width': item.percentage + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
  });

  const chartData = computed(() =>
    props.data.map((stat) => ({
      label: stat.fields?.statisticName || '',
      percentage: stat.fields?.statisticPercentage || 0,
      statisticLabel: stat.fields?.statisticLabel || 0,
    }))
  );
</script>

<style scoped lang="scss">
  .chart__item-inner-bar {
    animation: growBar 1s ease-in-out forwards;
  }

  @keyframes growBar {
    from {
      width: 0;
    }
    to {
      width: var(--bar-width);
    }
  }
</style>
