<template>
  <FormKit
    ref="textarea"
    type="textarea"
    :label="props.label"
    :name="props.name"
    label-class="label-asterick font-bold mb-[5px]"
    :input-class="`w-full focus:outline-0 rounded-md p-[5px] ${validState === ValidationState.VALID ? '!outline-green' : validState === ValidationState.INVALID ? '!outline-red' : ''}`"
    message-class="text-red mt-[4px] text-xs block"
    messages-class="!ml-0"
    :validation-messages="validationMessages"
    :validation="[validation]"
  >
    <template #suffix="{ value, state }">
      <IconsGreenCheckmark
        v-if="state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px]"
      />
      <FormsProcessingCheck
        v-else-if="state.validating && state.blurred"
        class="absolute right-[10px] top-[10px]"
      />
      <FormsInvalidCheck
        v-else-if="!state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px] h-auto w-auto"
      />
    </template>
  </FormKit>
</template>

<script setup>
  import { useGetValidationState } from '~/composables/formkit-valid-state';
  import { ValidationState } from '~/types/enums/validation-state';

  const props = defineProps({
    errorMessages: {
      type: String,
      required: false,
      default: 'Message is required',
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  });

  const textarea = ref(null);
  const validState = useGetValidationState(textarea);
  const validationMessages = computed(() => {
    let messages = '';
    if (props.required) {
      messages = {
        required:
          props.errorMessages && !props.errorMessages.required
            ? props.errorMessages
            : (props.errorMessages.required ?? 'Message is required.'),
      };
    }
    return messages;
  });
  const validation = props.required ? ['required'] : '';
</script>

<style scss>
  .label-asterick::before {
    content: '*';
    color: theme('colors.red.DEFAULT');
    padding-right: 5px;
  }
</style>
