export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"hid":"twitter_site","name":"twitter:site","content":"@samsarahq"},{"hid":"twitter_card","name":"twitter:card","content":"summary_large_image"},{"hid":"facebook_site_id","property":"fb:app_id","content":"292661627791716"}],"link":[{"rel":"icon","type":"image/x-icon","href":"https://d9ylfbta65t3x.cloudfront.net/img/brand/favicon.ico"},{"rel":"preconnect","href":"https://www.google.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://snap.licdn.com"},{"rel":"preconnect","href":"https://app.launchdarkly.com"},{"rel":"preconnect","href":"https://cdn.segment.com"}],"style":[],"script":[{"async":true,"type":"text/javascript","src":"https://cdn.segment.com/analytics.js/v1/pb66eQfxzrw09qgJ7dkxPYnz6OjzXcsm/analytics.min.js"},{"src":"https://www.google.com/recaptcha/api.js?render=6LfR1ZYpAAAAAMqK1_ILYvKlWGCLKjF_P1b4u9Ux","async":true,"defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"Samsara"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false