
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as api_45legacyN3Urm41CwQMeta } from "/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsiVPgcbiWM2Meta } from "/codebuild/output/src3556124522/src/horizon/pages/awards.vue?macro=true";
import { default as programshlan8zuLVVMeta } from "/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93qb79DLrA1TMeta } from "/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93086CuNOEB3Meta } from "/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexnuQ3JVmUwVMeta } from "/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondGuhTHJKvp4Meta } from "/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93NUn6Uef6ZNMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93qUhYv8gxX6Meta } from "/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutFQvJaknjicMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/about.vue?macro=true";
import { default as indexM2cokGHvZMMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productRvlPCR56r9Meta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexyo3tyg2UTNMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as sales0DEVEKB5PFMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivewQ3FNLCsXqMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexQz91K1QqtFMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexwKLA6f6J5IMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93eJxclgIMOhMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexBxVpkMO3vWMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversity0fsOQh8rjxMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexiFWxWsTXcfMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyD8wO1ND1PBMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetyg2hI3ezKs0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainability3ZqTuZYGReMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45news0BA0yfCNPtMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexIBLAVUCbaAMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesu4nMvwsum0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyHG7GhPLXwRMeta } from "/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolWCrnaNt5hFMeta } from "/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93PLwEYk8FcsMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as indexVnDYj2EXXTMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93VMiaCRTf9jMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93RtLPP8GO5bMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexuM05XdLc3NMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91slug_93W7qqp3q5qgMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue?macro=true";
import { default as above_45beyond8WIjiI6kD4Meta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceYIUvZwY3zDMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceI8inSTOoXdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexrOiPVNZfAdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetIuVSTwgctoMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceqqsT5wudnzMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performanceUhCUGnEze3Meta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorTE5eA0FGXPMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as index5lSpS3TlXiMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetNdYF61NantMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetBuWB9GOMmmMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexv2MnXbtylEMeta } from "/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_931xDN5QbGzqMeta } from "/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93xhQOgi69IHMeta } from "/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue?macro=true";
import { default as _91slug_93DS69F92nUUMeta } from "/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexcjagNsagJCMeta } from "/codebuild/output/src3556124522/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45applKdCBpCSyLMeta } from "/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93DYSC6O6CVhMeta } from "/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93wqkbqs7VBoMeta } from "/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93eNexgrQ326Meta } from "/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_936f3l5RSjr3Meta } from "/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93thujbCeoVyMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentjc2jA6TU8UMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsdDvjfvhgcDMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as index30VCDl7osGMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsQjImTk2nUzMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsWMzAZjxj6XMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexmUd2u4eTDAMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programRdxmfAMQAiMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiGePMUy9YrWMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as index6e1uqSappIMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futureYSWYHukUS2Meta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamssHOFw3iesaMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexgLD2Wcuaj4Meta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataVq47ChisNhMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93k9Avzdf9OcMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexvMWJes3waEMeta } from "/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexZOpYxtwscFMeta } from "/codebuild/output/src3556124522/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_930XAm72oIyjMeta } from "/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93gowd69UyrYMeta } from "/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexZMtzR8SHanMeta } from "/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesQhCQi3UT5LMeta } from "/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexxEjNNJ3ZKGMeta } from "/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexO6PUYSVZgdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue?macro=true";
import { default as _91slug_93RKO4jHxCn5Meta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue?macro=true";
import { default as candidate_45privacy_45noticeTnDwlNIsr3Meta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentNK7iaBy2qBMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policy7rk9pEHxX0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumuVwjbzZEfSMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsTOGpb0gDAGMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyB3fLxhNK0vMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slaTeQ4PSi5ZvMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conduct2TiEztAL36Meta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceYjx6RnCK0lMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyIIaCrsyQ1bMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45servicevaLOMXDVFbMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataeHglxNwqHqMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityeCRTTJqSRgMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportxUPdSxDmB0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceEpwNMeXFmMMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choiceseO4fl333JlMeta } from "/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as operatesmartert9pQK5Oz79Meta } from "/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue?macro=true";
import { default as pricingNbVfpcNwVJMeta } from "/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93pFBIXoxpn9Meta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ZwCzD8vMPeMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingaqlwUylWGDMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsClkRrNKHP1Meta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionWNtCBVYijYMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementcCpA9HqBzKMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideDQ6cmLijytMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingbQsfM9t3rqMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as _91_46_46_46slug_93Y86RTaztT1Meta } from "/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue?macro=true";
import { default as pricingDaMzwAzZHpMeta } from "/codebuild/output/src3556124522/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93NddWui1NI5Meta } from "/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93vsR7m3pFsCMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingYut7Bq3VzlMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingHoK2WoWvHFMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringVypPsGOf3HMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersTN9ak5p8UNMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingKjesW8PzVlMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as index1rGYQBX0K0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93Wb9dBKoT3KMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as index9hfpmE1GV3Meta } from "/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingZxkF51o5J2Meta } from "/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexNEn5zg2eLzMeta } from "/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingRzwuRpoolOMeta } from "/codebuild/output/src3556124522/src/horizon/pages/racing.vue?macro=true";
import { default as referralsLmTqcjwchGMeta } from "/codebuild/output/src3556124522/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93HBoUAJKGzSMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93oEsttQzHFdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsNnobCDJbmYMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93QnhmcqUZZdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centeroAuntzCvalMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribepASqBKVXi4Meta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_93qBOoKTph9IMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93VFoLbyC6zdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexzye9mCD3zlMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as index1p6H2z3bdMMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93jgmRRoKs8EMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93mxfONVJhzvMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as index8xLh99Y2rBMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as tourdvBLBrvuGSMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videos2ibMaGRDGdMeta } from "/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexv5i0FISzeLMeta } from "/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesFKqb7HShYhMeta } from "/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as searchUFHvpB0G9vMeta } from "/codebuild/output/src3556124522/src/horizon/pages/search.vue?macro=true";
import { default as security_45summitkHFjmcK8QHMeta } from "/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93H3qMT5VVFsMeta } from "/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93wa1hZQvvp0Meta } from "/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as index9o6T8j8RbkMeta } from "/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityhFIgT7JPVfMeta } from "/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spaceYGvZCR5HNyMeta } from "/codebuild/output/src3556124522/src/horizon/pages/space.vue?macro=true";
import { default as sparkVLf7gfi6qxMeta } from "/codebuild/output/src3556124522/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93QjM7oyp2MRMeta } from "/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93d3tExlLDkJMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93QzZEdkO14aMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyBMH1PRkiS6Meta } from "/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqfjjmCNnHteMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishYmtx0uciZRMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingWkYtRwmDRSMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as index6Ta7sGcHs8Meta } from "/codebuild/output/src3556124522/src/horizon/pages/support/index.vue?macro=true";
import { default as industrial1LT4msyGDEMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementI8CVskzAXNMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policy2ejIAJpgMmMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexzPFkLpMzrMMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45features4BKjvqPbE5Meta } from "/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listCMH3VhvtuQMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesdBmX04HgOLMeta } from "/codebuild/output/src3556124522/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageRE0jQ5hIQtMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as _91slug_93Cmz57hsb5WMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93xhRIGka89lMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue?macro=true";
import { default as thankyou_45recruitingw3SzMDUUFPMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralHF9iDik2CTMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouOAz9wQwMhYMeta } from "/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteP3YY0J3zJEMeta } from "/codebuild/output/src3556124522/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as pluszKk8d1MLyVMeta } from "/codebuild/output/src3556124522/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierQb8nmmlFSKMeta } from "/codebuild/output/src3556124522/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterI6MNX1I4XYMeta } from "/codebuild/output/src3556124522/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processKtVtacT1IPMeta } from "/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue?macro=true";
import { default as support2XdN64tLs4Meta } from "/codebuild/output/src3556124522/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93j48VNKVo2EMeta } from "/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as index985I09pW3XMeta } from "/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_931ffmRUVGdPMeta } from "/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
import { default as component_45stub8Pe5Rj8ys2Meta } from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub8Pe5Rj8ys2 } from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "api-legacy___en-US",
    path: "/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___ca",
    path: "/ca/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___nl",
    path: "/nl/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___uk",
    path: "/uk/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___de",
    path: "/de/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___mx",
    path: "/mx/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___fr",
    path: "/fr/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "api-legacy___fr-ca",
    path: "/fr-ca/api-legacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/api-legacy.vue")
  },
  {
    name: "awards___en-US",
    path: "/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___ca",
    path: "/ca/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___nl",
    path: "/nl/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___uk",
    path: "/uk/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___de",
    path: "/de/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___mx",
    path: "/mx/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___fr",
    path: "/fr/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "awards___fr-ca",
    path: "/fr-ca/awards",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/awards.vue")
  },
  {
    name: "benefits-programs___en-US",
    path: "/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___ca",
    path: "/ca/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___nl",
    path: "/nl/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___uk",
    path: "/uk/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___de",
    path: "/de/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___mx",
    path: "/mx/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___fr",
    path: "/fr/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "benefits-programs___fr-ca",
    path: "/fr-ca/benefits/programs",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/benefits/programs.vue")
  },
  {
    name: "blog-slug___en-US",
    path: "/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___ca",
    path: "/ca/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___mx",
    path: "/mx/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___uk",
    path: "/uk/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/[slug].vue")
  },
  {
    name: "blog-category-slug___en-US",
    path: "/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___ca",
    path: "/ca/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___nl",
    path: "/nl/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___uk",
    path: "/uk/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___de",
    path: "/de/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___mx",
    path: "/mx/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___fr",
    path: "/fr/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog-category-slug___fr-ca",
    path: "/fr-ca/blog/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/category/[slug].vue")
  },
  {
    name: "blog___en-US",
    path: "/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "blog___ca",
    path: "/ca/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "blog___mx",
    path: "/mx/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "blog___uk",
    path: "/uk/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/blog/index.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___en-US",
    path: "/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___ca",
    path: "/ca/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___nl",
    path: "/nl/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___uk",
    path: "/uk/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___de",
    path: "/de/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___mx",
    path: "/mx/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___fr",
    path: "/fr/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "call-for-speakers-at-samsara-beyond___fr-ca",
    path: "/fr-ca/call-for-speakers-at-samsara-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue")
  },
  {
    name: "company-prefix-slug___en-US",
    path: "/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___ca",
    path: "/ca/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___nl",
    path: "/nl/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___uk",
    path: "/uk/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___de",
    path: "/de/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___mx",
    path: "/mx/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___fr",
    path: "/fr/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-prefix-slug___fr-ca",
    path: "/fr-ca/company/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[prefix]/[slug].vue")
  },
  {
    name: "company-slug___en-US",
    path: "/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___ca",
    path: "/ca/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___nl",
    path: "/nl/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___uk",
    path: "/uk/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___de",
    path: "/de/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___mx",
    path: "/mx/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___fr",
    path: "/fr/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-slug___fr-ca",
    path: "/fr-ca/company/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/[slug].vue")
  },
  {
    name: "company-about___en-US",
    path: "/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___ca",
    path: "/ca/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___nl",
    path: "/nl/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___uk",
    path: "/uk/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___de",
    path: "/de/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___mx",
    path: "/mx/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___fr",
    path: "/fr/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-about___fr-ca",
    path: "/fr-ca/company/about",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/about.vue")
  },
  {
    name: "company-careers-benefits___en-US",
    path: "/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___ca",
    path: "/ca/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___nl",
    path: "/nl/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___uk",
    path: "/uk/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___de",
    path: "/de/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___mx",
    path: "/mx/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___fr",
    path: "/fr/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-benefits___fr-ca",
    path: "/fr-ca/company/careers/benefits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/benefits/index.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___en-US",
    path: "/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___ca",
    path: "/ca/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___nl",
    path: "/nl/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___uk",
    path: "/uk/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___de",
    path: "/de/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___mx",
    path: "/mx/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___fr",
    path: "/fr/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments-engineering-and-product___fr-ca",
    path: "/fr-ca/company/careers/departments/engineering-and-product",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/engineering-and-product.vue")
  },
  {
    name: "company-careers-departments___en-US",
    path: "/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___ca",
    path: "/ca/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___nl",
    path: "/nl/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___uk",
    path: "/uk/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___de",
    path: "/de/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___mx",
    path: "/mx/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___fr",
    path: "/fr/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments___fr-ca",
    path: "/fr-ca/company/careers/departments",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/index.vue")
  },
  {
    name: "company-careers-departments-sales___en-US",
    path: "/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___ca",
    path: "/ca/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___nl",
    path: "/nl/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___uk",
    path: "/uk/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___de",
    path: "/de/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___mx",
    path: "/mx/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___fr",
    path: "/fr/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-departments-sales___fr-ca",
    path: "/fr-ca/company/careers/departments/sales",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/departments/sales.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___en-US",
    path: "/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___ca",
    path: "/ca/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___nl",
    path: "/nl/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___uk",
    path: "/uk/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___de",
    path: "/de/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___mx",
    path: "/mx/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___fr",
    path: "/fr/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers-emerging-talent-archive___fr-ca",
    path: "/fr-ca/company/careers/emerging-talent-archive",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/emerging-talent-archive.vue")
  },
  {
    name: "company-careers___en-US",
    path: "/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___ca",
    path: "/ca/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___nl",
    path: "/nl/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___uk",
    path: "/uk/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___de",
    path: "/de/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___mx",
    path: "/mx/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___fr",
    path: "/fr/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers___fr-ca",
    path: "/fr-ca/company/careers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/index.vue")
  },
  {
    name: "company-careers-locations___en-US",
    path: "/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___ca",
    path: "/ca/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___nl",
    path: "/nl/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___uk",
    path: "/uk/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___de",
    path: "/de/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___mx",
    path: "/mx/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___fr",
    path: "/fr/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-locations___fr-ca",
    path: "/fr-ca/company/careers/locations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/locations/index.vue")
  },
  {
    name: "company-careers-roles-slug___en-US",
    path: "/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___ca",
    path: "/ca/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___nl",
    path: "/nl/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___uk",
    path: "/uk/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___de",
    path: "/de/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___mx",
    path: "/mx/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___fr",
    path: "/fr/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles-slug___fr-ca",
    path: "/fr-ca/company/careers/roles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/[slug].vue")
  },
  {
    name: "company-careers-roles___en-US",
    path: "/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___ca",
    path: "/ca/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___nl",
    path: "/nl/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___uk",
    path: "/uk/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___de",
    path: "/de/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___mx",
    path: "/mx/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___fr",
    path: "/fr/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-careers-roles___fr-ca",
    path: "/fr-ca/company/careers/roles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/careers/roles/index.vue")
  },
  {
    name: "company-diversity___en-US",
    path: "/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___ca",
    path: "/ca/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___nl",
    path: "/nl/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___uk",
    path: "/uk/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___de",
    path: "/de/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___mx",
    path: "/mx/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___fr",
    path: "/fr/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company-diversity___fr-ca",
    path: "/fr-ca/company/diversity",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/diversity.vue")
  },
  {
    name: "company___en-US",
    path: "/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___ca",
    path: "/ca/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___nl",
    path: "/nl/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___uk",
    path: "/uk/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___de",
    path: "/de/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___mx",
    path: "/mx/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___fr",
    path: "/fr/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company___fr-ca",
    path: "/fr-ca/company",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/index.vue")
  },
  {
    name: "company-mission-efficiency___en-US",
    path: "/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___ca",
    path: "/ca/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___nl",
    path: "/nl/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___uk",
    path: "/uk/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___de",
    path: "/de/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___mx",
    path: "/mx/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___fr",
    path: "/fr/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-efficiency___fr-ca",
    path: "/fr-ca/company/mission/efficiency",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/efficiency.vue")
  },
  {
    name: "company-mission-safety___en-US",
    path: "/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___ca",
    path: "/ca/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___nl",
    path: "/nl/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___uk",
    path: "/uk/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___de",
    path: "/de/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___mx",
    path: "/mx/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___fr",
    path: "/fr/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-safety___fr-ca",
    path: "/fr-ca/company/mission/safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/safety.vue")
  },
  {
    name: "company-mission-sustainability___en-US",
    path: "/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___ca",
    path: "/ca/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___nl",
    path: "/nl/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___uk",
    path: "/uk/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___de",
    path: "/de/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___mx",
    path: "/mx/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___fr",
    path: "/fr/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-mission-sustainability___fr-ca",
    path: "/fr-ca/company/mission/sustainability",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/mission/sustainability.vue")
  },
  {
    name: "company-news-in-the-news___en-US",
    path: "/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___ca",
    path: "/ca/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___nl",
    path: "/nl/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___uk",
    path: "/uk/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___de",
    path: "/de/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___mx",
    path: "/mx/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___fr",
    path: "/fr/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news-in-the-news___fr-ca",
    path: "/fr-ca/company/news/in-the-news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/in-the-news.vue")
  },
  {
    name: "company-news___en-US",
    path: "/company/news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/index.vue")
  },
  {
    name: "company-news___ca",
    path: "/ca/company/news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/index.vue")
  },
  {
    name: "company-news___mx",
    path: "/mx/company/news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/index.vue")
  },
  {
    name: "company-news___uk",
    path: "/uk/company/news",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/index.vue")
  },
  {
    name: "company-news-press-releases___en-US",
    path: "/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___ca",
    path: "/ca/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___nl",
    path: "/nl/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___uk",
    path: "/uk/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___de",
    path: "/de/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___mx",
    path: "/mx/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___fr",
    path: "/fr/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-news-press-releases___fr-ca",
    path: "/fr-ca/company/news/press-releases",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/news/press-releases.vue")
  },
  {
    name: "company-privacy___en-US",
    path: "/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___ca",
    path: "/ca/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___nl",
    path: "/nl/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___uk",
    path: "/uk/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___de",
    path: "/de/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___mx",
    path: "/mx/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___fr",
    path: "/fr/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "company-privacy___fr-ca",
    path: "/fr-ca/company/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/company/privacy.vue")
  },
  {
    name: "cost-assessment-tool___en-US",
    path: "/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___ca",
    path: "/ca/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___nl",
    path: "/nl/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___uk",
    path: "/uk/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___de",
    path: "/de/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___mx",
    path: "/mx/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___fr",
    path: "/fr/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "cost-assessment-tool___fr-ca",
    path: "/fr-ca/cost-assessment-tool",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/cost-assessment-tool.vue")
  },
  {
    name: "customers-slug___en-US",
    path: "/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___ca",
    path: "/ca/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___nl",
    path: "/nl/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___uk",
    path: "/uk/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___de",
    path: "/de/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___mx",
    path: "/mx/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___fr",
    path: "/fr/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers-slug___fr-ca",
    path: "/fr-ca/customers/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/[slug].vue")
  },
  {
    name: "customers___en-US",
    path: "/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___ca",
    path: "/ca/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___nl",
    path: "/nl/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___uk",
    path: "/uk/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___de",
    path: "/de/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___mx",
    path: "/mx/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___fr",
    path: "/fr/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers___fr-ca",
    path: "/fr-ca/customers",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/index.vue")
  },
  {
    name: "customers-tips-slug___en-US",
    path: "/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___ca",
    path: "/ca/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___nl",
    path: "/nl/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___uk",
    path: "/uk/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___de",
    path: "/de/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___mx",
    path: "/mx/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___fr",
    path: "/fr/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-slug___fr-ca",
    path: "/fr-ca/customers/tips/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___en-US",
    path: "/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___ca",
    path: "/ca/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___nl",
    path: "/nl/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___uk",
    path: "/uk/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___de",
    path: "/de/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___mx",
    path: "/mx/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___fr",
    path: "/fr/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips-category-slug___fr-ca",
    path: "/fr-ca/customers/tips/category/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/category/[slug].vue")
  },
  {
    name: "customers-tips___en-US",
    path: "/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___ca",
    path: "/ca/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___nl",
    path: "/nl/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___uk",
    path: "/uk/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___de",
    path: "/de/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___mx",
    path: "/mx/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___fr",
    path: "/fr/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-tips___fr-ca",
    path: "/fr-ca/customers/tips",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/tips/index.vue")
  },
  {
    name: "customers-topfleets-prefix-slug___en-US",
    path: "/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___ca",
    path: "/ca/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___nl",
    path: "/nl/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___uk",
    path: "/uk/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___de",
    path: "/de/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___mx",
    path: "/mx/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___fr",
    path: "/fr/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-prefix-slug___fr-ca",
    path: "/fr-ca/customers/topfleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/[prefix]/[slug].vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___en-US",
    path: "/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___ca",
    path: "/ca/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___nl",
    path: "/nl/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___uk",
    path: "/uk/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___de",
    path: "/de/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___mx",
    path: "/mx/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___fr",
    path: "/fr/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-above-beyond___fr-ca",
    path: "/fr-ca/customers/topfleets/2019/above-beyond",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/above-beyond.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___en-US",
    path: "/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___ca",
    path: "/ca/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___nl",
    path: "/nl/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___uk",
    path: "/uk/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___de",
    path: "/de/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___mx",
    path: "/mx/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___fr",
    path: "/fr/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-performance___fr-ca",
    path: "/fr-ca/customers/topfleets/2019/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___en-US",
    path: "/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___ca",
    path: "/ca/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___nl",
    path: "/nl/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___uk",
    path: "/uk/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___de",
    path: "/de/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___mx",
    path: "/mx/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___fr",
    path: "/fr/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019-excellence-service___fr-ca",
    path: "/fr-ca/customers/topfleets/2019/excellence-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/excellence-service.vue")
  },
  {
    name: "customers-topfleets-2019___en-US",
    path: "/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___ca",
    path: "/ca/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___nl",
    path: "/nl/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___uk",
    path: "/uk/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___de",
    path: "/de/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___mx",
    path: "/mx/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___fr",
    path: "/fr/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019___fr-ca",
    path: "/fr-ca/customers/topfleets/2019",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/index.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___en-US",
    path: "/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___ca",
    path: "/ca/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___nl",
    path: "/nl/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___uk",
    path: "/uk/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___de",
    path: "/de/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___mx",
    path: "/mx/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___fr",
    path: "/fr/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2019-safest-fleet___fr-ca",
    path: "/fr-ca/customers/topfleets/2019/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___en-US",
    path: "/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___ca",
    path: "/ca/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___nl",
    path: "/nl/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___uk",
    path: "/uk/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___de",
    path: "/de/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___mx",
    path: "/mx/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___fr",
    path: "/fr/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-driver-performance___fr-ca",
    path: "/fr-ca/customers/topfleets/2020/driver-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/driver-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___en-US",
    path: "/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___ca",
    path: "/ca/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___nl",
    path: "/nl/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___uk",
    path: "/uk/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___de",
    path: "/de/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___mx",
    path: "/mx/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___fr",
    path: "/fr/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-excellence-performance___fr-ca",
    path: "/fr-ca/customers/topfleets/2020/excellence-performance",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___en-US",
    path: "/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___ca",
    path: "/ca/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___nl",
    path: "/nl/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___uk",
    path: "/uk/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___de",
    path: "/de/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___mx",
    path: "/mx/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___fr",
    path: "/fr/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: "/fr-ca/customers/topfleets/2020/fleet-innovator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue")
  },
  {
    name: "customers-topfleets-2020___en-US",
    path: "/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___ca",
    path: "/ca/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___nl",
    path: "/nl/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___uk",
    path: "/uk/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___de",
    path: "/de/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___mx",
    path: "/mx/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___fr",
    path: "/fr/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020___fr-ca",
    path: "/fr-ca/customers/topfleets/2020",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/index.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___en-US",
    path: "/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___ca",
    path: "/ca/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___nl",
    path: "/nl/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___uk",
    path: "/uk/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___de",
    path: "/de/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___mx",
    path: "/mx/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___fr",
    path: "/fr/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-public-fleet___fr-ca",
    path: "/fr-ca/customers/topfleets/2020/public-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/public-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-safest-fleet___en-US",
    path: "/customers/topfleets/2020/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue")
  },
  {
    name: "customers-topfleets-2020-safest-fleet___ca",
    path: "/ca/customers/topfleets/2020/safest-fleet",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue")
  },
  {
    name: "customers-topfleets___en-US",
    path: "/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___ca",
    path: "/ca/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___nl",
    path: "/nl/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___uk",
    path: "/uk/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___de",
    path: "/de/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___mx",
    path: "/mx/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___fr",
    path: "/fr/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "customers-topfleets___fr-ca",
    path: "/fr-ca/customers/topfleets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/customers/topfleets/index.vue")
  },
  {
    name: "demos-prefix-slug___en-US",
    path: "/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___ca",
    path: "/ca/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___nl",
    path: "/nl/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___uk",
    path: "/uk/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___de",
    path: "/de/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___mx",
    path: "/mx/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___fr",
    path: "/fr/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-prefix-slug___fr-ca",
    path: "/fr-ca/demos/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[prefix]/[slug].vue")
  },
  {
    name: "demos-slug___en-US",
    path: "/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___ca",
    path: "/ca/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___nl",
    path: "/nl/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___uk",
    path: "/uk/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___de",
    path: "/de/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___mx",
    path: "/mx/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___fr",
    path: "/fr/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___fr-ca",
    path: "/fr-ca/demos/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/demos/[slug].vue")
  },
  {
    name: "events-slug___en-US",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___ca",
    path: "/ca/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___nl",
    path: "/nl/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___uk",
    path: "/uk/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___de",
    path: "/de/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___mx",
    path: "/mx/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___fr",
    path: "/fr/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events-slug___fr-ca",
    path: "/fr-ca/events/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/[slug].vue")
  },
  {
    name: "events___en-US",
    path: "/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___ca",
    path: "/ca/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___uk",
    path: "/uk/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___mx",
    path: "/mx/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___fr",
    path: "/fr/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "events___fr-ca",
    path: "/fr-ca/events",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/events/index.vue")
  },
  {
    name: "fleet-app___en-US",
    path: "/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___ca",
    path: "/ca/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___nl",
    path: "/nl/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___uk",
    path: "/uk/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___de",
    path: "/de/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___mx",
    path: "/mx/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___fr",
    path: "/fr/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-app___fr-ca",
    path: "/fr-ca/fleet-app",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet-app.vue")
  },
  {
    name: "fleet-prefix-slug___en-US",
    path: "/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___ca",
    path: "/ca/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___nl",
    path: "/nl/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___uk",
    path: "/uk/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___de",
    path: "/de/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___mx",
    path: "/mx/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___fr",
    path: "/fr/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-prefix-slug___fr-ca",
    path: "/fr-ca/fleet/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[prefix]/[slug].vue")
  },
  {
    name: "fleet-slug___en-US",
    path: "/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___ca",
    path: "/ca/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___nl",
    path: "/nl/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___uk",
    path: "/uk/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___de",
    path: "/de/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___mx",
    path: "/mx/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___fr",
    path: "/fr/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleet-slug___fr-ca",
    path: "/fr-ca/fleet/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleet/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___en-US",
    path: "/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___ca",
    path: "/ca/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___nl",
    path: "/nl/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___uk",
    path: "/uk/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___de",
    path: "/de/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___mx",
    path: "/mx/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___fr",
    path: "/fr/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-prefix-slug___fr-ca",
    path: "/fr-ca/fleets/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[prefix]/[slug].vue")
  },
  {
    name: "fleets-slug___en-US",
    path: "/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___ca",
    path: "/ca/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___nl",
    path: "/nl/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___uk",
    path: "/uk/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___de",
    path: "/de/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___mx",
    path: "/mx/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___fr",
    path: "/fr/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "fleets-slug___fr-ca",
    path: "/fr-ca/fleets/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/fleets/[slug].vue")
  },
  {
    name: "guides-slug___en-US",
    path: "/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___ca",
    path: "/ca/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___nl",
    path: "/nl/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___uk",
    path: "/uk/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___de",
    path: "/de/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___mx",
    path: "/mx/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___fr",
    path: "/fr/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-slug___fr-ca",
    path: "/fr-ca/guides/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/[slug].vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___en-US",
    path: "/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___ca",
    path: "/ca/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___nl",
    path: "/nl/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___uk",
    path: "/uk/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___de",
    path: "/de/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___mx",
    path: "/mx/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___fr",
    path: "/fr/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue")
  },
  {
    name: "guides-build-business-resilience___en-US",
    path: "/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___ca",
    path: "/ca/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___nl",
    path: "/nl/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___uk",
    path: "/uk/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___de",
    path: "/de/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___mx",
    path: "/mx/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___fr",
    path: "/fr/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience___fr-ca",
    path: "/fr-ca/guides/build-business-resilience",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/index.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: "/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___ca",
    path: "/ca/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___nl",
    path: "/nl/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___uk",
    path: "/uk/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___de",
    path: "/de/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___mx",
    path: "/mx/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___fr",
    path: "/fr/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___en-US",
    path: "/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___ca",
    path: "/ca/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___nl",
    path: "/nl/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___uk",
    path: "/uk/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___de",
    path: "/de/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___mx",
    path: "/mx/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___fr",
    path: "/fr/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety-best-dash-cams___fr-ca",
    path: "/fr-ca/guides/fleet-safety/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue")
  },
  {
    name: "guides-fleet-safety___en-US",
    path: "/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___ca",
    path: "/ca/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___nl",
    path: "/nl/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___uk",
    path: "/uk/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___de",
    path: "/de/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___mx",
    path: "/mx/guias/seguridad-flotas",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___fr",
    path: "/fr/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety___fr-ca",
    path: "/fr-ca/guides/fleet-safety",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/index.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___en-US",
    path: "/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___ca",
    path: "/ca/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___nl",
    path: "/nl/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___uk",
    path: "/uk/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___de",
    path: "/de/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___mx",
    path: "/mx/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___fr",
    path: "/fr/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-program___fr-ca",
    path: "/fr-ca/guides/fleet-safety/safety-program",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-program.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___en-US",
    path: "/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___ca",
    path: "/ca/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___nl",
    path: "/nl/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___uk",
    path: "/uk/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___de",
    path: "/de/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___mx",
    path: "/mx/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___fr",
    path: "/fr/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides-fleet-safety-safety-roi___fr-ca",
    path: "/fr-ca/guides/fleet-safety/safety-roi",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/fleet-safety/safety-roi.vue")
  },
  {
    name: "guides___en-US",
    path: "/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___ca",
    path: "/ca/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___nl",
    path: "/nl/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___uk",
    path: "/uk/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___de",
    path: "/de/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___mx",
    path: "/mx/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___fr",
    path: "/fr/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides___fr-ca",
    path: "/fr-ca/guides",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/index.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___en-US",
    path: "/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___ca",
    path: "/ca/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___nl",
    path: "/nl/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___uk",
    path: "/uk/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___de",
    path: "/de/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___mx",
    path: "/mx/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___fr",
    path: "/fr/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-build-for-the-future___fr-ca",
    path: "/fr-ca/guides/make-more-possible/build-for-the-future",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___en-US",
    path: "/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___ca",
    path: "/ca/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___nl",
    path: "/nl/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___uk",
    path: "/uk/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___de",
    path: "/de/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___mx",
    path: "/mx/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___fr",
    path: "/fr/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible-empower-your-teams___fr-ca",
    path: "/fr-ca/guides/make-more-possible/empower-your-teams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue")
  },
  {
    name: "guides-make-more-possible___en-US",
    path: "/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___ca",
    path: "/ca/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___nl",
    path: "/nl/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___uk",
    path: "/uk/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___de",
    path: "/de/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___mx",
    path: "/mx/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___fr",
    path: "/fr/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible___fr-ca",
    path: "/fr-ca/guides/make-more-possible",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/index.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___en-US",
    path: "/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___ca",
    path: "/ca/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___nl",
    path: "/nl/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___uk",
    path: "/uk/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___de",
    path: "/de/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___mx",
    path: "/mx/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___fr",
    path: "/fr/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-make-more-possible-take-action-with-data___fr-ca",
    path: "/fr-ca/guides/make-more-possible/take-action-with-data",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: "/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___ca",
    path: "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___nl",
    path: "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___uk",
    path: "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___de",
    path: "/de/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___mx",
    path: "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___fr",
    path: "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___en-US",
    path: "/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___ca",
    path: "/ca/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___nl",
    path: "/nl/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___uk",
    path: "/uk/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___de",
    path: "/de/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___mx",
    path: "/mx/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___fr",
    path: "/fr/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "guides-your-partner-for-the-road-ahead___fr-ca",
    path: "/fr-ca/guides/your-partner-for-the-road-ahead",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue")
  },
  {
    name: "index___en-US",
    path: "/",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___ca",
    path: "/ca",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___uk",
    path: "/uk",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___mx",
    path: "/mx",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "index___fr-ca",
    path: "/fr-ca",
    meta: indexZOpYxtwscFMeta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/index.vue")
  },
  {
    name: "industrial-slug___en-US",
    path: "/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___ca",
    path: "/ca/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___nl",
    path: "/nl/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___uk",
    path: "/uk/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___de",
    path: "/de/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___mx",
    path: "/mx/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___fr",
    path: "/fr/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industrial-slug___fr-ca",
    path: "/fr-ca/industrial/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industrial/[slug].vue")
  },
  {
    name: "industries-slug___en-US",
    path: "/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___ca",
    path: "/ca/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___nl",
    path: "/nl/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___uk",
    path: "/uk/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___de",
    path: "/de/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___mx",
    path: "/mx/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___fr",
    path: "/fr/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries-slug___fr-ca",
    path: "/fr-ca/industries/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/[slug].vue")
  },
  {
    name: "industries___en-US",
    path: "/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___ca",
    path: "/ca/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___nl",
    path: "/nl/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___uk",
    path: "/uk/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___de",
    path: "/de/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___mx",
    path: "/mx/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___fr",
    path: "/fr/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "industries___fr-ca",
    path: "/fr-ca/industries",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/industries/index.vue")
  },
  {
    name: "insights-cities___en-US",
    path: "/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___ca",
    path: "/ca/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___nl",
    path: "/nl/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___uk",
    path: "/uk/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___de",
    path: "/de/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___mx",
    path: "/mx/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___fr",
    path: "/fr/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "insights-cities___fr-ca",
    path: "/fr-ca/insights/cities",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/insights/cities.vue")
  },
  {
    name: "integrations___en-US",
    path: "/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___ca",
    path: "/ca/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___nl",
    path: "/nl/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___uk",
    path: "/uk/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___de",
    path: "/de/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___mx",
    path: "/mx/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___fr",
    path: "/fr/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "integrations___fr-ca",
    path: "/fr-ca/integrations",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/integrations/index.vue")
  },
  {
    name: "invite___en-US",
    path: "/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___ca",
    path: "/ca/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___nl",
    path: "/nl/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___uk",
    path: "/uk/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___de",
    path: "/de/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___mx",
    path: "/mx/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___fr",
    path: "/fr/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "invite___fr-ca",
    path: "/fr-ca/invite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/invite/index.vue")
  },
  {
    name: "legal-slug___en-US",
    path: "/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___ca",
    path: "/ca/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___nl",
    path: "/nl/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___uk",
    path: "/uk/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___de",
    path: "/de/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___mx",
    path: "/mx/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___fr",
    path: "/fr/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___fr-ca",
    path: "/fr-ca/legal/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/[slug].vue")
  },
  {
    name: "legal-candidate-privacy-notice___en-US",
    path: "/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___ca",
    path: "/ca/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___nl",
    path: "/nl/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___uk",
    path: "/uk/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___de",
    path: "/de/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___mx",
    path: "/mx/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___fr",
    path: "/fr/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-candidate-privacy-notice___fr-ca",
    path: "/fr-ca/legal/candidate-privacy-notice",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/candidate-privacy-notice.vue")
  },
  {
    name: "legal-cookie-consent___en-US",
    path: "/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___ca",
    path: "/ca/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___nl",
    path: "/nl/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___uk",
    path: "/uk/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___de",
    path: "/de/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___mx",
    path: "/mx/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___fr",
    path: "/fr/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-consent___fr-ca",
    path: "/fr-ca/legal/cookie-consent",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-consent.vue")
  },
  {
    name: "legal-cookie-policy___en-US",
    path: "/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___ca",
    path: "/ca/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___nl",
    path: "/nl/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___uk",
    path: "/uk/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___de",
    path: "/de/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___mx",
    path: "/mx/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___fr",
    path: "/fr/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-cookie-policy___fr-ca",
    path: "/fr-ca/legal/cookie-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/cookie-policy.vue")
  },
  {
    name: "legal-data-protection-addendum___en-US",
    path: "/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___ca",
    path: "/ca/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___nl",
    path: "/nl/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___uk",
    path: "/uk/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___de",
    path: "/de/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___mx",
    path: "/mx/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___fr",
    path: "/fr/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-data-protection-addendum___fr-ca",
    path: "/fr-ca/legal/data-protection-addendum",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/data-protection-addendum.vue")
  },
  {
    name: "legal-express-order-terms___en-US",
    path: "/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___ca",
    path: "/ca/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___nl",
    path: "/nl/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___uk",
    path: "/uk/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___de",
    path: "/de/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___mx",
    path: "/mx/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___fr",
    path: "/fr/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-express-order-terms___fr-ca",
    path: "/fr-ca/legal/express-order-terms",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/express-order-terms.vue")
  },
  {
    name: "legal-hardware-warranty___en-US",
    path: "/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___ca",
    path: "/ca/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___nl",
    path: "/nl/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___uk",
    path: "/uk/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___de",
    path: "/de/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___mx",
    path: "/mx/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___fr",
    path: "/fr/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hardware-warranty___fr-ca",
    path: "/fr-ca/legal/hardware-warranty",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hardware-warranty.vue")
  },
  {
    name: "legal-hosted-software-sla___en-US",
    path: "/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___ca",
    path: "/ca/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___nl",
    path: "/nl/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___uk",
    path: "/uk/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___de",
    path: "/de/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___mx",
    path: "/mx/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___fr",
    path: "/fr/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-hosted-software-sla___fr-ca",
    path: "/fr-ca/legal/hosted-software-sla",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/hosted-software-sla.vue")
  },
  {
    name: "legal-partner-code-of-conduct___en-US",
    path: "/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___ca",
    path: "/ca/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___nl",
    path: "/nl/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___uk",
    path: "/uk/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___de",
    path: "/de/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___mx",
    path: "/mx/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___fr",
    path: "/fr/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-partner-code-of-conduct___fr-ca",
    path: "/fr-ca/legal/partner-code-of-conduct",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/partner-code-of-conduct.vue")
  },
  {
    name: "legal-platform-terms-of-service___en-US",
    path: "/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___ca",
    path: "/ca/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___nl",
    path: "/nl/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___uk",
    path: "/uk/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___de",
    path: "/de/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___mx",
    path: "/mx/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___fr",
    path: "/fr/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-platform-terms-of-service___fr-ca",
    path: "/fr-ca/legal/platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/platform-terms-of-service.vue")
  },
  {
    name: "legal-privacy___en-US",
    path: "/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___ca",
    path: "/ca/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___nl",
    path: "/nl/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___uk",
    path: "/uk/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___de",
    path: "/de/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___mx",
    path: "/mx/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___fr",
    path: "/fr/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-privacy___fr-ca",
    path: "/fr-ca/legal/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/privacy.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: "/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___ca",
    path: "/ca/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___nl",
    path: "/nl/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___uk",
    path: "/uk/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___de",
    path: "/de/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___mx",
    path: "/mx/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___fr",
    path: "/fr/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue")
  },
  {
    name: "legal-requestingcustomerdata___en-US",
    path: "/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___ca",
    path: "/ca/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___nl",
    path: "/nl/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___uk",
    path: "/uk/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___de",
    path: "/de/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___mx",
    path: "/mx/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___fr",
    path: "/fr/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-requestingcustomerdata___fr-ca",
    path: "/fr-ca/legal/requestingcustomerdata",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/requestingcustomerdata.vue")
  },
  {
    name: "legal-security___en-US",
    path: "/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___ca",
    path: "/ca/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___nl",
    path: "/nl/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___uk",
    path: "/uk/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___de",
    path: "/de/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___mx",
    path: "/mx/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___fr",
    path: "/fr/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-security___fr-ca",
    path: "/fr-ca/legal/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/security.vue")
  },
  {
    name: "legal-transparencyreport___en-US",
    path: "/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___ca",
    path: "/ca/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___nl",
    path: "/nl/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___uk",
    path: "/uk/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___de",
    path: "/de/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___mx",
    path: "/mx/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___fr",
    path: "/fr/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-transparencyreport___fr-ca",
    path: "/fr-ca/legal/transparencyreport",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/transparencyreport.vue")
  },
  {
    name: "legal-website-terms-of-service___en-US",
    path: "/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___ca",
    path: "/ca/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___nl",
    path: "/nl/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___uk",
    path: "/uk/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___de",
    path: "/de/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___mx",
    path: "/mx/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___fr",
    path: "/fr/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-website-terms-of-service___fr-ca",
    path: "/fr-ca/legal/website-terms-of-service",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/website-terms-of-service.vue")
  },
  {
    name: "legal-your-privacy-choices___en-US",
    path: "/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___ca",
    path: "/ca/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___nl",
    path: "/nl/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___uk",
    path: "/uk/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___de",
    path: "/de/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___mx",
    path: "/mx/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___fr",
    path: "/fr/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "legal-your-privacy-choices___fr-ca",
    path: "/fr-ca/legal/your-privacy-choices",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/legal/your-privacy-choices.vue")
  },
  {
    name: "operatesmarter___en-US",
    path: "/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___ca",
    path: "/ca/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___nl",
    path: "/nl/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___uk",
    path: "/uk/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___de",
    path: "/de/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___mx",
    path: "/mx/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___fr",
    path: "/fr/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "operatesmarter___fr-ca",
    path: "/fr-ca/operatesmarter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/operatesmarter.vue")
  },
  {
    name: "page-pricing___en-US",
    path: "/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___ca",
    path: "/ca/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___nl",
    path: "/nl/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___uk",
    path: "/uk/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___de",
    path: "/de/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___mx",
    path: "/mx/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___fr",
    path: "/fr/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "page-pricing___fr-ca",
    path: "/fr-ca/page/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/page/pricing.vue")
  },
  {
    name: "pages-prefix-slug___en-US",
    path: "/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___ca",
    path: "/ca/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___nl",
    path: "/nl/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___uk",
    path: "/uk/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___de",
    path: "/de/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___mx",
    path: "/mx/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___fr",
    path: "/fr/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-prefix-slug___fr-ca",
    path: "/fr-ca/pages/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[prefix]/[slug].vue")
  },
  {
    name: "pages-slug___en-US",
    path: "/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___ca",
    path: "/ca/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___nl",
    path: "/nl/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___uk",
    path: "/uk/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___de",
    path: "/de/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___mx",
    path: "/mx/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___fr",
    path: "/fr/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-slug___fr-ca",
    path: "/fr-ca/pages/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/[slug].vue")
  },
  {
    name: "pages-asset-tracking___en-US",
    path: "/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___ca",
    path: "/ca/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___nl",
    path: "/nl/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___uk",
    path: "/uk/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___de",
    path: "/de/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___mx",
    path: "/mx/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___fr",
    path: "/fr/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-asset-tracking___fr-ca",
    path: "/fr-ca/pages/asset-tracking",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/asset-tracking.vue")
  },
  {
    name: "pages-best-dash-cams___en-US",
    path: "/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___ca",
    path: "/ca/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___nl",
    path: "/nl/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___uk",
    path: "/uk/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___de",
    path: "/de/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___mx",
    path: "/mx/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___fr",
    path: "/fr/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-best-dash-cams___fr-ca",
    path: "/fr-ca/pages/best-dash-cams",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/best-dash-cams.vue")
  },
  {
    name: "pages-construction___en-US",
    path: "/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___ca",
    path: "/ca/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___nl",
    path: "/nl/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___uk",
    path: "/uk/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___de",
    path: "/de/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___mx",
    path: "/mx/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___fr",
    path: "/fr/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-construction___fr-ca",
    path: "/fr-ca/pages/construction",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/construction.vue")
  },
  {
    name: "pages-fleet-management___en-US",
    path: "/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___ca",
    path: "/ca/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___nl",
    path: "/nl/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___uk",
    path: "/uk/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___de",
    path: "/de/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___mx",
    path: "/mx/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___fr",
    path: "/fr/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-fleet-management___fr-ca",
    path: "/fr-ca/pages/fleet-management",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/fleet-management.vue")
  },
  {
    name: "pages-nationwide___en-US",
    path: "/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___ca",
    path: "/ca/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___nl",
    path: "/nl/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___uk",
    path: "/uk/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___de",
    path: "/de/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___mx",
    path: "/mx/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___fr",
    path: "/fr/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-nationwide___fr-ca",
    path: "/fr-ca/pages/nationwide",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/nationwide.vue")
  },
  {
    name: "pages-safety-coaching___en-US",
    path: "/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___ca",
    path: "/ca/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___nl",
    path: "/nl/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___uk",
    path: "/uk/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___de",
    path: "/de/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___mx",
    path: "/mx/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___fr",
    path: "/fr/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pages-safety-coaching___fr-ca",
    path: "/fr-ca/pages/safety-coaching",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pages/safety-coaching.vue")
  },
  {
    name: "pdf-track-slug___en-US",
    path: "/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___ca",
    path: "/ca/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___nl",
    path: "/nl/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___uk",
    path: "/uk/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___de",
    path: "/de/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___mx",
    path: "/mx/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___fr",
    path: "/fr/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pdf-track-slug___fr-ca",
    path: "/fr-ca/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93Y86RTaztT1Meta || {},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pdf/track/[...slug].vue")
  },
  {
    name: "pricing___en-US",
    path: "/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___ca",
    path: "/ca/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___nl",
    path: "/nl/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___uk",
    path: "/uk/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___de",
    path: "/de/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___mx",
    path: "/mx/prueba-gratis",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___fr",
    path: "/fr/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "pricing___fr-ca",
    path: "/fr-ca/pricing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/pricing.vue")
  },
  {
    name: "products-prefix-slug___en-US",
    path: "/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___ca",
    path: "/ca/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___nl",
    path: "/nl/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___uk",
    path: "/uk/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___de",
    path: "/de/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___mx",
    path: "/mx/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___fr",
    path: "/fr/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-prefix-slug___fr-ca",
    path: "/fr-ca/products/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[prefix]/[slug].vue")
  },
  {
    name: "products-slug___en-US",
    path: "/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___ca",
    path: "/ca/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___nl",
    path: "/nl/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___uk",
    path: "/uk/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___de",
    path: "/de/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___mx",
    path: "/mx/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___fr",
    path: "/fr/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-slug___fr-ca",
    path: "/fr-ca/products/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/[slug].vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___en-US",
    path: "/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___ca",
    path: "/ca/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___nl",
    path: "/nl/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___uk",
    path: "/uk/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___de",
    path: "/de/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___mx",
    path: "/mx/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___fr",
    path: "/fr/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-asset-tracking___fr-ca",
    path: "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___en-US",
    path: "/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___ca",
    path: "/ca/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___nl",
    path: "/nl/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___uk",
    path: "/uk/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___de",
    path: "/de/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___mx",
    path: "/mx/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___fr",
    path: "/fr/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___en-US",
    path: "/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___ca",
    path: "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___nl",
    path: "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___uk",
    path: "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___de",
    path: "/de/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___mx",
    path: "/mx/productos/rastreo-equipos/refrigerados",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___fr",
    path: "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___en-US",
    path: "/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___ca",
    path: "/ca/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___nl",
    path: "/nl/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___uk",
    path: "/uk/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___de",
    path: "/de/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___mx",
    path: "/mx/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___fr",
    path: "/fr/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-smart-trailers___fr-ca",
    path: "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___en-US",
    path: "/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___ca",
    path: "/ca/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___nl",
    path: "/nl/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___uk",
    path: "/uk/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___de",
    path: "/de/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___mx",
    path: "/mx/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___fr",
    path: "/fr/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue")
  },
  {
    name: "products___en-US",
    path: "/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___ca",
    path: "/ca/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___nl",
    path: "/nl/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___uk",
    path: "/uk/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___de",
    path: "/de/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___mx",
    path: "/mx/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___fr",
    path: "/fr/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products___fr-ca",
    path: "/fr-ca/products",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/index.vue")
  },
  {
    name: "products-models-slug___en-US",
    path: "/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___ca",
    path: "/ca/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___nl",
    path: "/nl/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___uk",
    path: "/uk/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___de",
    path: "/de/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___mx",
    path: "/mx/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___fr",
    path: "/fr/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models-slug___fr-ca",
    path: "/fr-ca/products/models/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/[slug].vue")
  },
  {
    name: "products-models___en-US",
    path: "/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___ca",
    path: "/ca/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___nl",
    path: "/nl/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___uk",
    path: "/uk/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___de",
    path: "/de/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___mx",
    path: "/mx/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___fr",
    path: "/fr/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-models___fr-ca",
    path: "/fr-ca/products/models",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/models/index.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___en-US",
    path: "/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___ca",
    path: "/ca/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___nl",
    path: "/nl/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___uk",
    path: "/uk/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___de",
    path: "/de/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___mx",
    path: "/mx/productos/telematica/rastreo-gps-flota",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___fr",
    path: "/fr/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics-gps-fleet-tracking___fr-ca",
    path: "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/gps-fleet-tracking.vue")
  },
  {
    name: "products-telematics___en-US",
    path: "/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___ca",
    path: "/ca/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___nl",
    path: "/nl/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___uk",
    path: "/uk/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___de",
    path: "/de/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___mx",
    path: "/mx/products/flota",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___fr",
    path: "/fr/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "products-telematics___fr-ca",
    path: "/fr-ca/products/telematics",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/products/telematics/index.vue")
  },
  {
    name: "racing___en-US",
    path: "/racing",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/racing.vue")
  },
  {
    name: "referrals___en-US",
    path: "/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___ca",
    path: "/ca/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___nl",
    path: "/nl/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___uk",
    path: "/uk/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___de",
    path: "/de/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___mx",
    path: "/mx/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___fr",
    path: "/fr/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "referrals___fr-ca",
    path: "/fr-ca/referrals",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/referrals.vue")
  },
  {
    name: "resources-prefix-slug___en-US",
    path: "/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___ca",
    path: "/ca/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___nl",
    path: "/nl/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___uk",
    path: "/uk/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___de",
    path: "/de/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___mx",
    path: "/mx/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___fr",
    path: "/fr/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-prefix-slug___fr-ca",
    path: "/fr-ca/resources/:prefix()/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[prefix]/[slug].vue")
  },
  {
    name: "resources-slug___en-US",
    path: "/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___ca",
    path: "/ca/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___nl",
    path: "/nl/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___uk",
    path: "/uk/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___de",
    path: "/de/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___mx",
    path: "/mx/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___fr",
    path: "/fr/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___fr-ca",
    path: "/fr-ca/resources/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/[slug].vue")
  },
  {
    name: "resources-brand-assets___en-US",
    path: "/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___ca",
    path: "/ca/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___nl",
    path: "/nl/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___uk",
    path: "/uk/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___de",
    path: "/de/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___mx",
    path: "/mx/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___fr",
    path: "/fr/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-brand-assets___fr-ca",
    path: "/fr-ca/resources/brand-assets",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/brand-assets.vue")
  },
  {
    name: "resources-content-slug___en-US",
    path: "/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___ca",
    path: "/ca/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___nl",
    path: "/nl/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___uk",
    path: "/uk/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___de",
    path: "/de/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___mx",
    path: "/mx/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___fr",
    path: "/fr/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-slug___fr-ca",
    path: "/fr-ca/resources/content/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/[slug].vue")
  },
  {
    name: "resources-content-email-preferences-center___en-US",
    path: "/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___ca",
    path: "/ca/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___nl",
    path: "/nl/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___uk",
    path: "/uk/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___de",
    path: "/de/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___mx",
    path: "/mx/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___fr",
    path: "/fr/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-email-preferences-center___fr-ca",
    path: "/fr-ca/resources/content/email-preferences-center",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/email-preferences-center.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___en-US",
    path: "/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___ca",
    path: "/ca/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___nl",
    path: "/nl/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___uk",
    path: "/uk/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___de",
    path: "/de/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___mx",
    path: "/mx/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___fr",
    path: "/fr/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-content-one-click-unsubscribe___fr-ca",
    path: "/fr-ca/resources/content/one-click-unsubscribe",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/content/one-click-unsubscribe.vue")
  },
  {
    name: "resources-experts-slug___en-US",
    path: "/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___ca",
    path: "/ca/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___nl",
    path: "/nl/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___uk",
    path: "/uk/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___de",
    path: "/de/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___mx",
    path: "/mx/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___fr",
    path: "/fr/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-slug___fr-ca",
    path: "/fr-ca/resources/experts/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___en-US",
    path: "/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___ca",
    path: "/ca/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___nl",
    path: "/nl/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___uk",
    path: "/uk/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___de",
    path: "/de/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___mx",
    path: "/mx/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___fr",
    path: "/fr/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts-category-slug___fr-ca",
    path: "/fr-ca/resources/experts/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/category/[slug].vue")
  },
  {
    name: "resources-experts___en-US",
    path: "/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___ca",
    path: "/ca/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___nl",
    path: "/nl/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___uk",
    path: "/uk/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___de",
    path: "/de/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___mx",
    path: "/mx/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___fr",
    path: "/fr/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources-experts___fr-ca",
    path: "/fr-ca/resources/experts",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/experts/index.vue")
  },
  {
    name: "resources___en-US",
    path: "/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___ca",
    path: "/ca/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___nl",
    path: "/nl/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___uk",
    path: "/uk/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___de",
    path: "/de/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___mx",
    path: "/mx/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___fr",
    path: "/fr/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources___fr-ca",
    path: "/fr-ca/resources",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/index.vue")
  },
  {
    name: "resources-marketplace-slug___en-US",
    path: "/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___ca",
    path: "/ca/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___nl",
    path: "/nl/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___uk",
    path: "/uk/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___de",
    path: "/de/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___mx",
    path: "/mx/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___fr",
    path: "/fr/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-slug___fr-ca",
    path: "/fr-ca/resources/marketplace/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___en-US",
    path: "/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___ca",
    path: "/ca/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___nl",
    path: "/nl/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___uk",
    path: "/uk/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___de",
    path: "/de/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___mx",
    path: "/mx/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___fr",
    path: "/fr/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace-category-slug___fr-ca",
    path: "/fr-ca/resources/marketplace/category/:slug()",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/category/[slug].vue")
  },
  {
    name: "resources-marketplace___en-US",
    path: "/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___ca",
    path: "/ca/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___nl",
    path: "/nl/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___uk",
    path: "/uk/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___de",
    path: "/de/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___mx",
    path: "/mx/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___fr",
    path: "/fr/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-marketplace___fr-ca",
    path: "/fr-ca/resources/marketplace",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/marketplace/index.vue")
  },
  {
    name: "resources-tour___en-US",
    path: "/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___ca",
    path: "/ca/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___nl",
    path: "/nl/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___uk",
    path: "/uk/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___de",
    path: "/de/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___mx",
    path: "/mx/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___fr",
    path: "/fr/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-tour___fr-ca",
    path: "/fr-ca/resources/tour",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/tour.vue")
  },
  {
    name: "resources-videos___en-US",
    path: "/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___ca",
    path: "/ca/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___nl",
    path: "/nl/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___uk",
    path: "/uk/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___de",
    path: "/de/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___mx",
    path: "/mx/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___fr",
    path: "/fr/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "resources-videos___fr-ca",
    path: "/fr-ca/resources/videos",
    meta: {"middleware":["6s-redirect"]},
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/resources/videos.vue")
  },
  {
    name: "roi-calculator___en-US",
    path: "/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___ca",
    path: "/ca/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___nl",
    path: "/nl/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___uk",
    path: "/uk/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___de",
    path: "/de/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___mx",
    path: "/mx/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___fr",
    path: "/fr/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "roi-calculator___fr-ca",
    path: "/fr-ca/roi-calculator",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/roi-calculator/index.vue")
  },
  {
    name: "samsara-ventures___en-US",
    path: "/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___ca",
    path: "/ca/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___nl",
    path: "/nl/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___uk",
    path: "/uk/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___de",
    path: "/de/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___mx",
    path: "/mx/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___fr",
    path: "/fr/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "samsara-ventures___fr-ca",
    path: "/fr-ca/samsara-ventures",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/samsara-ventures.vue")
  },
  {
    name: "search___en-US",
    path: "/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___ca",
    path: "/ca/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___nl",
    path: "/nl/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___mx",
    path: "/mx/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "search___fr-ca",
    path: "/fr-ca/search",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/search.vue")
  },
  {
    name: "security-summit___en-US",
    path: "/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___ca",
    path: "/ca/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___nl",
    path: "/nl/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___uk",
    path: "/uk/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___de",
    path: "/de/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___mx",
    path: "/mx/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___fr",
    path: "/fr/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "security-summit___fr-ca",
    path: "/fr-ca/security-summit",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/security-summit.vue")
  },
  {
    name: "solutions-prefix-slug___en-US",
    path: "/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___ca",
    path: "/ca/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___nl",
    path: "/nl/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___uk",
    path: "/uk/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___de",
    path: "/de/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___mx",
    path: "/mx/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___fr",
    path: "/fr/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-prefix-slug___fr-ca",
    path: "/fr-ca/solutions/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[prefix]/[slug].vue")
  },
  {
    name: "solutions-slug___en-US",
    path: "/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___ca",
    path: "/ca/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___nl",
    path: "/nl/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___uk",
    path: "/uk/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___de",
    path: "/de/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___mx",
    path: "/mx/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___fr",
    path: "/fr/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions-slug___fr-ca",
    path: "/fr-ca/solutions/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/[slug].vue")
  },
  {
    name: "solutions___en-US",
    path: "/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___ca",
    path: "/ca/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___nl",
    path: "/nl/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___uk",
    path: "/uk/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___de",
    path: "/de/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___mx",
    path: "/mx/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___fr",
    path: "/fr/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions___fr-ca",
    path: "/fr-ca/solutions",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/index.vue")
  },
  {
    name: "solutions-security___en-US",
    path: "/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___ca",
    path: "/ca/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___nl",
    path: "/nl/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___uk",
    path: "/uk/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___de",
    path: "/de/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___mx",
    path: "/mx/soluciones/seguridad-patrimonial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___fr",
    path: "/fr/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "solutions-security___fr-ca",
    path: "/fr-ca/solutions/security",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/solutions/security.vue")
  },
  {
    name: "space___en-US",
    path: "/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___ca",
    path: "/ca/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___nl",
    path: "/nl/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___uk",
    path: "/uk/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___de",
    path: "/de/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___mx",
    path: "/mx/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___fr",
    path: "/fr/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "space___fr-ca",
    path: "/fr-ca/space",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/space.vue")
  },
  {
    name: "spark___en-US",
    path: "/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___ca",
    path: "/ca/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___nl",
    path: "/nl/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___uk",
    path: "/uk/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___de",
    path: "/de/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___mx",
    path: "/mx/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___fr",
    path: "/fr/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "spark___fr-ca",
    path: "/fr-ca/spark",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/spark.vue")
  },
  {
    name: "styles-slug___en-US",
    path: "/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___ca",
    path: "/ca/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___nl",
    path: "/nl/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___uk",
    path: "/uk/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___de",
    path: "/de/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___mx",
    path: "/mx/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___fr",
    path: "/fr/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "styles-slug___fr-ca",
    path: "/fr-ca/styles/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/styles/[slug].vue")
  },
  {
    name: "support-prefix-slug___en-US",
    path: "/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___ca",
    path: "/ca/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___nl",
    path: "/nl/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___uk",
    path: "/uk/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___de",
    path: "/de/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___mx",
    path: "/mx/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___fr",
    path: "/fr/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-prefix-slug___fr-ca",
    path: "/fr-ca/support/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[prefix]/[slug].vue")
  },
  {
    name: "support-slug___en-US",
    path: "/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___ca",
    path: "/ca/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___nl",
    path: "/nl/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___uk",
    path: "/uk/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___de",
    path: "/de/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___mx",
    path: "/mx/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___fr",
    path: "/fr/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-slug___fr-ca",
    path: "/fr-ca/support/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/[slug].vue")
  },
  {
    name: "support-apps-privacy-policy___en-US",
    path: "/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___ca",
    path: "/ca/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___nl",
    path: "/nl/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___uk",
    path: "/uk/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___de",
    path: "/de/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___mx",
    path: "/mx/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___fr",
    path: "/fr/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-apps-privacy-policy___fr-ca",
    path: "/fr-ca/support/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/apps-privacy-policy.vue")
  },
  {
    name: "support-customer-referrals-faq___en-US",
    path: "/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___ca",
    path: "/ca/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___nl",
    path: "/nl/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___uk",
    path: "/uk/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___de",
    path: "/de/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___mx",
    path: "/mx/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___fr",
    path: "/fr/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-customer-referrals-faq___fr-ca",
    path: "/fr-ca/support/customer-referrals-faq",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/customer-referrals-faq.vue")
  },
  {
    name: "support-driver-training-spanish___en-US",
    path: "/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___ca",
    path: "/ca/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___nl",
    path: "/nl/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___uk",
    path: "/uk/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___de",
    path: "/de/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___mx",
    path: "/mx/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___fr",
    path: "/fr/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training-spanish___fr-ca",
    path: "/fr-ca/support/driver-training-spanish",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training-spanish.vue")
  },
  {
    name: "support-driver-training___en-US",
    path: "/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___ca",
    path: "/ca/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___nl",
    path: "/nl/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___uk",
    path: "/uk/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___de",
    path: "/de/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___mx",
    path: "/mx/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___fr",
    path: "/fr/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support-driver-training___fr-ca",
    path: "/fr-ca/support/driver-training",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/driver-training.vue")
  },
  {
    name: "support___en-US",
    path: "/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___ca",
    path: "/ca/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___nl",
    path: "/nl/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___uk",
    path: "/uk/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___de",
    path: "/de/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___mx",
    path: "/mx/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___fr",
    path: "/fr/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support___fr-ca",
    path: "/fr-ca/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/index.vue")
  },
  {
    name: "support-industrial___en-US",
    path: "/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___ca",
    path: "/ca/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___nl",
    path: "/nl/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___uk",
    path: "/uk/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___de",
    path: "/de/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___mx",
    path: "/mx/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___fr",
    path: "/fr/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-industrial___fr-ca",
    path: "/fr-ca/support/industrial",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/industrial.vue")
  },
  {
    name: "support-modern-slavery-statement___en-US",
    path: "/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___ca",
    path: "/ca/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___nl",
    path: "/nl/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___uk",
    path: "/uk/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___de",
    path: "/de/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___mx",
    path: "/mx/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___fr",
    path: "/fr/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-modern-slavery-statement___fr-ca",
    path: "/fr-ca/support/modern-slavery-statement",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/modern-slavery-statement.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___en-US",
    path: "/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___ca",
    path: "/ca/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___nl",
    path: "/nl/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___uk",
    path: "/uk/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___de",
    path: "/de/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___mx",
    path: "/mx/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___fr",
    path: "/fr/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy-apps-privacy-policy___fr-ca",
    path: "/fr-ca/support/privacy/apps-privacy-policy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/apps-privacy-policy.vue")
  },
  {
    name: "support-privacy___en-US",
    path: "/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___ca",
    path: "/ca/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___nl",
    path: "/nl/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___uk",
    path: "/uk/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___de",
    path: "/de/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___mx",
    path: "/mx/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___fr",
    path: "/fr/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy___fr-ca",
    path: "/fr-ca/support/privacy",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/index.vue")
  },
  {
    name: "support-privacy-special-features___en-US",
    path: "/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___ca",
    path: "/ca/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___nl",
    path: "/nl/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___uk",
    path: "/uk/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___de",
    path: "/de/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___mx",
    path: "/mx/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___fr",
    path: "/fr/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-special-features___fr-ca",
    path: "/fr-ca/support/privacy/special-features",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/special-features.vue")
  },
  {
    name: "support-privacy-subprocessor-list___en-US",
    path: "/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___ca",
    path: "/ca/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___nl",
    path: "/nl/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___uk",
    path: "/uk/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___de",
    path: "/de/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___mx",
    path: "/mx/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___fr",
    path: "/fr/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-privacy-subprocessor-list___fr-ca",
    path: "/fr-ca/support/privacy/subprocessor-list",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/privacy/subprocessor-list.vue")
  },
  {
    name: "support-support-kb-articles___nl",
    path: "/nl/support/support-kb-articles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/support-kb-articles.vue")
  },
  {
    name: "support-support-kb-articles___mx",
    path: "/mx/support/support-kb-articles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/support-kb-articles.vue")
  },
  {
    name: "support-support-kb-articles___fr",
    path: "/fr/support/support-kb-articles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/support-kb-articles.vue")
  },
  {
    name: "support-support-kb-articles___de",
    path: "/de/support/support-kb-articles",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/support/support-kb-articles.vue")
  },
  {
    name: "thank-you-page___en-US",
    path: "/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___ca",
    path: "/ca/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___nl",
    path: "/nl/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___uk",
    path: "/uk/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___de",
    path: "/de/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___mx",
    path: "/mx/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___fr",
    path: "/fr/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-page___fr-ca",
    path: "/fr-ca/thank-you-page",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you-page.vue")
  },
  {
    name: "thank-you-prefix-slug___en-US",
    path: "/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___ca",
    path: "/ca/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___nl",
    path: "/nl/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___uk",
    path: "/uk/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___de",
    path: "/de/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___mx",
    path: "/mx/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___fr",
    path: "/fr/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-prefix-slug___fr-ca",
    path: "/fr-ca/thank-you/:prefix()/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[prefix]/[slug].vue")
  },
  {
    name: "thank-you-slug___en-US",
    path: "/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___ca",
    path: "/ca/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___nl",
    path: "/nl/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___uk",
    path: "/uk/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___de",
    path: "/de/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___mx",
    path: "/mx/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___fr",
    path: "/fr/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thank-you-slug___fr-ca",
    path: "/fr-ca/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thank-you/[slug].vue")
  },
  {
    name: "thankyou-recruiting___en-US",
    path: "/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___ca",
    path: "/ca/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___nl",
    path: "/nl/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___uk",
    path: "/uk/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___de",
    path: "/de/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___mx",
    path: "/mx/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___fr",
    path: "/fr/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-recruiting___fr-ca",
    path: "/fr-ca/thankyou-recruiting",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-recruiting.vue")
  },
  {
    name: "thankyou-referral___en-US",
    path: "/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___ca",
    path: "/ca/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___nl",
    path: "/nl/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___uk",
    path: "/uk/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___de",
    path: "/de/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___mx",
    path: "/mx/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___fr",
    path: "/fr/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou-referral___fr-ca",
    path: "/fr-ca/thankyou-referral",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou-referral.vue")
  },
  {
    name: "thankyou___en-US",
    path: "/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___ca",
    path: "/ca/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___nl",
    path: "/nl/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___uk",
    path: "/uk/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___de",
    path: "/de/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___mx",
    path: "/mx/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___fr",
    path: "/fr/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "thankyou___fr-ca",
    path: "/fr-ca/thankyou",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/thankyou.vue")
  },
  {
    name: "tiers-elite___uk",
    path: "/uk/tiers/elite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/elite.vue")
  },
  {
    name: "tiers-elite___en-US",
    path: "/tiers/elite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/elite.vue")
  },
  {
    name: "tiers-elite___ca",
    path: "/ca/tiers/elite",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/elite.vue")
  },
  {
    name: "tiers-plus___uk",
    path: "/uk/tiers/plus",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/plus.vue")
  },
  {
    name: "tiers-plus___en-US",
    path: "/tiers/plus",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/plus.vue")
  },
  {
    name: "tiers-plus___ca",
    path: "/ca/tiers/plus",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/plus.vue")
  },
  {
    name: "tiers-premier___uk",
    path: "/uk/tiers/premier",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/premier.vue")
  },
  {
    name: "tiers-premier___en-US",
    path: "/tiers/premier",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/premier.vue")
  },
  {
    name: "tiers-premier___ca",
    path: "/ca/tiers/premier",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/premier.vue")
  },
  {
    name: "tiers-starter___uk",
    path: "/uk/tiers/starter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/starter.vue")
  },
  {
    name: "tiers-starter___en-US",
    path: "/tiers/starter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/starter.vue")
  },
  {
    name: "tiers-starter___ca",
    path: "/ca/tiers/starter",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/tiers/starter.vue")
  },
  {
    name: "trial-process___en-US",
    path: "/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___ca",
    path: "/ca/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___nl",
    path: "/nl/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___uk",
    path: "/uk/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___de",
    path: "/de/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___mx",
    path: "/mx/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___fr",
    path: "/fr/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "trial-process___fr-ca",
    path: "/fr-ca/trial/process",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/trial/process.vue")
  },
  {
    name: "warranty-support___uk",
    path: "/uk/warranty/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/warranty/support.vue")
  },
  {
    name: "warranty-support___en-US",
    path: "/warranty/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/warranty/support.vue")
  },
  {
    name: "warranty-support___ca",
    path: "/ca/warranty/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/warranty/support.vue")
  },
  {
    name: "warranty-support___ie",
    path: "/ie/warranty/support",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/warranty/support.vue")
  },
  {
    name: "webinars-slug___en-US",
    path: "/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___ca",
    path: "/ca/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___nl",
    path: "/nl/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___uk",
    path: "/uk/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___de",
    path: "/de/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___mx",
    path: "/mx/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___fr",
    path: "/fr/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___fr-ca",
    path: "/fr-ca/webinars/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/[slug].vue")
  },
  {
    name: "webinars___en-US",
    path: "/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___ca",
    path: "/ca/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___nl",
    path: "/nl/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___uk",
    path: "/uk/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___de",
    path: "/de/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___mx",
    path: "/mx/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___fr",
    path: "/fr/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars___fr-ca",
    path: "/fr-ca/webinars",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/index.vue")
  },
  {
    name: "webinars-thank-you-slug___en-US",
    path: "/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___ca",
    path: "/ca/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___nl",
    path: "/nl/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___uk",
    path: "/uk/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___de",
    path: "/de/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___mx",
    path: "/mx/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___fr",
    path: "/fr/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: "webinars-thank-you-slug___fr-ca",
    path: "/fr-ca/webinars/thank-you/:slug()",
    component: () => import("/codebuild/output/src3556124522/src/horizon/pages/webinars/thank-you/[slug].vue")
  },
  {
    name: component_45stub8Pe5Rj8ys2Meta?.name,
    path: "/sitemap.xml",
    component: component_45stub8Pe5Rj8ys2
  }
]