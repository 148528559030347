<template>
  <div>
    <ul
      v-if="tabsArray.length > 1 || tabsType !== 'SimpleTabs'"
      :class="['tabs__header', headerClasses]"
      role="tablist"
    >
      <li
        v-for="(tab, index) in tabsArray"
        :key="`tab-${index}`"
        :class="[{ tab__selected: index === modelValue }, tabClasses]"
        role="tab"
        tabindex="0"
        @click="handleTabClick(index)"
        @keyup.enter="handleTabClick(index)"
      >
        <div
          v-if="tab.labelMedia?.fields?.file"
          class="relative left-[50%] hidden h-[33px] w-[33px] translate-x-[-50%] md:block"
        >
          <ImagesNuxtImg
            :url="tab.labelMedia.fields.file.url"
            :natural-width="tab.labelMedia.fields.file.details.image.width"
            :natural-height="tab.labelMedia.fields.file.details.image.height"
            :is-inline="false"
            classes="block max-w-[33px] object-contain"
            :alt="tab.labelMedia.fields.description ?? ''"
            :content-type="tab.labelMedia.fields.file.contentType"
          />
        </div>
        <div v-if="tabsType === 'VerticalTabs'">
          <span class="tab__number hidden font-light tracking-normal md:block">
            {{ index + 1 }}
          </span>
          <span class="tab__line mt-1/2 block"></span>
          <h5 v-if="tab.label">{{ tab.label }}</h5>
        </div>
        <span v-else-if="tab.label">{{ tab.label }}</span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script setup>
  const props = defineProps({
    tabClasses: {
      type: String,
      default:
        'text-lg uppercase tracking-wide text-blue-dark font-bold mx-1/2 transition-colors duration-200 ease-in-out mb-1 md:mb-0 text-center',
    },
    headerClasses: {
      type: String,
      default: 'justify-center flex mb-2 md:mb-3 flex-wrap',
    },
    tabsType: {
      type: String,
      required: true,
      default: 'SimpleTabs',
    },
    modelValue: {
      type: Number,
      required: true,
      default: 0,
    },
    deepChildren: {
      type: Boolean,
      default: false,
    },
  });

  const slots = useSlots();
  const emit = defineEmits(['update:model-value']);

  const tabsArray = ref([]);

  onMounted(() => {
    const children = props.deepChildren
      ? slots.default?.()
      : slots.default?.()[0]?.children;

    console.log(children);

    tabsArray.value = children.map((child) => ({
      label: child.props?.label,
      labelMedia: child.props?.['label-media'],
    }));
  });

  const handleTabClick = (index) => {
    emit('update:model-value', index);
  };
</script>

<style lang="scss" scoped>
  ul.tabs__header {
    list-style: none;
  }

  ul.tabs__header > li {
    display: inline-block;
    cursor: pointer;
  }

  .tab {
    display: inline-block;
  }

  .tab__number {
    font-size: 48px;
    line-height: 48px;
  }
</style>
