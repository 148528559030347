<template>
  <section id="index-case-study-carousel" class="pt-3 md:pt-[60px] lg:pt-4">
    <div class="relative z-20 w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap">
        <div class="column w-12/12 md:ml-2/12 md:w-8/12">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
      <div id="index-case-wrapper" class="-mx-gap flex flex-wrap">
        <Swiper
          v-bind="indexCase"
          class="rotator--index-case flex w-full"
          :modules="[SwiperNavigation]"
          @swiper="onIndexCase"
        >
          <SwiperSlide
            v-for="(slide, index) in slides"
            :key="index"
            class="slide--platform-data relative flex h-full flex-col bg-white"
          >
            <LinksLocaleLink :link="slide.fields.link">
              <ImagesPicture
                :image="slide.fields.image.fields"
                classes="w-full block"
                :lazy="true"
              />
              <div class="wrapper__copy">
                <RichTextRenderer
                  :document="slide.fields.content"
                  :node-renderers="nodeRenderers"
                />
              </div>
            </LinksLocaleLink>
          </SwiperSlide>
          <div
            class="next--blue absolute right-0 z-20 cursor-pointer select-none rounded-full"
            @click="next()"
          >
            <svg
              width="93"
              height="93"
              viewBox="0 0 93 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
              <path
                d="M49.7067 30.4653L65.7412 46.4998L49.7067 62.5343"
                stroke="white"
                stroke-width="5"
              />
              <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
            </svg>
          </div>
        </Swiper>
      </div>
      <div class="-mx-gap flex flex-wrap" v-if="modules.json">
        <div class="column w-12/12 px-gap">
          <div
            v-if="modules.json?.link?.href && modules.json?.link?.text"
            class="relative inline-block"
          >
            <LinksArrowLink
              class="uppercase"
              :arrow-link="{
                text: modules.json?.link?.text,
                linkUrl: modules.json?.link?.href,
                type: 'Blue Small Animated',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: {
      modules: {
        type: Object,
        required: true,
        slides: {
          type: Array,
          fields: {
            type: Object,
            isVisible: Boolean,
          },
          required: false,
          default: [],
        },
      },
    },
    setup() {
      const resize = useResize();
      const indexCaseRef = ref(null);

      const onIndexCase = (swiper) => {
        indexCaseRef.value = swiper;
      };
      return {
        resize,
        onIndexCase,
        indexCaseRef,
      };
    },
    data() {
      return {
        indexCase: {
          spaceBetween: 20,
          loop: true,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          loopAddBlankSlides: true,
          slidesPerView: 2.5,
          allowTouchMove: false,
          breakpoints: {
            0: {
              slidesPerView: 1.5,
            },
            736: {
              slidesPerView: 2.5,
            },
            1000: {
              slidesPerView: 3,
            },
          },
        },
      };
    },
    computed: {
      slides() {
        let slides = [];

        if (this.modules.slides) {
          let initSlides = [...this.modules.slides];

          if (initSlides.length > 0) {
            initSlides = initSlides.filter(
              (slide) => slide.fields.isVisible !== false
            );
          }

          if (initSlides.length < 6) {
            const copy = [...initSlides];
            const slide = initSlides.at(-3);
            if (slide) copy.push(slide);
            slides = copy;
          }
        }

        return slides;
      },
      bgImage() {
        return this.resize(
          this.modules.media[0].fields.image.fields.file
            ? this.modules.media[0].fields.image.fields.file.url
            : ''
        );
      },
    },
    mounted() {
      let innerWidth = window.innerWidth;
      const caseWrapper = document.querySelector('#index-case-wrapper');
      const rotaterIndex = document.querySelector('.rotator--index-case');
      let leftDistance = rotaterIndex.getBoundingClientRect().left;
      let setWidth = innerWidth - leftDistance;

      caseWrapper.style.width = setWidth + 'px';
      this.indexCaseRef.update();

      window.onresize = () => {
        innerWidth = window.innerWidth;
        leftDistance = rotaterIndex.getBoundingClientRect().left;
        setWidth = innerWidth - leftDistance;
        caseWrapper.style.width = setWidth + 'px';
      };
    },
    methods: {
      next() {
        if (this.indexCaseRef.isEnd) {
          this.indexCaseRef.slideTo(0);
        } else {
          this.indexCaseRef.slideNext();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #index-case-study-carousel {
    .column {
      :deep(h1),
      :deep(h2),
      :deep(h3),
      :deep(h4),
      :deep(h5),
      :deep(h6),
      :deep(p) {
        text-align: center;
      }
    }

    .rotator--index-case {
      padding-top: 30px;
      padding-bottom: 60px;
      padding-left: 60px;
      margin-left: -60px;
      .slide--platform-data {
        flex: 1 0 auto;
        &.swiper-slide-prev {
          opacity: 0;
        }
      }
      .next--blue {
        width: 93px;
        height: 93px;
        top: 50%;
        margin-right: 55px;
        transform: translate(0, -50%);
        @media (max-width: theme('screens.md')) {
          width: 40px;
          height: 40px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        svg {
          box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.25);
          transition: 0.2s ease-in-out;
          border-radius: 100%;
          width: 72px;
          height: 72px;
          circle {
            transition: 0.2s ease-in-out;
          }
          path {
            transition: 0.2s ease-in-out;
          }
        }
        &:hover {
          svg {
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
            transform: translate(0, -2px);
          }
        }
      }
    }
    a.slide--platform-data {
      text-decoration: none;
    }
    .slide--platform-data {
      opacity: 1;
      box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.15);
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.15);
        z-index: 2;
      }
    }
    .wrapper__copy {
      padding: 20px 25px;
      h3 {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
</style>
