export default defineNuxtPlugin({
  name: 'app-error-handler',
  dependsOn: ['datadog-logs', 'sentry-plugin'],
  setup(nuxtApp) {
    const { $ddLoggerClient } = useNuxtApp();
    const { isProduction } = useRuntimeConfig().public;

    nuxtApp.hook('app:chunkError', ({ error }) => {
      if (isProduction) {
        $ddLoggerClient.logger.error(
          'horizon.page dynamic import error',
          { service: 'horizon', name: 'horizon.error.dynamic-import' },
          error
        );
      }
      reloadNuxtApp();
    });

    // suppress specific console error regarding async hydration mismatches
    const originalConsoleError = console.error;

    console.error = (...args) => {
      const errorMessage = args[0];
      if (
        typeof errorMessage === 'string' &&
        errorMessage.includes('Hydration completed but contains mismatches.')
      ) {
        console.warn(
          '[Custom Warning] Suppressed specific console error:',
          ...args
        );
      } else {
        originalConsoleError(...args);
      }
    };
  },
  env: {
    islands: false,
  },
});
