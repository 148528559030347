<template>
  <FormKit
    type="email"
    ref="email"
    :label="props.label"
    :name="props.name"
    label-class="label-asterick font-bold"
    :input-class="`w-full focus:outline-0 rounded-md p-[5px] ${validState === ValidationState.VALID ? '!outline-green' : validState === ValidationState.INVALID ? '!outline-red' : ''}`"
    wrapper-class="!h-full"
    message-class="text-red mt-[4px] text-xs block"
    messages-class="!ml-0"
    :validation-messages="{
      required:
        props.errorMessages && !props.errorMessages.required
          ? props.errorMessages
          : (props.errorMessages.required ?? 'Email address is required.'),
    }"
    :validation="'required|email|(1000)validateEmail'"
    :validation-rules="rules"
    :value="query.email ?? ''"
  >
    <template #suffix="{ value, state }">
      <IconsGreenCheckmark
        v-if="state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px]"
      />
      <FormsProcessingCheck
        v-else-if="state.validating && state.blurred"
        class="absolute right-[10px] top-[10px]"
      />
      <FormsInvalidCheck
        v-else-if="!state.valid && !state.validating && state.blurred"
        class="absolute right-[15px] top-[15px] h-auto w-auto"
      />
    </template>
  </FormKit>
</template>

<script setup>
  import { useGetValidationState } from '~/composables/formkit-valid-state';
  import { ValidationState } from '~/types/enums/validation-state';

  const props = defineProps({
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
  });

  const email = ref(null);
  const validState = useGetValidationState(email);
  const { isProduction, isStaging } = useRuntimeConfig().public;
  const { locale } = useI18n();
  const { fullPath } = useRoute();
  const { analyticsTrack } = useAnalytics();
  const { $ddLoggerClient } = useNuxtApp();

  const rules = {
    async validateEmail({ value }) {
      const email = value;
      if (isProduction || isStaging) {
        try {
          const response = await $fetch('/api/validate/ipqs', {
            method: 'post',
            body: { input: email, type: 'email' },
          });

          if (response.ok === true) {
            return true;
          } else if (
            response.ok === false &&
            response.data?.message?.includes('exceeded your request quota')
          ) {
            if (isProduction) {
              $ddLoggerClient.logger.error('horizon.page ipqs', {
                service: 'horizon',
                name: 'horizon.error.ipqs.quota-exceeded',
                path: fullPath,
              });
            }
            return true;
          } else {
            analyticsTrack('Form Step Validation Failed', {
              form_type: 'Short Form',
              form_url: fullPath,
              locale_site: locale.value,
              validation_data: {
                ipqs_email_valid: response.data.valid,
                ipqs_email_disposable: response.data.disposable,
                ipqs_email_fraud_score: response.data.fraud_score,
              },
            });
            return false;
          }
        } catch (error) {
          report(error);
          return true;
        }
      } else {
        return true;
      }
    },
  };

  const { query } = useRoute();
</script>

<style scss>
  .label-asterick::before {
    content: '*';
    color: theme('colors.red.DEFAULT');
    padding-right: 5px;
  }
</style>
