import revive_payload_client_XHGZHRlhSO from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jNaMuOgI5q from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KxHo5yZUo8 from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RzW1QagqSH from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.7_sass@1.8_vzuwolzibg3e6uu26za6peibia/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_LdnGRFacCL from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DVpTwmb6LQ from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Htar4vDsP5 from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bIGVJ8eA8k from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jEpRIyIQxy from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3556124522/src/horizon/.nuxt/components.plugin.mjs";
import prefetch_client_5x4bzYqXLT from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_26yg5ioidaepiw3tdhargq3ghm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tuQQDnZsgS from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.7.3___magicast@0.3_3girovkftjmd3ec2m6vvwxqp6q/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_SRVsQXqxqF from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt-lazy-load@3.0.4_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_abCty0I7bT from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@babel+core@7.26.0_@parcel+watcher@2.5.0_@types+node@22.10.7_graph_mqrlolzhpvqqhne36nuxqm65ke/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import nuxt_plugin_6M83aBc4Tr from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_sOXyA866ut from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_pe2eoexaYA from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_2YzjSeQOer from "/codebuild/output/src3556124522/src/horizon/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typesc_givrvewwysda7xnx6nhgtjqw3y/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import analytics_mixpanel_client_KsTLYJ1HGU from "/codebuild/output/src3556124522/src/horizon/plugins/analytics-mixpanel.client.ts";
import analytics_page_client_Z0Ih4fJOaW from "/codebuild/output/src3556124522/src/horizon/plugins/analytics-page.client.ts";
import app_error_handler_client_wDZWWydHKE from "/codebuild/output/src3556124522/src/horizon/plugins/app-error-handler.client.ts";
import contentful_rest_jatwBhQm2u from "/codebuild/output/src3556124522/src/horizon/plugins/contentful-rest.ts";
import datadog_logs_client_VRBmCojiXq from "/codebuild/output/src3556124522/src/horizon/plugins/datadog-logs.client.ts";
import datadog_client_cfAAU11B0P from "/codebuild/output/src3556124522/src/horizon/plugins/datadog.client.ts";
import emitter_eaMgM0oZTb from "/codebuild/output/src3556124522/src/horizon/plugins/emitter.ts";
import get_contentful_page_locales_yHw6Zyjjap from "/codebuild/output/src3556124522/src/horizon/plugins/get-contentful-page-locales.ts";
import get_contentful_page_ePclQmDq2k from "/codebuild/output/src3556124522/src/horizon/plugins/get-contentful-page.ts";
import graphql_on_error_3QzmGOazd4 from "/codebuild/output/src3556124522/src/horizon/plugins/graphql-on-error.ts";
import launch_darkly_client_QMCmz2rq1I from "/codebuild/output/src3556124522/src/horizon/plugins/launch-darkly.client.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src3556124522/src/horizon/plugins/sentry.client.ts";
import set_session_client_s47LienHRC from "/codebuild/output/src3556124522/src/horizon/plugins/set-session.client.ts";
export default [
  revive_payload_client_XHGZHRlhSO,
  unhead_jNaMuOgI5q,
  router_KxHo5yZUo8,
  _0_siteConfig_RzW1QagqSH,
  payload_client_LdnGRFacCL,
  navigation_repaint_client_DVpTwmb6LQ,
  check_outdated_build_client_Htar4vDsP5,
  chunk_reload_client_bIGVJ8eA8k,
  plugin_vue3_jEpRIyIQxy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5x4bzYqXLT,
  plugin_tuQQDnZsgS,
  plugin_SRVsQXqxqF,
  plugin_abCty0I7bT,
  nuxt_plugin_6M83aBc4Tr,
  switch_locale_path_ssr_sOXyA866ut,
  i18n_pe2eoexaYA,
  plugin_wy0B721ODc,
  plugin_2YzjSeQOer,
  analytics_mixpanel_client_KsTLYJ1HGU,
  analytics_page_client_Z0Ih4fJOaW,
  app_error_handler_client_wDZWWydHKE,
  contentful_rest_jatwBhQm2u,
  datadog_logs_client_VRBmCojiXq,
  datadog_client_cfAAU11B0P,
  emitter_eaMgM0oZTb,
  get_contentful_page_locales_yHw6Zyjjap,
  get_contentful_page_ePclQmDq2k,
  graphql_on_error_3QzmGOazd4,
  launch_darkly_client_QMCmz2rq1I,
  sentry_client_shVUlIjFLk,
  set_session_client_s47LienHRC
]